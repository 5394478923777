
import './Template2.scss';

const Template2Page = () : JSX.Element => {

    return(
        <div className="template-2">
            Plantilla 2
        </div>
    )
}

export default Template2Page;