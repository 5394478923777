import { Button, Checkbox, Col, Form, Input, Radio, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetLanguageInUrl } from "../../../utils/urls";
import { useAppConfig } from "../../../Contexts/AppConfigContext";

import './PersonalFormComponent.scss';
import { Language } from "../../../models/Language";
import ProfileData from "../../../models/ProfileData";
import { LoadingOutlined } from "@ant-design/icons";
import CustomField, { KeyValuePair, TranslatedLabel } from "../../../models/CustomField";
import { GetCustomFields } from "../../../utils/customfields";

interface PersonalFormComponentProps{
    onPreviousStep: (step?: number) => void,
    onNextStep: (step?: number) => void,
    onFillProfileData: (data: ProfileData) => void,
    isLoading: boolean
}

const PersonalFormComponent = (props: PersonalFormComponentProps) : JSX.Element => {
    const appConfig = useAppConfig();
    const [form] = useForm();
    const { t } = useTranslation();
    const {onPreviousStep, onNextStep, onFillProfileData, isLoading} = props;

    const [customFields, setCustomFields] = useState<JSX.Element[]>([])
    const [enabledButton, setEnabledButton] = useState<boolean>(false);

    const [acceptTermnsAndConditions, setAcceptTermnsAndConditions] = useState<boolean>(false);
    const [wantReceiveEmails, setWantReceiveEmails] = useState<boolean>(false);

    const onChangeTermnsAndConditions = (v: any) : void => {
        setAcceptTermnsAndConditions(v.target.checked);
    }

    const onChangeSetWantToReceiveEmails = (v: any) : void => {
        setWantReceiveEmails(v.target.checked);
    }

    const onFinish = () : void => {
        const profileData : ProfileData = {
            email: form.getFieldValue("email"),
            repeatEmail: form.getFieldValue("mailRepeat"),
            language: form.getFieldValue("language"),
            prefix: form.getFieldValue("prefix"),
            name: form.getFieldValue("name"),
            surname: form.getFieldValue("surname"),
            secondSurname: form.getFieldValue("secondSurname"),
            phone: form.getFieldValue("phone"),
            termnsAndConditionsReaded: acceptTermnsAndConditions,
            wantToReceiveEmails: wantReceiveEmails,
            customFields: []
        };

        if (appConfig.config.customRegisterFields.length > 0){
            appConfig.config.customRegisterFields.forEach((field: CustomField) => {
                profileData.customFields.push({
                    key: field.name,
                    value: form.getFieldValue(field.name)
                })
            })
        }

        onFillProfileData(profileData);
    }

    const loadCustomForms = () : void => {
        let fields : JSX.Element[] = [];

        if (appConfig.config.customRegisterFields.length > 0){
            fields.push(<Col xs={24}><h3>{t('components.personal-form.extra-fields')}</h3></Col>)

            fields = fields.concat(GetCustomFields(appConfig.config.customRegisterFields, t));
        }

        setCustomFields(fields);
    }

    const verifyFields = () : void => {
        const hasErrors = form.getFieldsError().some((field) => {
            const fieldName: string = field.name[0].toString();
            let hasValue: boolean = form.getFieldValue(fieldName) !== undefined && form.getFieldValue(fieldName)?.length > 0;
            let hasError: boolean = field.errors.length > 0 || !hasValue;


            return (fieldName === "secondSurname" || appConfig.config.customRegisterFields.some(cf => cf.name == fieldName && !cf.required)) ? false : hasError;
        });

        setEnabledButton(!hasErrors && acceptTermnsAndConditions);
    }

    useEffect(()=>{
        verifyFields();
    },[acceptTermnsAndConditions])

    useEffect(()=>{
        if (appConfig && appConfig.config.customRegisterFields.length > 0){
            loadCustomForms();
        }
    },[appConfig])

    return(
        <div className="personal-form-component">
            <Form form={form} onFinish={onFinish} layout="vertical" onFieldsChange={verifyFields}>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.name-prefix")}
                            name="prefix"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select>
                                {
                                    appConfig.config.prefixNames.map((prefix: string, index: number)=>{
                                        return <Select.Option key={index} value={prefix}>{prefix}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.name")}
                            name="name"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.surname")}
                            name="surname"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.second-surname")}
                            name="secondSurname"
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.email")}
                            name="email"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                                { type: "email", message: t("forms.validations.invalid-format")!}
                            ]}
                        >
                            <Input onCopy={(e)=>{
                                e.preventDefault();
                            }} onPaste={(e)=>{
                                e.preventDefault();
                            }} className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.repeat-email")}
                            name="mailRepeat"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                                { type: "email", message: t("forms.validations.invalid-format")!},
                                ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue("email") === value) {
                                    return Promise.resolve();
                                    }

                                    return Promise.reject(t("forms.validations.not-match"));
                                },
                                }),
                            ]}
                            >
                            <Input onCopy={(e)=>{
                                e.preventDefault();
                            }} onPaste={(e)=>{
                                e.preventDefault();
                            }} className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.phone")}
                            name="phone"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.personal-form.language")}
                            name="language"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                            initialValue={GetLanguageInUrl().replace("/","")}
                        >
                            <Select>
                                {
                                    appConfig.config.availableLanguages.map((language: Language, index: number)=>{
                                        return <Select.Option key={index} value={language.shortCode} disabled={!language.enabled}>{language.description}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    {customFields}
                </Row>
                <div className="checkbox-actions">
                    <Checkbox onChange={onChangeTermnsAndConditions}>
                        {HTMLReactParser(t('components.personal-form.termns-and-conditions', [`${appConfig.config.siteName}/${GetLanguageInUrl()}/termns-and-conditions`,"app-link"]))}
                    </Checkbox>
                    <Checkbox onChange={onChangeSetWantToReceiveEmails}>
                        {t('components.personal-form.news-on-email')}
                    </Checkbox>
                </div>
                <div className="app-actions">
                    <Button onClick={()=>{onPreviousStep();}} className="app-white-button">
                        {t('components.personal-form.actions.prev')}
                    </Button>

                    <Button htmlType="submit" className="app-button" disabled={!enabledButton}>
                        {
                            isLoading ? <LoadingOutlined /> : ''
                        }
                        {t('components.personal-form.actions.finish')}
                    </Button>
                </div>
            </Form>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default PersonalFormComponent;