import { Button } from 'antd';
import { GetEmailSentIcon } from '../../../utils/icons';
import './VerifyEmailComponent.scss';
import { useTranslation } from 'react-i18next';
import { GoHome } from '../../../utils/urls';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';


interface VerifyEmailComponentProps{
    onResendEmail: () =>  any
}

const VerifyEmailComponent = (props: VerifyEmailComponentProps) : JSX.Element=>{
    const { t } = useTranslation();
    const [sendingEmail, setsendingEmail] = useState<boolean>(false);

    const { onResendEmail } = props;


    const resendEmail = () => {
        setsendingEmail(true);
        onResendEmail().finally(()=>{
            setsendingEmail(false);
        });
    }


    return(
        <div className="verify-email">
            {
                GetEmailSentIcon()
            }

            <div className="info">
                <p>
                    <strong>{t("components.verify-email.verify-your-email")}</strong>
                </p>
                <p>
                    {t(`components.verify-email.notice-text`)}
                </p>
                <Button className="app-button" onClick={GoHome}>
                    {t("components.verify-email.ok-understand")}
                </Button>
                <div className="resend-email">
                    <small>{t("components.verify-email.not-received.title")}</small>
                    <small className="link-resend-email" onClick={resendEmail}>
                        {t("components.verify-email.not-received.we-can-resend-email")} 
                        {sendingEmail ? <LoadingOutlined className="app-loading"/> : ''}
                    </small>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailComponent;