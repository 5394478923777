import { DestinationListChilds, DestinationListItem, HistoryItem, HotelProperties, SearchList } from "@beds2b-group/reusable-components/dist/types"
import hotelUtils from "./hotels"
import { Place } from "../utils/utils"
import { AvailableDestinationsList } from "../models/HotelInfo";

export const availableDestinations : AvailableDestinationsList[] = [
  {
    countryName: "España",
    countryCode: "ESP",
    cities: [
      {
        cityName: "Almería",
        cityCode: "ALM",
        hotels: [
          { hotelName: "Hotel Demo 1 ****", hotelCode: "ALM1" },
          { hotelName: "Hotel Demo 2 ****", hotelCode: "ALM2" },
          { hotelName: "Hotel Demo 3 ****", hotelCode: "ALM3" },
          { hotelName: "Hotel Demo 4 ****", hotelCode: "ALM4" },
          { hotelName: "Hotel Demo 5 ****", hotelCode: "ALM5" },
          { hotelName: "Hotel Demo 6 ****", hotelCode: "ALM6" },
          { hotelName: "Hotel Demo 7 ****", hotelCode: "ALM7" },
          { hotelName: "Hotel Demo 8 ****", hotelCode: "ALM8" },
          { hotelName: "Hotel Demo 9 ****", hotelCode: "ALM9" },
          { hotelName: "Hotel Demo 10 **", hotelCode: "ALM10" },
        ],
      },
      {
        cityName: "Barcelona",
        cityCode: "BAR",
        hotels: [
          { hotelName: "Hotel Demo 11 ****", hotelCode: "BAR1" },
        ],
      },
      {
        cityName: "Cádiz",
        cityCode: "CAD",
        hotels: [
          { hotelName: "Hotel Demo 12 ****", hotelCode: "CAD1" },
          { hotelName: "Hotel Demo 13 ****", hotelCode: "CAD2" },
          { hotelName: "Hotel Demo 14 ****", hotelCode: "CAD3" },
        ],
      },
      {
        cityName: "Granada",
        cityCode: "GRA",
        hotels: [
          { hotelName: "Hotel Demo 15 ****", hotelCode: "GRA1" },
          { hotelName: "Hotel Demo 16 ****", hotelCode: "GRA2" },
        ],
      },
      {
        cityName: "Huelva",
        cityCode: "HUE",
        hotels: [
          { hotelName: "Hotel Demo 17 ***", hotelCode: "HUE1" },
          { hotelName: "Hotel Demo 18 ****", hotelCode: "HUE2" },
          { hotelName: "Hotel Demo 19 ****", hotelCode: "HUE3" },
        ],
      },
      {
        cityName: "Madrid",
        cityCode: "MAD",
        hotels: [
          { hotelName: "Hotel Demo 20 ****", hotelCode: "MAD1" },
          { hotelName: "Hotel Demo 21 ***", hotelCode: "MAD2" },
        ],
      },
      {
        cityName: "Mallorca",
        cityCode: "MAL",
        hotels: [
          { hotelName: "Hotel Demo 22 *****", hotelCode: "MAL1" },
          { hotelName: "Hotel Demo 23 ****", hotelCode: "MAL2" },
          { hotelName: "Hotel Demo 24 ****", hotelCode: "MAL3" },
        ]
      },
      {
        cityName: "Murcia",
        cityCode: "MUR",
        hotels: [
          { hotelName: "Hotel Demo 25", hotelCode: "MUR1" },
          { hotelName: "Hotel Demo 26 *****", hotelCode: "MUR2" },
          { hotelName: "Hotel Demo 27 ****", hotelCode: "MUR3" },
        ],
      },
      {
        cityName: "Málaga",
        cityCode: "MALA",
        hotels: [
          { hotelName: "Hotel Demo 28 *****", hotelCode: "MALA1" },
        ],
      },
      {
        cityName: "Puerto Plata",
        cityCode: "PP",
        hotels: [
          { hotelName: "Hotel Demo 29 *****", hotelCode: "PP1" },
          { hotelName: "Hotel Demo 30 *****", hotelCode: "PP2" },
        ],
      },
      {
        cityName: "Sevilla",
        cityCode: "SEV",
        hotels: [
          { hotelName: "Hotel Demo 31 ****", hotelCode: "SEV1" },
        ],
      },
      {
        cityName: "Valencia",
        cityCode: "VAL",
        hotels: [
          { hotelName: "Hotel Demo 32 ****", hotelCode: "VAL1" },
          { hotelName: "Hotel Demo 33 ****", hotelCode: "VAL2" },
        ],
      },
    ],
  },
  {
    countryName: "México",
    countryCode: "MEX",
    cities: [
      {
        cityName: "Distrito Federal",
        cityCode: "DF",
        hotels: [
          { hotelName: "Hotel Demo 34 ****", hotelCode: "DF1" },
          { hotelName: "Hotel Demo 35 ***", hotelCode: "DF2" },
          { hotelName: "Hotel Demo 36 ****", hotelCode: "DF3" },
        ],
      },
      {
        cityName: "Guadalajara",
        cityCode: "GDL",
        hotels: [
          { hotelName: "Hotel Demo 37 ****", hotelCode: "GDL1" },
          { hotelName: "Hotel Demo 38 ****", hotelCode: "GDL2" },
        ],
      },
    ],
  },
];




  
  const countries: Array<DestinationListItem> = []
  
  const regionsSpain: Array<DestinationListItem> = []
  
  const regionsMexico: Array<DestinationListItem> = []
  
  const hotels: Array<DestinationListItem> = []
  
  export const userHistory: Array<HistoryItem> = [
    {
      item: {
        label: 'HotelBrand 1',
        id: '1',
      },
      dates: {
        from: new Date(2024, 7, 22),
        to: new Date(2024, 7, 27),
      },
      occupancy: [{
        adults: { quantity: 2, min: 0, max: 0 },
        kids: { quantity: 0, min: 0, max: 0, ages: {} }
      }]
    },
    {
      item: {
        label: 'HotelBrand 2',
        id: '2',
      },
      dates: {
        from: new Date(2024, 7, 22),
        to: new Date(2024, 7, 27),
      },
      occupancy: [{
        adults: { quantity: 2, min: 0, max: 0 },
        kids: { quantity: 0, min: 0, max: 0, ages: {} }
      }]
    },
  ]

  const spanishCities : Array<string> = [
    'Madrid',
    'Barcelona',
    'Valencia',
    'Sevilla',
    'Zaragoza',
    'Málaga',
    'Murcia',
    'Palma',
    'Las Palmas de Gran Canaria',
    'Bilbao',
    'Alicante',
    'Cordoba',
    'Valladolid',
    'Vigo',
    'Gijon',
    'L Hospitalet de Llobregat',
    'La Coruna',
    'Granada',
    'Vitoria-Gasteiz',
    'Elche',
    'Oviedo',
    'Badalona',
    'Cartagena',
    'Terrassa',
    'Jerez de la Frontera',
    'Sabadell',
    'Mostoles',
    'Santa Cruz de Tenerife',
    'Alcala de Henares',
    'Pamplona',
    'Fuenlabrada',
    'Almeria',
    'San Sebastian',
    'Leganes',
    'Santander',
    'Burgos',
    'Castello de la Plana',
    'Albacete',
    'Alcorcon',
    'Getafe',
    'Salamanca',
    'Huelva',
    'Logrono'
  ]

  function getSpanishCitiesCoincidencesIgnoringAcents(value: string): Array<string> {
    let results: Array<string> = []
    
    for (let i = 0; i < spanishCities.length; i++) {
      if (spanishCities[i].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())) {
        results.push(spanishCities[i])
      }
    }

    return results
  }

  function getHotelCoincidencesIngoringAcents(value: string): Array<{
    id: number,
    label: string,
    component: any
  }> {
    let results: Array<{
      id: number,
      label: string,
      component: any
    }> = []
    
    for (let i = 0; i < hotelUtils.clientHotelList.length; i++) {
      if (hotelUtils.clientHotelList[i].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())) {

        results.push({
          //different random id
          id: Math.floor(Math.random() * 1000000),
          label: hotelUtils.clientHotelList[i].name,
          component: <span>{hotelUtils.clientHotelList[i].name} <small style={{display: "block", marginLeft: "5px"}}>{hotelUtils.clientHotelList[i].city}, {hotelUtils.clientHotelList[i].country}.</small></span>
        })

      }
    }

    return results
  }

  export function simulateSearchCoincidencesForWidget(value: string): Array<SearchList> {
    let results: Array<SearchList> = []

    let places = getSpanishCitiesCoincidencesIgnoringAcents(value).map((city) => {
      return {
        component: <Place hotelsNumber={
                    //random number between 1 and 10
                    Math.floor(Math.random() * 10) + 1
                  } place={city} />,
        label: city,
        id: `place.${city}`,
      }
    });

    if (places?.length > 0){
      results.push({
        header: 'Destinos',
        category: 'place',
        items: places,
      });
    }

    let hotels = getHotelCoincidencesIngoringAcents(value).map((hotel) => {
      return hotel as any
    });

    if (hotels?.length > 0){
      results.push({
        header: 'Hoteles',
        category: 'hotel',
        items: hotels,
      });
    }
    
    return results;
  }

  



  
  const resultslist: Array<SearchList> = []
  
  function handleSelectCountry(id: string): Array<DestinationListItem> {
    if (id === '1') return regionsSpain
    return regionsMexico
  }
  
  function handleSelectRegion() {
    return hotels
  }
  
  async function handleSelect(
    id: string,
    category: string
  ): Promise<DestinationListChilds | null> {
    if (category === 'countries')
      return {
        category: 'regions',
        items: handleSelectCountry(id),
        header: <h4>Regiones</h4>,
      }
    if (category === 'regions')
      return {
        category: 'hotels',
        items: handleSelectRegion(),
        header: <h4>Hoteles</h4>,
      }
    console.log('called in a hotel (2)')
    return null
  }
  
  async function retrieveItemsByName(
    label: string
  ): Promise<Array<HotelProperties>> {
    return new Promise((res) =>
      res([hotelUtils.hotel, hotelUtils.hotel, hotelUtils.hotel])
    )
  }
  async function retrieveItemsByCategory(
    category: string,
    selection: Selection
  ): Promise<Array<HotelProperties>> {
    return new Promise((res) =>
      res([hotelUtils.hotel, hotelUtils.hotel, hotelUtils.hotel])
    )
  }
  async function retrieveItemsById(id: string): Promise<Array<HotelProperties>> {
    return new Promise((res) =>
      res([hotelUtils.hotel, hotelUtils.hotel, hotelUtils.hotel])
    )
  }
  
  const searchUtils = {
    countries,
    regionsSpain,
    regionsMexico,
    hotels,
    userHistory,
    resultslist,
    handleSelect,
    retrievers: {
      retrieveItemsByCategory,
      retrieveItemsById,
      retrieveItemsByName,
    },
  }
  
  export default searchUtils
  