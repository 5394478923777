import { useTranslation } from "react-i18next";

import './DashboardPage.scss'

import { ImagePath } from "../../../utils/urls";
import { EngineWidget, GoogleMaps, Statistics } from "../../../components";
import { Col, Row } from "antd";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { useEffect, useState } from "react";
import { HistoryItem } from "@beds2b-group/reusable-components/dist/types";
import { fetchAvailableDestinations, fetchSearchCoincidences, fetchUserHistory } from "../../../api/repositoryEP";
import { useBookingEngineContext } from "../../../Contexts/EngineDataContext";

const DashboardgPage = () : JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();

    const bookingEngineContext = useBookingEngineContext();

    const imagePath = ImagePath();

    const [userHistory, setUserHistory] = useState<Array<HistoryItem>>([]);
    const getUserHistory = () => {
        fetchUserHistory().then((response) => {
            if (response){
                setUserHistory(response.data)
            }else{
                setUserHistory([])
            }
        });
    }

    const [searchCoincidences, setSearchCoincidences] = useState<Array<any>>([]);
    const getSearchCoincidences = (v: string) => {
        if (v.length < 3) return setSearchCoincidences([]);

        fetchSearchCoincidences(v).then((response) => {
            if (response){
                setSearchCoincidences(response.data)
            }else{
                setSearchCoincidences([])
            }
        });
    }

    const [availableDestinations, setAvailableDestinations] = useState<Array<any>>([]);
    const getAvilableDestinations = () => {
        fetchAvailableDestinations().then((response) => {
            if (response){
                setAvailableDestinations(response.data)
            }else{
                setAvailableDestinations([])
            }
        });
    }

    useEffect(()=>{
        getUserHistory();
        getAvilableDestinations();
        bookingEngineContext.clearContext();
    },[])

    return(
        <div className="dashboard-page">
            <div className="booking-widget">
                <h1>
                    {t("pages.dashboard.booking-widget-title")}
                </h1>
                <div>
                    <EngineWidget userHistory={userHistory} onGetCoincidences={getSearchCoincidences} searchCoincidences={searchCoincidences} availableDestinations={availableDestinations}/>
                </div>
            </div>
            <div className="statistics-widgets">
                <h1>
                    {t("pages.dashboard.statistics-widget-title")}
                </h1>

                <div className="image-container" style={{backgroundImage:`url(/${imagePath}/statistics-image-separator.png)`}}>
                    &nbsp;
                </div>
                <div className="statistics-widgets-container">
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2023'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={10}                                    
                                    data={[42, 65, 78, 91, 54, 36, 70, 85, 102, 112, 127, 136]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Comisiones obtenidas en 2023'}
                                    symbol={appConfig.config.availableCurrencies[0]?.symbol}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={50}
                                    data={[30, 42, 55, 78, 49, 27, 43, 66, 85, 94, 110, 220]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Promedio agentes online en 2023'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={32}
                                    data={[20, 30, 25, 60, 35, 40, 75, 90, 55, 70, 45, 80]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2022'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={95}
                                    data={[12, 25, 18, 42, 55, 62, 47, 30, 38, 58, 65, 72]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2022'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={43}
                                    data={[90, 78, 67, 105, 85, 110, 95, 120, 130, 150, 142, 130]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2022'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={75}
                                    data={[30, 38, 50, 45, 70, 62, 80, 78, 65, 58, 42, 60]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2022'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={82}
                                    data={[70, 62, 55, 48, 82, 96, 77, 90, 103, 112, 128, 145]}
                                    />
                        </Col>
                        <Col xs={24} sm={12} md={6} >
                            <Statistics 
                                    header={'Ventas realizadas en 2022'}
                                    title={'Título'}
                                    subtitle="Subtítulo"
                                    progress={34}
                                    data={[55, 42, 78, 67, 92, 108, 85, 73, 120, 135, 118, 95]}
                                    />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default DashboardgPage;