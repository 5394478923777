import { registerModal, useModal } from '@gluedigital/modal'
import { RateRoomModal } from '@beds2b-group/reusable-components'

function RoomRateModalWrapper() {
  const modal = useModal()
  return (
    <RateRoomModal
      selectedMeal={{
        code: "1",
        name: "prueba",
        price: {
          currency: "EUR",
          pvp: 100,
        },
        rateKey: "rate-key",
        discount: {
            amount: 120,
            type: 'percentage',
            alreadyApplied: false
        }
    }}

    rate={{
  isOffer: true,
  additionalInfo: [
    {
      icon: 'attention',
      message: 'Baja disponibilidad',
    },
    {
      icon: 'ok',
      message: '25% de descuento en entrada al Parque Temático Oasys ',
    },
    {
      icon: 'ok',
      message: 'Anulación gratis hasta el 11 de abril.',
    },
  ],
  cancelPolicies: {
    description: 'Descripción de las políticas de cancelación',
    policies: [
      {
        currency: '1',
        deadLine: new Date(),
        penalty: '100',
        refundable: false,
      },
    ],
  },
  dates: [new Date(), new Date()],
  meals: [
    {
      rateKey: 'rtk-1',
      code: '1',
      name: 'Solo alojamiento',
      price: {
        pvp: 150.25,
        currency: "EUR",
      },
      discount: {
        amount: +10,
        type: 'fixed',
      },
    },
    {
      rateKey: 'rtk-2',
      code: '2',
      name: 'Alojamiento y desayuno',
      price: {
        pvp: 200.33,
        currency: "EUR",
      },
      discount: {
        amount: 50,
        type: 'percentage',
      },
    },
    {
      rateKey: 'rtk-3',
      code: '3',
      name: 'Media pension',
      price: {
        pvp: 300.13,
        currency: "EUR",
      },
      discount: {
        amount: 50,
        type: 'percentage',
      },
    },
    {
      rateKey: 'rtk-4',
      code: '4',
      name: 'Pension completa',
      price: {
        pvp: 400.11,
        currency: "EUR",
      },
      discount: {
        amount: -50,
        type: 'percentage',
      },
    },
  ],
  name: 'Tarifa Reducida',
}}
    roomName={"The room Name"}
    handleClose={() => modal.hide()}
    />
  )
}

export default RoomRateModalWrapper

registerModal('room-rate-modal', RoomRateModalWrapper)
