export var exampleUsers = [
    {
        id: '1',
        name: 'John',
        surname: 'Doe',
        secondSurname: 'Smith',
        email: 'john@example.com',
        password: 'password1',
        role: 'User',
        created_at: '2023-01-15',
        updated_at: '2023-02-20',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '2',
        name: 'Jane',
        surname: 'Johnson',
        secondSurname: 'Lee',
        email: 'jane@example.com',
        password: 'password2',
        role: 'Admin',
        created_at: '2023-03-10',
        updated_at: '2023-04-25',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '3',
        name: 'Robert',
        surname: 'Williams',
        secondSurname: 'Brown',
        email: 'robert@example.com',
        password: 'password3',
        role: 'User',
        created_at: '2023-05-05',
        updated_at: '2023-06-12',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '4',
        name: 'Emily',
        surname: 'Davis',
        secondSurname: 'Martinez',
        email: 'emily@example.com',
        password: 'password4',
        role: 'User',
        created_at: '2023-07-20',
        updated_at: '2023-08-30',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '5',
        name: 'Michael',
        surname: 'Wilson',
        secondSurname: 'Anderson',
        email: 'michael@example.com',
        password: 'password5',
        role: 'Admin',
        created_at: '2023-09-15',
        updated_at: '2023-10-05',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '6',
        name: 'Sarah',
        surname: 'Brown',
        secondSurname: 'Lewis',
        email: 'sarah@example.com',
        password: 'password6',
        role: 'User',
        created_at: '2023-11-10',
        updated_at: '2023-12-20',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '7',
        name: 'Daniel',
        surname: 'Taylor',
        secondSurname: 'Young',
        email: 'daniel@example.com',
        password: 'password7',
        role: 'User',
        created_at: '2024-01-15',
        updated_at: '2024-02-20',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '8',
        name: 'Olivia',
        surname: 'Harris',
        secondSurname: 'Rodriguez',
        email: 'olivia@example.com',
        password: 'password8',
        role: 'Admin',
        created_at: '2024-03-10',
        updated_at: '2024-04-25',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '9',
        name: 'Liam',
        surname: 'Johnson',
        secondSurname: 'Hall',
        email: 'liam@example.com',
        password: 'password9',
        role: 'User',
        created_at: '2024-05-05',
        updated_at: '2024-06-12',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '10',
        name: 'Ava',
        surname: 'Garcia',
        secondSurname: 'Turner',
        email: 'ava@example.com',
        password: 'password10',
        role: 'User',
        created_at: '2024-07-20',
        updated_at: '2024-08-30',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '11',
        name: 'Ethan',
        surname: 'Miller',
        secondSurname: 'Collins',
        email: 'ethan@example.com',
        password: 'password11',
        role: 'Admin',
        created_at: '2024-09-15',
        updated_at: '2024-10-05',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '12',
        name: 'Isabella',
        surname: 'Davis',
        secondSurname: 'Bennett',
        email: 'isabella@example.com',
        password: 'password12',
        role: 'User',
        created_at: '2024-11-10',
        updated_at: '2024-12-20',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '13',
        name: 'Mason',
        surname: 'Martinez',
        secondSurname: 'Walker',
        email: 'mason@example.com',
        password: 'password13',
        role: 'User',
        created_at: '2025-01-15',
        updated_at: '2025-02-20',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '14',
        name: 'Sophia',
        surname: 'Anderson',
        secondSurname: 'King',
        email: 'sophia@example.com',
        password: 'password14',
        role: 'Admin',
        created_at: '2025-03-10',
        updated_at: '2025-04-25',
        deleted_at: null,
        phone: "369852147"
    },
    {
        id: '15',
        name: 'Liam',
        surname: 'Smith',
        secondSurname: 'Wright',
        email: 'liam@example.com',
        password: 'password15',
        role: 'User',
        created_at: '2025-05-05',
        updated_at: '2025-06-12',
        deleted_at: null,
        phone: "369852147"
    }
];

export const removeUser = (id: string) => {
    exampleUsers = exampleUsers.filter(user => user.id !== id);
}