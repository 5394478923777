import PublicFooterComponent from "./PublicFooter/PublicFooterComponent";
import PublicHeaderComponent from "./PublicHeader/PublicHeaderComponent";
import LanguageSelectorComponent from './LanguageSelector/LanguageSelectorComponent'
import CurrencySelectorComponent from './CurrencySelector/CurrencySelectorComponent'
import PrivateFooterComponent from "./PrivateFooter/PrivateFooterComponent";
import PrivateHeaderComponent from "./PrivateHeader/PrivateHeaderComponent";
import PrivateMenuComponent from "./PrivateMenu/PrivateMenuComponent";


export const PublicFooter = PublicFooterComponent;
export const PublicHeader = PublicHeaderComponent;
export const LanguageSelector = LanguageSelectorComponent;
export const CurrencySelector = CurrencySelectorComponent;
export const PrivateFooter = PrivateFooterComponent;
export const PrivateHeader = PrivateHeaderComponent;
export const PrivateMenu = PrivateMenuComponent;