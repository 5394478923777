

import './BookingsFilterComponent.scss'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import HotelInfo from '../../../models/HotelInfo';
import StatusInfo from '../../../models/StatusInfo';
import UsersInfo from '../../../models/UsersInfo';
import { BookingFilteData } from '../../../models/BookingInfo';

interface BookingsFilterComponentProps {
    hotelList: HotelInfo[],
    statusList: StatusInfo[],
    userList: UsersInfo[],
    onFilter: (filter: BookingFilteData) => void
}

const BookingsFilterComponent = (props: BookingsFilterComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const onFinish = () => {
        props.onFilter(form.getFieldsValue());
    }

    return(
        <div className="bookings-filter">
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} lg={8}>
                        <Form.Item name="locator" label={t('components.bookings-filter.form.locator')}>
                            <Input type="text" className="app-input" allowClear onPressEnter={onFinish}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name="hotel" label="Hotel" initialValue={""}>
                            <Select onChange={onFinish}>
                                <Select.Option value="">{t('components.bookings-filter.form.all')}</Select.Option>
                                {
                                    props.hotelList.map((hotel: HotelInfo, index: number) => {
                                        return <Select.Option value={hotel.id} key={index}>{hotel.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name="ownerName" label={t('components.bookings-filter.form.owner')}>
                            <Input type="text" className="app-input" allowClear onPressEnter={onFinish}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name="checkInDate" label={t('components.bookings-filter.form.checkIn')}>
                            <DatePicker className="app-input" onChange={onFinish}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name="checkOutDate" label={t('components.bookings-filter.form.checkOut')}>
                            <DatePicker className="app-input" onChange={onFinish}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name="statusId" label={t('components.bookings-filter.form.status')} initialValue={""}>
                            <Select onChange={onFinish}>
                                <Select.Option value="">{t('components.bookings-filter.form.all')}</Select.Option>
                                {
                                    props.statusList.map((status: StatusInfo, index: number) => {
                                        return <Select.Option value={status.id} key={index}>{status.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name="recordDate" label={t('components.bookings-filter.form.record-date')}>
                            <DatePicker className="app-input" onChange={onFinish}/>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={8}>
                        <Form.Item name="userId" label={t('components.bookings-filter.form.record-by')} initialValue={""}>
                            <Select showSearch onChange={onFinish}>
                                <Select.Option value="">{t('components.bookings-filter.form.all')}</Select.Option>
                                {
                                    props.userList.map((user: UsersInfo, index: number) => {
                                        return <Select.Option value={user.id} key={index}>{user.surname} {user.secondSurname}, {user.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} className="btn-actions">
                        <Button htmlType='submit' className="app-button search-button">
                            {t('components.bookings-filter.search')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default BookingsFilterComponent;