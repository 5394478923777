import { useTranslation } from "react-i18next";
import { PublicHeader, PublicFooter } from "../../../components/common";


import './RegisterPage.scss';
import { Button, Col, Row, Steps } from "antd";
import { useEffect, useState } from "react";
import { UserType } from "../../../utils/constants";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { GetAgencyIcon, GetAgentEventsIcon, GetAgentIndependentIcon, GetBusinessIcon } from "../../../utils/icons";

import { AgencySelector, CompanySelector, CorporateForm, PersonalForm, VerifyEmail, WaigintEmailSignUp } from '../../../components'
import { fetchAgencies, fetchCompanies, pushAgent, resendVerifyEmail, sendSignUpRequest } from "../../../api/repositoryEP";
import { AgencyFilter, SmallAgencyData, SmallAgencyDataList } from "../../../models/SmallAgencyData";
import HTMLReactParser from "html-react-parser";
import { CompanyFilter, SmallCompanyData, SmallCompanyDataList } from "../../../models/SmallCompanyData";
import { SmallCorporateData } from "../../../models/SmallCorporateData";
import ProfileData from "../../../models/ProfileData";
import ApiResponse from "../../../models/api/ApiResponse";
import CreateAgent from "../../../models/CreateAgentInfo";

const RegisterPage = () : JSX.Element => {
    const appContext = useAppConfig();

    const { t } = useTranslation();

    const [actualStep, setActualStep] = useState<number>(0);
    const [extraInfoForStep, setExtraInfoForStep] = useState<string>('');
    const [stepComponent, setStepComponent] = useState<JSX.Element>(<></>);
    
    const [availableUserTypes, setAvailableUserTypes] = useState<JSX.Element[]>([]);
    const [selectedUserType, setSelectedUserType] = useState<UserType>();
    const [selectedAgecy, setSelectedAgency] = useState<SmallAgencyData>();
    const [selectedCompany, setSelectedCompany] = useState<SmallCompanyData>();
    const [profileData, setProfileData] = useState<ProfileData>();
    const [corporateData,setCorporateData] = useState<SmallCorporateData>();

    const getUserTypeSelectors = () : void => {
        var result : JSX.Element[] = [];
        appContext.config.availableUserTypes.map((userType: UserType)=>{
            switch(userType){
                case "agent":
                    result.push(<Col key={userType} xs={24} sm={12} md={12} lg={12} xl={11} xxl={6}>
                                    <div className="type-selector px-4" onClick={()=>{setSelectedUserType(userType)}}>
                                        <div className="image">
                                            {GetAgencyIcon("app-icon")}
                                        </div>
                                        <div className="label">
                                            {t(`pages.register-page.user-types.agent`)}
                                        </div>
                                    </div>
                                    
                                </Col>);
                    break;
                case "business":
                    result.push(<Col key={userType} xs={24} sm={12} md={12} lg={12} xl={11} xxl={6}>
                                    <div className="type-selector px-4" onClick={()=>{setSelectedUserType(userType)}}>
                                        <div className="image">
                                            {GetBusinessIcon("app-icon")}
                                        </div>
                                        <div className="label">
                                            {t(`pages.register-page.user-types.business`)}
                                        </div>
                                    </div>
                                    
                                </Col>);
                    break;
                case "events": 
                    result.push(<Col key={userType} xs={24} sm={12} md={12} lg={12} xl={11} xxl={6}>
                                    <div className="type-selector px-4" onClick={()=>{setSelectedUserType(userType)}}>
                                        <div className="image">
                                            {GetAgentEventsIcon("app-icon")}
                                        </div>
                                        <div className="label">
                                            {t(`pages.register-page.user-types.events`)}
                                        </div>
                                    </div>
                                    
                                </Col>);
                    break;
                case "independent": 
                    result.push(<Col key={userType} xs={24} sm={12} md={12} lg={12} xl={11} xxl={6}>
                                    <div className="type-selector px-4" onClick={()=>{setSelectedUserType(userType)}}>
                                        <div className="image">
                                            {GetAgentIndependentIcon("app-icon")}
                                        </div>
                                        <div className="label">
                                            {t(`pages.register-page.user-types.independent`)}
                                        </div>
                                    </div>
                                    
                                </Col>);
                break;
            }
        });

        setAvailableUserTypes(result);
    }

    const getAgencies = async (filter: AgencyFilter) : Promise<SmallAgencyDataList> => {
        var data = await fetchAgencies(filter);

        return data?.data;
    }

    const getCompanies = async (filter: CompanyFilter) : Promise<SmallCompanyDataList> => {
        var data = await fetchCompanies(filter);

        return data?.data;
    }

    const onSelectAgency = (agencyData: SmallAgencyData) : void => {
        setSelectedAgency(agencyData);
    }

    const onSelectCompany = (companyData: SmallCompanyData) : void => {
        setSelectedCompany(companyData);
    }

    const onFillCorporateData = (corporateData: SmallCorporateData) : void => {
        setCorporateData(corporateData);
    }

    const [creatingAgent, setCreatingAgent] = useState<boolean>(false);
    // Cuando rellene todos los pasos enviaremos la información al servidor. Si todo ha ido OK pasará al paso 3.
    const onFillProfileData = (data: ProfileData): void => {
        
        setProfileData(data);

        setCreatingAgent(true);
        const agentData : CreateAgent = {
            agentType: selectedUserType!,
            profileData: data,
            agencyData: selectedAgecy!,
            companyData: selectedCompany!,
            corporateData: corporateData!
        };

        pushAgent(agentData).then((r:ApiResponse | null)=>{
            if (r && r.data){
                setActualStep(3);
            }
        }).finally(()=>{
            setCreatingAgent(false);
        });
    }

    const onSignUpRequest = async (type: string, selectedId: string, email: string, agentName: string) => {
        const result = await sendSignUpRequest({type: type, id: selectedId, email, agentName});

        return result;
    }
    
    
    const setActualStepComponent = async () : Promise<void> => {
        var component : JSX.Element = <>default</>;
        switch(actualStep){
            case 1: //Realmente es el paso 2
                setSelectedAgency(undefined);
                setSelectedCompany(undefined);
                setProfileData(undefined);
                setCorporateData(undefined);
                switch(selectedUserType){
                    case "agent": 
                        component = <AgencySelector key="agency-component" getAgenciesData={getAgencies} onNextStep={nextStep} onPreviousStep={prevStep} onSelectAgency={onSelectAgency} onSignUpRequest={onSignUpRequest}/>;
                        break;
                     case "business":
                        component = <CompanySelector key="business-component" getCompaniesData={getCompanies} onNextStep={nextStep} onPreviousStep={prevStep} onSelectCompany={onSelectCompany} onSignUpRequest={onSignUpRequest}/>
                        break;
                    case "events":
                    case "independent": 
                        // Formulario para rellenar los datos de "compañía" de un agente independiente y eventos
                        component = <CorporateForm key="independent-events-component" onNextStep={nextStep} onPreviousStep={prevStep} onFillCorprateData={onFillCorporateData}/>
                        break;
                }    
                break;
            case 2: //Realmente es el paso 3
                component = <PersonalForm key="" onNextStep={nextStep} onPreviousStep={prevStep} onFillProfileData={onFillProfileData} isLoading={creatingAgent} />
                break;
            case 3: //Realmente es el paso 4 (Avisándole de que ha debido recibir un correo)
                switch(extraInfoForStep){
                    case 'waiting-request':
                        // Indica que ha seleccionado una agencia o compañía ya en uso y que se queda esperando respuesta de su correo
                        component = <WaigintEmailSignUp key="waiting-request-component" noticeType={selectedUserType === "agent" ? 'agency' : 'company'}/>
                        break;
                    default:
                        // El paso normal es revisar su correo de registro
                        component = <VerifyEmail key="verify-email-component" onResendEmail={resendEmail} />
                        break;
                }
                break;
            case 4: //Realmente es el paso 5 (Cuando desde el correo hace click para validar su cuenta)
                component = <div>5 <br/><Button onClick={()=>{setActualStep(5);}}>Siguiente</Button></div>
                break;
            case 0:
            default: 
                setSelectedUserType(undefined);
                setSelectedAgency(undefined);
                setSelectedCompany(undefined);
                setProfileData(undefined);
                setCorporateData(undefined);
                component = <div className="user-type-selector">
                                <Row gutter={32}>
                                    {availableUserTypes}
                                </Row>
                            </div>
            break;
        }


        setStepComponent(component);
    }

    const items = [
        {
          title: '', description: '', onClick: ()=>{ if (actualStep >= 1){ setActualStep(0); setSelectedUserType(undefined)} }
        },
        {
          title: '', description: '', onClick: ()=>{ if (actualStep >= 2) setActualStep(1) }
        },
        {
          title: '', description: '', onClick: ()=>{ if (actualStep >= 3) setActualStep(2) }
        },
        {
          title: '', description: '', onClick: ()=>{ if (actualStep >= 4) setActualStep(3) }
        },
        {
          title: '', description: '', onClick: ()=>{ if (actualStep >= 5) setActualStep(4) }
        },
    ];

    const nextStep = (step: number = -1, extra: string = '') : void => {
        var newStepNumber = (step >=0 ? step : (actualStep+1));

        setExtraInfoForStep(extra);
        setActualStep(newStepNumber);
    }

    const prevStep = (step: number = -1, extra: string = '') : void => {
        var newStepNumber = (step >=0 ? step : (actualStep-1));

        setExtraInfoForStep(extra);

        switch(newStepNumber){
            case 0:
                setSelectedUserType(undefined);
                setSelectedAgency(undefined);
                setSelectedCompany(undefined);
                break;
        }

        setActualStep(newStepNumber);
    }

    const resendEmail = async () => {
        if (profileData?.email){
            await resendVerifyEmail(profileData?.email!)
        }
    }

    useEffect(()=>{
        if (selectedUserType){
            setActualStep(1)
        }
    },[selectedUserType]);

    useEffect(()=>{
        getUserTypeSelectors();
    },[appContext])

    useEffect(()=>{
        if (availableUserTypes.length > 0){
            setActualStepComponent();
        }
    },[availableUserTypes])

    useEffect(()=>{
        if (actualStep >=0){
            setActualStepComponent();
        }
    },[actualStep])

    return(
        <div className="register-page">
                <div className="title">
                    {t('pages.register-page.title')}
                </div>
                <div className="steps">
                    <span className="step-title">
                        {
                            HTMLReactParser(t(`pages.register-page.steps.descriptions.${actualStep}-${selectedUserType}`, [appContext.config.siteName]))
                        }
                    </span>
                    {
                        actualStep >= 0 ? <Steps responsive={false} className="items" current={actualStep}  items={items} /> : <></>
                    }
                </div>
                <div className="step-title">
                    <p className="title">
                        {t(`pages.register-page.steps.labels.${actualStep}-${selectedUserType}`)}
                    </p>
                    <div className="sub-title">
                        {t(`pages.register-page.steps.sub-labels.${actualStep}-${selectedUserType}`)}
                    </div>
                </div>

                { stepComponent ?? <></> }
        </div>
    )
}


export default RegisterPage;