
import { Col, List, Pagination, Popconfirm, Row, Table, Tag, Tooltip, notification } from 'antd';
import BookingInfo, { SmallBookingDataList } from '../../../models/BookingInfo';
import './BookingsListComponent.scss'
import { useTranslation } from 'react-i18next';
import { EditOutlined, InfoCircleOutlined, SnippetsOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { GetPaginationLocale } from '../../../utils/locales';
import { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';

interface BookingsListComponentProps {
    bookingsInfo: SmallBookingDataList,
    search: (rows: number, page: number) => void,
    onShowBookingDetail: (bookingId: string) => void,
    onShowCustomerVoucher: (bookingId: string) => void,
    onForceReloadBookingStatus: (bookingId: string, bookingLocator: string) => void,
    synchronizingId: string
}

const BookingsListComponent = (props: BookingsListComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const appConfig = useAppConfig();

    const [selectedNumRecords, setSelectedNumRecords] = useState<number>(6);
    const [selectedPage, setSelectedPage] = useState<number>(1);

    const getBookingDetail = (bookingId: string) : void => {
        props.onShowBookingDetail(bookingId);
    }


    const getCustomerVoucher = (bookingId: string) : void => {
        props.onShowCustomerVoucher(bookingId);
    }

    const reloadBookingInfo = (bookingId: string,bookingLocator: string) : void => {
        props.onForceReloadBookingStatus(bookingId, bookingLocator);
    }

    const getTagStatus = (record: BookingInfo) : JSX.Element => {
        return <div className="status">
        <Tag className="tag-status" color={record?.isCancelled ? 'red' : 'green'}>
            {record.statusName}  
            {
                record?.isCancelled ? 
                    <Tooltip title={t('pages.booking-detail.booking-status.cancelled-at',[record.cancelledDate ? moment(record.cancelledDate).format(appConfig.config.dateFormat) : 'N/D'])}>
                        <InfoCircleOutlined className="icon-cancelled-at"/>
                    </Tooltip>
                :
                    <SynchronizeButton synchronizing={props.synchronizingId === record.locator} bookingData={record} onSynchronize={reloadBookingInfo}/>
            }
            
        </Tag>
        </div>
    }

    const getPricingInfo = (record: BookingInfo) : JSX.Element => {
        return <div className="pricing-data">
                        <div className="pvp"> {t('components.bookings-list.pvp')}: {record.pvp} {record.currency}</div>
                        <div className="commission"> {t('components.bookings-list.commission')}: {record.commission} {record.currency}</div>
                        <div className="payed"> {t('components.bookings-list.payed')}: {record.payed} {record.currency}</div>
                </div>
    }

    const getLocatorInfo = (record: BookingInfo) : JSX.Element => {
        return <div className="locator-data">
                        <div className="locator">{record.locator}</div>
                        <div className="creation-date">({moment(record.creationDate).format(appConfig.config.dateFormat)})</div>
                </div>
    }

    const getDatesInfo = (record: BookingInfo) : JSX.Element => {
        return <div className="hotel-data">
                    <div className="hotel-name">{record.hotelName}</div>
                    <div className="check-in-out">
                        ({moment(record.checkIn).format(appConfig.config.dateFormat)} - {moment(record.checkOut).format(appConfig.config.dateFormat)})
                    </div>
            </div>
    }

    const getUserInfo = (record: BookingInfo) : JSX.Element => {
        return <div className="user-data">
                        <div className="user-name">{record.userName}</div>
                        <div className="user-email">{record.userEmail}</div>
                </div>
    }

    const tableColumns : ColumnsType<BookingInfo> = [
        {
            title: t('components.bookings-list.status'),
            dataIndex: "status",
            key: "status",
            sorter: (a: BookingInfo, b: BookingInfo) => a.statusName.localeCompare(b.statusName),
            render: (item: string, record: BookingInfo) => {

                return <div className="locator-data">
                            {getTagStatus(record)}
                       </div>
            }
        },
        {
            title: t('components.bookings-list.locator'),
            dataIndex: "locator",
            key: "locator",
            sorter: (a: BookingInfo, b: BookingInfo) => a.locator.localeCompare(b.locator),
            render: (item: string, record: BookingInfo) => {

                return getLocatorInfo(record)
            }
        },
        {
            title: t('components.bookings-list.booking'),
            dataIndex: "hotelName",
            key: "hotelName",
            sorter: (a: BookingInfo, b: BookingInfo) => a.hotelName.localeCompare(b.hotelName),
            render: (item: string, record: BookingInfo) => {

                return getDatesInfo(record)
            }
        },
        {
            title: t('components.bookings-list.owner'),
            dataIndex: "ownerName",
            key: "ownerName",
            sorter: (a: BookingInfo, b: BookingInfo) => a.ownerName.localeCompare(b.ownerName),
            render: (item: string, record: BookingInfo) => {
                    return <div className="owner-data">
                                    <div className="owner-name">{item}</div>
                            </div>
            }
        },
        {
            title: t('components.bookings-list.pricing-data'),
            dataIndex: "pvp",
            key: "pvp",
            render: (item: string, record: BookingInfo) => {

                return getPricingInfo(record);
            }
        },
        {
            title: t('components.bookings-list.booking-by'),
            dataIndex: "userId",
            sorter: (a: BookingInfo, b: BookingInfo) => a.userName.localeCompare(b.userName),
            key: "userId",
            render: (item: string, record: BookingInfo) => {

                return getUserInfo(record)
            }
        },
        {
            title: t('components.bookings-list.actions'),
            dataIndex: "actions",
            key: "actions",
            render: (item: string, record: BookingInfo) => {
                return getActions(record);
            }
        }
    ];

    const getActions = (item: BookingInfo) : JSX.Element => {
        return <div className="user-actions">
            <Tooltip title={t('components.bookings-list.actions-tooltip.booking-detail')}>
                <SnippetsOutlined className="app-icon icon" onClick={()=>{getBookingDetail(item.bookingId)}}/>
            </Tooltip>
            <Tooltip title={t('components.bookings-list.actions-tooltip.customer-voucher')}>
                <UserOutlined className="app-icon icon" onClick={()=>{getCustomerVoucher(item.bookingId)}}/>
            </Tooltip>
        </div>
    }

    useEffect(()=>{
        props.search(selectedNumRecords, selectedPage);
    },[selectedPage, selectedNumRecords])

    return(
        <div className="bookings-list">
            <div className="bookings-resume">
                <Row gutter={16}>
                    <Col xs={24} lg={4}>
                        {t('components.bookings-list.resume.total')}: <span>{props.bookingsInfo?.bookings?.length}</span>
                    </Col>
                    <Col xs={24} lg={4}>
                        {t('components.bookings-list.resume.total-amount')}: <span>{props.bookingsInfo?.bookings?.reduce((acc, booking) => acc + booking.pvp, 0)} {props.bookingsInfo.bookings?.[0]?.currency}</span>
                    </Col>
                    <Col xs={24} lg={4}>
                        {t('components.bookings-list.resume.total-commissions')}: <span>{props.bookingsInfo?.bookings?.reduce((acc, booking) => acc + booking.commission, 0)} {props.bookingsInfo.bookings?.[0]?.currency}</span>
                    </Col>
                </Row>
            </div>
            <div className="list">
                {
                    appConfig.device === 'desktop' ?
                        <Table
                            locale={{
                                triggerAsc: t('components.bookings-list.sort-asc')!,
                                triggerDesc: t('components.bookings-list.sort-desc')!,
                                cancelSort: t('components.bookings-list.no-sort')!,
                            }}
                        className="app-table" columns={tableColumns} dataSource={props.bookingsInfo?.bookings} pagination={false} rowKey={"id"} />
                    :
                        <List
                            className={`app-list`}
                            itemLayout="vertical"
                            dataSource={props.bookingsInfo?.bookings}
                            renderItem={(item: BookingInfo) =>
                                <List.Item className="booking-list-item">
                                    <Row gutter={0}>
                                        <Col xs={10} className="list-item">
                                            {getTagStatus(item)}
                                        </Col>
                                        <Col xs={10} className="list-item">
                                            {getLocatorInfo(item)}
                                        </Col>
                                        <Col xs={4} className="list-item flex-center">
                                            {getActions(item)}
                                        </Col>
                                    </Row>
                                    <Row className="list-additional-info">
                                        <Col xs={12}  className="list-item">
                                            <span>Hotel/Fechas:</span>
                                            {getDatesInfo(item)}
                                        </Col>
                                        <Col xs={12}  className="list-item">
                                            <span>Titular reserva:</span>
                                            {item.ownerName}
                                        </Col>
                                        <Col xs={12}  className="list-item">
                                            <span>Precio:</span>
                                            {getPricingInfo(item)}
                                        </Col>
                                        <Col xs={12}  className="list-item">
                                            <span>Datos agente:</span>
                                            {getUserInfo(item)}
                                        </Col>
                                    </Row>
                                    
                                </List.Item>
                            }
                        />
                }
            </div>
            <div className="total-records">
                <div className="pagination">
                    <Pagination
                        locale={GetPaginationLocale(t)}
                        total={props.bookingsInfo?.totalRecords}
                        pageSizeOptions={["6", "20", "50", "100"]}
                        current={selectedPage}
                        pageSize={selectedNumRecords}
                        defaultPageSize={6}
                        defaultCurrent={1}
                        onChange={(page, numRecords)=>{
                            setSelectedPage(page);
                            setSelectedNumRecords(numRecords);
                        }}
                    />
                </div>
                <div className="counter">
                    {t('components.company-selector.total-records',[props.bookingsInfo.totalRecords])}
                </div>
            </div>
        </div>
    )
}

const SynchronizeButton = (props: {synchronizing: boolean, onSynchronize: (bookingId: string, bookingLocator: string)=>void, bookingData: BookingInfo}) : JSX.Element => {
    const { t } = useTranslation();

    const waitingTime = 60000;

    const [disabled, setDisabled] = useState<boolean>(false);   

    const [counter, setCounter] = useState<number>(waitingTime);

    useEffect(() => {
        let countdown: NodeJS.Timeout | null = null;
        if (disabled) { 
          countdown = setTimeout(() => {
            setDisabled(false);
            setCounter(waitingTime);
          }, waitingTime);
        }
    
        return () => {
          // Limpia el temporizador si el componente se desmonta o si disabled cambia a false
          if (countdown) {
            clearTimeout(countdown);
          }
        };
      }, [disabled]);
    
      useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
    
        if (disabled && counter > 0) {
          // Actualiza el contador cada segundo
          interval = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1000);
          }, 1000);
        }
    
        return () => {
          // Limpia el intervalo si el componente se desmonta o si disabled cambia a false
          if (interval) {
            clearInterval(interval);
          }
        };
      }, [disabled, counter]);

    return(
        <Popconfirm 
            okText={t('components.bookings-list.synchronize.ok-text')} 
            cancelText={t('components.bookings-list.synchronize.cancel-text')} 
            title={t('components.bookings-list.synchronize.title')} 
            description={HTMLReactParser(t('components.bookings-list.synchronize.description', [props.bookingData.locator]))}
            onConfirm={()=>{
                if (!disabled){
                    props.onSynchronize(props.bookingData.bookingId, props.bookingData.locator)
                    setDisabled(true);
                }else{
                    notification.warning({
                        message: t('components.bookings-list.synchronize.time-exceeded'),
                        description: HTMLReactParser(t('components.bookings-list.synchronize.time-exceeded-description', [props.bookingData.locator, (counter/1000)]))
                    })
                }
            }}
            >
            <Tooltip title={counter == waitingTime ? t('components.bookings-list.actions-tooltip.synchronize-booking') : HTMLReactParser(t('components.bookings-list.synchronize.time-exceeded-description', [props.bookingData.locator, (counter/1000)]))}>
                <SyncOutlined disabled spin={props.synchronizing} className="app-icon icon" />
            </Tooltip>
        </Popconfirm>
    )
}

export default BookingsListComponent;