import { notification } from 'antd';
import { bookingDataExample } from '../models/BookingData';
import BookingInfo, { BookingFilteData, SmallBookingDataList } from '../models/BookingInfo';
import { ContactFormInfo, GroupFormData } from '../models/ContactFormInfo';
import CreateAgentInfo from '../models/CreateAgentInfo';
import { ErrorInfo } from '../models/ErrorInfo';
import HotelInfo from '../models/HotelInfo';
import { AddAgency, AgencyFilter, SmallAgencyDataList } from '../models/SmallAgencyData';
import { AddCompany, CompanyFilter, SmallCompanyDataList } from '../models/SmallCompanyData';
import StatusInfo from '../models/StatusInfo';
import UserSessionData from '../models/UserSessionData';
import UsersInfo, { SmallUsersDataList, UsersFilteData } from '../models/UsersInfo';
import ApiResponse from '../models/api/ApiResponse';
import ParamsApi from '../models/api/ParamsApi';
import RestApiClient from './restApiClient';
import RegimeInfo from '../models/RegimeInfo';
import UpdatePersonalData from '../models/UpdatePersonalData';
import UpdateProfileData from '../models/UpdateProfileData';
import ChangePasswordData from '../models/ChangePasswordData';

//Mocks
import { exampleUsers, removeUser } from '../mocks/users';
import { exampleBookings, examplePostponedBookings } from '../mocks/bookings';
import { configClientApplication } from '../mocks/applicationConfig';
import { agenciesList } from '../mocks/agecies';
import { companiesClientList } from '../mocks/companies';
import { templateInfo } from '../mocks/templates';
import { availableDestinations, simulateSearchCoincidencesForWidget, userHistory } from '../mocks/search';
import { AvailabilityRequest } from '../models/availability/AvailabilityRequest';
import { roomAvailabilityMock } from '../mocks/roomAvailability';
import { locationAvailabilityResponseExample } from '../mocks/locationAvailabilityMock';

const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

var restApiClient : RestApiClient = new RestApiClient();;

// Si esta variable está a TRUE cuando se produce un error en una llamada API se redirige al usuario a la página de error
const REDIRECT_TO_ERROR_PAGE : boolean = true;




  

export async function pushErrorNotificationData(errorInfo: ErrorInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushErrorNotificationData", errorInfo);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PushErrorNotification", {
            body:errorInfo
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchClientAppCustomData() : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchClientAppCustomData");

        return {
            additionalMessages: [],
            code: 200,
            data: configClientApplication,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("GetCustomAppData", {},  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function useDoLogin(username: string, password: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useDoLogin", username, password);

        let result : ApiResponse = {
            additionalMessages: [],
            code: 200,
            data: {
                sessionId: "dfecb97e-e240-4747-b327-d30ff4da0f63",
                userData:{
                    name: "John",
                    surname: "Doe",
                    secondSurname: "Doe",
                    UAID:"123-ABC-XXX",
                    email: "test@beds2b.com",
                    avatar: "", //"https://www.shareicon.net/data/256x256/2016/09/15/829453_user_512x512.png",
                    phone: "123456789",
                    username: "TestUsername"
                },
                isLogged: true
            } as UserSessionData,
            message: "",
            type: 200,
            typeText: "success"
        };

        if ((username !== 'Sergio') || (password !== 'Sergio123')){
            result = {
                additionalMessages: [],
                code: 200,
                data: {
                    sessionId: "dfecb97e-e240-4747-b327-d30ff4da0f63",
                    userData:{
                        name: "John",
                        surname: "Doe",
                        secondSurname: "Doe",
                        UAID:"123-ABC-XXX",
                        email: "test@beds2b.com",
                        avatar: "", //"https://www.shareicon.net/data/256x256/2016/09/15/829453_user_512x512.png",
                        phone: "123456789",
                        username: "TestUsername"
                    },
                    isLogged: true
                } as UserSessionData,
                message: "",
                type: 404,
                typeText: "error"
            };
        }

        return result;

        var params : ParamsApi = {
            body:{
                userName: username,
                password: password
            }
        }

        const response = await restApiClient.fetch("DoLogin", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function useCloseSession(sessionToken: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useCloseSession", sessionToken);

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                token: sessionToken
            }
        }

        const response = await restApiClient.fetch("DoLogout", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function useRecoverPassword(email: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useRecoverPassword", email);

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                email: email
            }
        }

        const response = await restApiClient.fetch("DoRecoverPassword", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function fetchAgencies(filterData: AgencyFilter) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchAgencies", filterData);

        let r : SmallAgencyDataList = {
            totalRecords: 1500,
            agencies: agenciesList
        };

        if (filterData?.commercialName?.length > 0 && filterData?.commercialName === "no"){
            r = {
                totalRecords: 0,
                agencies: []
            };
        }else if (filterData?.commercialName?.length > 0){
            const coincidences = agenciesList.filter((agency) => agency.name.includes(filterData.commercialName));
            r = {
                totalRecords: coincidences.length,
                agencies: coincidences
            };
        }
        

        var start = (filterData.page-1) * filterData.numRecords;
        var end = start + filterData.numRecords;

        r.agencies = r.agencies.slice(start, end);

        return {
            additionalMessages: [],
            code: 200,
            data: r,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filterData: filterData
            }
        }

        const response = await restApiClient.fetch("GetAgencies", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function fetchCompanies(filterData: CompanyFilter) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchCompanies", filterData);

        let r : SmallCompanyDataList = {
            totalRecords: 1500,
            companies: companiesClientList
        };

        if (filterData?.commercialName?.length > 0 && filterData?.commercialName === "no"){
            r = {
                totalRecords: 0,
                companies: []
            };
        }else if (filterData?.commercialName?.length > 0){
            const coincidences = companiesClientList.filter((company) => company.name.includes(filterData.commercialName));
            r = {
                totalRecords: coincidences.length,
                companies: coincidences
            };
        }

        var start = (filterData.page-1) * filterData.numRecords;
        var end = start + filterData.numRecords;

        r.companies = r.companies.slice(start, end);

        return {
            additionalMessages: [],
            code: 200,
            data: r,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filterData: filterData
            }
        }

        const response = await restApiClient.fetch("GetCompanies", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function sendSignUpRequest(data: {type: string, id: string, email: string, agentName: string}) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: sendSignUpRequest", data);

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("SendSignUpRequest", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function createOrganization(type: 'agency' | 'company', data: AddAgency | AddCompany) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: createOrganization", type, data);

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body: {
                type: type,
                organizationData: data
            }
        }

        const response = await restApiClient.fetch("CreateOrganization", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function pushAgent(agentInfo: CreateAgentInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushAgent", agentInfo);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PushAgent", {
            body:agentInfo
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function resendVerifyEmail(agentEmail: string)  : Promise<ApiResponse | null>{
    console.warn("Calling to: resendVerifyEmail", agentEmail);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("ResendVerifyEmail", {
            query:{
                agentEmail : agentEmail
            }
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchTemplateInfo() : Promise<ApiResponse | null>{
    console.warn("Calling to: getTemplateInfo");
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: templateInfo,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("getClientTemplate", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function sendContactEmail(formData: ContactFormInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: sendContactEmail", formData);
    try{


        notification.success({
            message: 'Email enviado',
            description: 'El email se ha enviado correctamente'
        })

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("sendContactEmail", {
            body:formData
        },  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchUserList(filterData: UsersFilteData) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchUserList", filterData);
    try{

        let r : SmallUsersDataList = {
            totalRecords: exampleUsers.length,
            users: exampleUsers as UsersInfo[]
        };

        if ((filterData?.name?.length > 0 || filterData?.email?.length > 0) && (filterData?.name === "no" || filterData?.email === "no")){
            r = {
                totalRecords: 0,
                users: []
            };
        }else if (filterData?.name?.length > 0 || filterData?.email?.length > 0){
            const coincidences = r.users.filter((user) => {
                const completeName = user.name + " " + user.surname + " " + user.secondSurname;
                return completeName.includes(filterData.name) || user.email.includes(filterData.email)
            });
            r = {
                totalRecords: coincidences.length,
                users: coincidences
            };
        }

        var start = (filterData.page-1) * filterData.numRecords;
        var end = start  + filterData.numRecords;

        r.users = r.users.slice(start, end);

        return {
            additionalMessages: [],
            code: 200,
            data: r,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filterData: filterData
            }
        }

        const response = await restApiClient.fetch("GetUserList", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchUserInfo(userId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchUserInfo", userId);
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: exampleUsers.find(u => u.id === userId) as UsersInfo,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                id: userId
            }
        }

        const response = await restApiClient.fetch("GetUserInfo", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function pushNewUser(userData: UsersInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushNewUser", userData);
    try{

        //Añadimos a exampleUsers el nuevo usuario
        exampleUsers.push({
            id: userData.id,
            name: userData.name,
            surname: userData.surname,
            secondSurname: userData.secondSurname,
            email: userData.email,
            password: userData.password,
            role: userData.role,
            created_at: '2023-01-15',
            updated_at: '2023-02-20',
            deleted_at: null,
            phone: userData.phone
        });

        notification.success({
            message: 'Usuario añadido',
            description: 'El usuario se ha añadido correctamente'
        })

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: userData
            }
        }

        const response = await restApiClient.fetch("AddUser", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function deleteUser(userId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: deleteUser", userId);
    try{

        //Eliminamos el usuario de la lista de exampleUsers
        removeUser(userId);

        notification.success({
            message: 'Usuario eliminado',
            description: 'El usuario se ha eliminado correctamente'
        })

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                id: userId
            }
        }

        const response = await restApiClient.fetch("RemoveUser", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function updateUser(userData: UsersInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: updateUser", userData);
    try{


        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: userData
            }
        }

        const response = await restApiClient.fetch("UpdateUser", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchHotels() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchHotels");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: [
                { 
                    id: "guid-1",
                    name: "Hotel 1", 
                },
                {
                    id: "guid-2",
                    name: "Hotel 2"
                },
                {
                    id: "guid-3",
                    name: "Hotel 3"
                },
                {
                    id: "guid-4",
                    name: "Hotel 4"
                },
                {
                    id: "guid-5",
                    name: "Hotel 5"
                }
            ] as HotelInfo[],
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("GetHotels", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchStatus() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchStatus");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: [
                { 
                    id: "guid-1",
                    name: "Status 1", 
                },
                {
                    id: "guid-2",
                    name: "Status 2"
                },
                {
                    id: "guid-3",
                    name: "Status 3"
                },
                {
                    id: "guid-4",
                    name: "Status 4"
                },
                {
                    id: "guid-5",
                    name: "Status 5"
                }
            ] as StatusInfo[],
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("GetStatus", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchBookingList(filterData: BookingFilteData) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchBookingList", filterData);
    try{

        let r : SmallBookingDataList = {
            totalRecords: 1500,
            bookings: exampleBookings as BookingInfo[]
        };

        if (filterData?.locator?.length > 0 && (filterData?.locator === "no" )){
            r = {
                totalRecords: 0,
                bookings: []
            };
        }else if (filterData?.locator?.length > 0 ){
            r = {
                totalRecords: 1,
                bookings: [exampleBookings[0]]
            };
        }

        var start = (filterData.page-1) * filterData.numRecords;
        var end = start  + filterData.numRecords;

        r.bookings = r.bookings.slice(start, end);

        return {
            additionalMessages: [],
            code: 200,
            data: r,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filterData: filterData
            }
        }

        const response = await restApiClient.fetch("GetBookingList", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function synchronizeBookingStatus(bookingId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: synchronizeBookingStatus", bookingId);
    try{

        await new Promise((resolve) => setTimeout(resolve, 4000));

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("SynchronizeBookingStatus", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchBookingData(bookingId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchBookingData", bookingId);
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: bookingDataExample,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("GetBookingData", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function cancellBooking(bookingId: number) : Promise<ApiResponse | null>{
    console.warn("Calling to: cancellBooking", bookingId);
    try{

        notification.success({
            message: "Reserva cancelada correctamente",
            description: "La reserva se ha cancelado correctamente"
        });
        
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "Reserva cancelada correctamente",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("CancelBooking", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function sendEmailToCustomer(bookingId: number) : Promise<ApiResponse | null>{
    console.warn("Calling to: cancellBooking", bookingId);
    try{

        notification.success({
            message: "Operación realizada",
            description: "Se ha enviado correctamente un email al cliente con la información de la reserva"
        });
        
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "Email enviado correctamente",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                bookingId: bookingId
            }
        }

        const response = await restApiClient.fetch("SendEmailToCustomer", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchRegimesInfo() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchRegimesInfo");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: [
                {
                    id: "1",
                    name: "Alojamiento y desayuno",
                },
                {
                    id: "2",
                    name: "Media pensión",
                },
                {
                    id: "3",
                    name: "Pensión completa",
                },
                {
                    id: "4",
                    name: "Todo incluido",
                }
            ] as RegimeInfo[],
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetRegimesInfo", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function sendEmailGroups(formData: GroupFormData) : Promise<ApiResponse | null>{
    console.warn("Calling to: sendEmailGroups", formData);
    try{

        await sleep(1000);
        
        notification.success({
            message: "Operación realizada",
            description: "Se ha enviado correctamente el email a la cadena. En breve nos pondremos en contacto con usted"
        });
        
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "Se ha enviado correctamente el email a la cadena. En breve nos pondremos en contacto con usted",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                formData: formData
            }
        }

        const response = await restApiClient.fetch("SendEmailToCustomer", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function updateProfilePersonalData(userData: UpdatePersonalData) : Promise<ApiResponse | null>{
    console.warn("Calling to: updateProfilePersonalData", userData);
    try{
        await sleep(1000);
        notification.success({
            message: "Operación realizada",
            description: "Se ha actualizado correctamente la información personal"
        });

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: userData
            }
        }

        const response = await restApiClient.fetch("UpdateProfilePersonalData", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function updateProfileContacData(userData: UpdateProfileData) : Promise<ApiResponse | null>{
    console.warn("Calling to: updateProfileContacData", userData);
    try{
        await sleep(1000);
        notification.success({
            message: "Operación realizada",
            description: "Se ha actualizado correctamente los datos de contacto"
        });

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: userData
            }
        }

        const response = await restApiClient.fetch("UpdateProfileContactData", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function updateProfileAccessData(userData: ChangePasswordData) : Promise<ApiResponse | null>{
    console.warn("Calling to: updateProfileAccessData", userData);
    try{
        await sleep(1000);
        notification.success({
            message: "Operación realizada",
            description: "Se ha actualizado correctamente los datos de acceso"
        });

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: userData
            }
        }

        const response = await restApiClient.fetch("UpdateProfileAccessData", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchLocationAvailability(availabilityData: AvailabilityRequest) : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchLocationAvailability", availabilityData);
    try{

        await sleep(1000);

        return {
            additionalMessages: [],
            code: 200,
            data: locationAvailabilityResponseExample,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: availabilityData
            }
        }

        const response = await restApiClient.fetch("GetLocationAvailability", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchHotelAvailability(availabilityData: AvailabilityRequest) : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchHotelAvailability", availabilityData);
    try{

        await sleep(1000);

        return {
            additionalMessages: [],
            code: 200,
            data: roomAvailabilityMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: availabilityData
            }
        }

        const response = await restApiClient.fetch("GetHotelAvailability", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchAvailableDestinations() : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchAvailableDestinations");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: availableDestinations,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetSearchCoicidences", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchUserHistory() : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchUserHistory");
    try{

        await sleep(1000);

        return {
            additionalMessages: [],
            code: 200,
            data: userHistory,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetUserHistory", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchSearchCoincidences(value: string) : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchSearchCoincidences", value);
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: simulateSearchCoincidencesForWidget(value),
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("GetSearchCoicidences", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchPostponedBookings() : Promise<ApiResponse | null>{

    console.warn("Calling to: fetchPostponedBookings");
    try{

        return {
            additionalMessages: [],
            code: 200,
            data: examplePostponedBookings,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {}

        const response = await restApiClient.fetch("fetchPostponedBookings", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}


