

import { useState } from 'react';
import { Get3DotsIcon } from '../../../utils/icons';

import './DotsActionsComponent.scss'

interface DotsActionsComponentProps {
    actions: any[];
}

const DotsActionsComponent = (props: DotsActionsComponentProps) : JSX.Element => {

    const { actions } = props;
    
    const [isShowDotsActions, setIsShowDotsActions] = useState<boolean>(false);

    const showDotsActions = () => {
        setIsShowDotsActions(!isShowDotsActions);
    }
    
    return(
            <div className="dots-container" onClick={showDotsActions}>
                {Get3DotsIcon('app-icon width-24px')}
                <div className={`dots-actions ${isShowDotsActions ? 'visible' : 'hidden'}`} onMouseLeave={showDotsActions}>
                    <ul>
                        {
                            actions.map((item: any, index: number) => {
                                return(
                                    <li key={index} className="dots-action" onClick={item.action}>
                                        {item.icon ?? ''} <span>{item.label}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    
                </div>
            </div>
    )
}

export default DotsActionsComponent;