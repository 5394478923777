import { useTranslation } from "react-i18next";
import { useBookingEngineContext } from "../../../Contexts/EngineDataContext";
import { useEffect, useState } from "react";
import { BookingData, Cart, EngineWidget, GoogleMaps, Pagination, PostponedBookings } from "../../../components";
import { Step as EngineSteps }  from "@beds2b-group/reusable-components";
import { fetchAvailableDestinations, fetchHotelAvailability, fetchLocationAvailability, fetchPostponedBookings, fetchSearchCoincidences, fetchUserHistory } from "../../../api/repositoryEP";

import './BookingEnginePage.scss';
import LoadingPage from "../../Commons/LoadingPage/LoadingPage";
import { HistoryItem } from "@beds2b-group/reusable-components/dist/types";

import '../../../components/booking-engine/HotelModalWrapper'
import '../../../components/booking-engine/RoomModalWrapper'
import '../../../components/booking-engine/RoomRateModalWrapper'

import { useModal } from "@gluedigital/modal";
import { AvailabilityRequest } from "../../../models/availability/AvailabilityRequest";

import { EngineDestinationTypeHotel, EngineDestinationTypeLocation, emptyLocationAvailabilityResponse, emptyHotelAvailabilityResponse } from "../../../utils/constants";
import { LocationAvailabilityResponse } from "../../../models/availability/LocationsAvailability";
import { HotelAvailabilityResponse } from "../../../models/availability/HotelAvailability";
import { LocationToAppAvailability } from "../../../utils/LocationToAppAvailability";
import { HotelsToAppAvailability } from "../../../utils/HotelsToAppAvailability";
import { Alert, Button, Col, Modal, Row } from "antd";
import hotelUtils from "../../../mocks/hotels";
import { useCart } from "../../../Contexts/CartContexts";
import { useNavigate } from "react-router-dom";
import { GetFormattedUrl } from "../../../utils/urls";
import BookingInfo from "../../../models/BookingInfo";
import PostponedBooking from "../../../models/PostponedBooking";

const BookingEnginePage = () : JSX.Element => {

    const navigate = useNavigate();

    const cartContext = useCart();

    const modal = useModal();

    const bookingEngineContext = useBookingEngineContext();

    const [actualStepRoomSelector, setActualStepRoomSelector] = useState<number>(0);
    const [actualStepBooking, setActualStepBooking] = useState<number>(1);
    
    const { t } = useTranslation();

    const onChangeStep = (step: number) => {
        //Permitimos el cambio del 3 al 2 y del 2 al 3 siempre que:
        //  - El paso 2 esté completo: Se hayan seleccionado todas las habitaciones
        //  - Del paso 3 al 2 se puede pasar siempre.
        // No se permiten otros cambios de paso.
        var newStep = step+1;

        if (actualStepBooking === 3 && newStep === 2){
            setActualStepBooking(newStep);
            setActualStepRoomSelector(1);
        }else if (actualStepBooking === 2 && newStep === 3){
            if (cartContext.data.rooms.length == bookingEngineContext.rooms.length){
                setActualStepBooking(newStep);
                setActualStepRoomSelector(bookingEngineContext.rooms.length+1);
            }
        }

        
    };

    const [userHistory, setUserHistory] = useState<Array<HistoryItem>>([]);
    const getUserHistory = () => {
        fetchUserHistory().then((response) => {
            if (response){
                setUserHistory(response.data)
            }else{
                setUserHistory([])
            }
        });
    }

    const [searchCoincidences, setSearchCoincidences] = useState<Array<any>>([]);
    const getSearchCoincidences = (v: string) => {

        if (v.length < 3) return setSearchCoincidences([]);

        fetchSearchCoincidences(v).then((response) => {
            if (response){
                setSearchCoincidences(response.data)
            }else{
                setSearchCoincidences([])
            }
        });
    }

    const [availabilityType, setAvailabilityType] = useState<number>(EngineDestinationTypeLocation); // [hotel, room
    const [isGettingAvailability, setIsGettingAvailability] = useState<boolean>(false); 
    const [locationAvailability, setLocationAvailability] = useState<LocationAvailabilityResponse>(emptyLocationAvailabilityResponse);
    const [hotelAvailability, setHotelAvailability] = useState<HotelAvailabilityResponse>(emptyHotelAvailabilityResponse);

    const getAvailability = () => {
        setIsGettingAvailability(true);

        if (bookingEngineContext.dates.from == null || bookingEngineContext.dates.to == null) return;

        var availabilityData : AvailabilityRequest = {
            LanguageIsoCode: 'es-ES',
            DestinationType: bookingEngineContext.searchType,
            DestinationCodes: [bookingEngineContext.searchCode],
            AccommodationDates: {
                Format: 'dd/MM/yyyy',
                From: bookingEngineContext.dates.from,
                To: bookingEngineContext.dates.to
            },
            PromotionCode: bookingEngineContext.promotionalCode,
            RoomConfiguration: [],
            IncludeAvailabilityWithoutQuota: false,
            IncludeAlternatives: false,
            IncludeCrossSellingProducts: true,
        }

        bookingEngineContext.rooms.forEach((room) => {
            availabilityData.RoomConfiguration.push({
                RoomCode: "",
                OccupancyConfiguration: {
                    Adults: {
                        Ages: [],
                        Number: room.persons.adults.quantity
                    }, 
                    Teenagers:{
                        Ages: [],
                        Number: 0
                    },
                    Babies: {
                        Ages: [],
                        Number: 0
                    },
                    Childrens: {
                        Ages: room.persons.kids.ages,
                        Number: room.persons.kids.quantity
                    }
                },
                MealCode: []
            })
        });

        //Vamos al siguiente paso: Selección.
        setActualStepBooking(2);
        
        if (bookingEngineContext.searchType === EngineDestinationTypeLocation){
            setAvailabilityType(EngineDestinationTypeLocation);
            setActualStepRoomSelector(0);
            fetchLocationAvailability(availabilityData).then((response) => {
                if (response){
                    setLocationAvailability(response.data)
                }else{
                    setLocationAvailability(emptyLocationAvailabilityResponse)
                }
            }).finally(()=>{
                setIsGettingAvailability(false);
            })
        }else{
            //Mostramos la paginación de las habitaciones del hotel
            setAvailabilityType(EngineDestinationTypeHotel);
            setActualStepRoomSelector(1);
            fetchHotelAvailability(availabilityData).then((response) => {
                if (response){
                    setHotelAvailability(response.data)
                }else{
                    setHotelAvailability(emptyHotelAvailabilityResponse)
                }
            }).finally(()=>{
                setIsGettingAvailability(false);
            })
        }

        
    }

    const [roomSteps, setRoomSteps] = useState<any[]>([]);
    const getRoomSteps = () : void => {
        const roomSteps : any[] = [];
        bookingEngineContext.rooms.forEach((room) => {
            roomSteps.push({
                adults: room.persons.adults.quantity,
                childs: room.persons.kids.quantity
            })
        });

        setRoomSteps(roomSteps);
    }


    const [availableDestinations, setAvailableDestinations] = useState<Array<any>>([]);
    const getAvilableDestinations = () => {
        fetchAvailableDestinations().then((response) => {
            if (response){
                setAvailableDestinations(response.data)
            }else{
                setAvailableDestinations([])
            }
        });
    }

    

    const onSelectRoom = (roomData: any, mealData: any, rateData: any) => {

        console.log("data ->", roomData, mealData, rateData);

        cartContext.addRoomToCart({
            roomIndex: actualStepRoomSelector,
            mainImage: roomData.images[0],
            name: roomData.name,
            code: roomData.code,
            price: {
                currency: mealData.price.currency,
                pvp: mealData.price.pvp,
                commission: 0
            },
            occupancy:{
                adults: bookingEngineContext.rooms[actualStepRoomSelector-1].persons.adults.quantity,
                childs: bookingEngineContext.rooms[actualStepRoomSelector-1].persons.kids.quantity,
                childsAges: bookingEngineContext.rooms[actualStepRoomSelector-1].persons.kids.ages
            },
            ratekey: mealData.rateKey,
        })

        // Go next step
        setActualStepRoomSelector(cartContext.whatIsTheNextRoomIndex());


    }

    const onRemoveRoomFromCart = (roomIndex: number, roomCode: string) => {
        setActualStepRoomSelector(roomIndex);
        setActualStepBooking(2);
    }

    const [isSimulateBookingPaymentOpened, setIsSimulateBookingPaymentOpened] = useState<boolean>(false);
    const toogleSimulateBookingPayment = () => {
        console.log("toogleSimulateBookingPayment");
        setIsSimulateBookingPaymentOpened(!isSimulateBookingPaymentOpened);
    }


    const [postponedBookings, setPostponedBookings] = useState<PostponedBooking[]>([]);
    const getPostponedBookings = () => {
        fetchPostponedBookings().then((response) => {
            if (response){
                setPostponedBookings(response.data)
                }else{
                    setPostponedBookings([])
                }
            }
        )
    }      


    const [roomsAvailability, setRoomsAvailability] = useState<JSX.Element[]>([]);
    useEffect(()=>{
        if (hotelAvailability && hotelAvailability.HotelAvailabilities && hotelAvailability.HotelAvailabilities.length >0 && actualStepRoomSelector > 0){
            setRoomsAvailability(HotelsToAppAvailability(hotelAvailability, actualStepRoomSelector, onSelectRoom, modal));
        }

        if (actualStepRoomSelector > roomSteps.length){
            setActualStepBooking(3);
        }else if(actualStepRoomSelector > 0 && actualStepRoomSelector <= roomSteps.length){
            setActualStepBooking(2);
        }
    },[hotelAvailability, actualStepRoomSelector]);

    useEffect(()=>{
        if (bookingEngineContext.isValidData()){
            cartContext.setHotelData({
                code: bookingEngineContext.searchCode,
                name: bookingEngineContext.label
            });
            cartContext.setDates({
                checkIn: bookingEngineContext.dates.from ?? new Date(),
                checkOut: bookingEngineContext.dates.to ?? new Date(),
            });

            cartContext.updateHashCart("1234");
            getAvailability();
            getRoomSteps();
        }
    },[bookingEngineContext]);

    useEffect(()=>{
        getUserHistory();
        getAvilableDestinations();
        getPostponedBookings();
    },[]);

    

    return(
        <div className="booking-engine-page">

            <Modal closable title="Simulador de pago" open={isSimulateBookingPaymentOpened} onCancel={toogleSimulateBookingPayment}
                footer={(_, { CancelBtn }) => (
                <>
                    <Button className="app-button danger" danger onClick={()=>{
                        //Go to customer voucher
                        toogleSimulateBookingPayment();
                        navigate(GetFormattedUrl(`private/payment-ko`));
                    }}>Simular KO</Button>
                    <Button className="app-button" onClick={()=>{
                        //Clear engine search
                        bookingEngineContext.clearContext();
                        //Clear cart
                        cartContext.clearCart();
                        //Go to customer voucher
                        navigate(GetFormattedUrl(`private/payment-ok-customer-voucher/123`));
                    }}>Simular OK</Button>
                    <CancelBtn />
                </>
                )}
            >
                <p>Por favor, pulse una de las siguientes opciones para simular el flujo del pago</p>
            </Modal>
            
            <div className="widget-container">
                <EngineWidget userHistory={userHistory} onGetCoincidences={getSearchCoincidences} searchCoincidences={searchCoincidences} availableDestinations={availableDestinations}/>
            </div>
            
            <div className="booking-container">
            {
                isGettingAvailability ? 
                    <LoadingPage />
                :
                (locationAvailability && locationAvailability.Hotels?.length > 0)  ||
                (roomsAvailability && roomsAvailability.length > 0) ? <>
                        <div className="booking-engine-pagination">
                            <Pagination actualStep={actualStepBooking} onChangeStep={onChangeStep}/>
                        </div>
                        <div className="booking-engine-steps">
                            {
                                actualStepRoomSelector > 0 && roomSteps.length > 1 ? 
                                    <EngineSteps actualStep={actualStepRoomSelector} occupancy={roomSteps} onStepChange={(step: number)=>{ 
                                        setActualStepRoomSelector(step);
                                    }}/>
                                :
                                    <></>
                            }
                        </div>
                        <div className={`booking-engine-result`}>
                            <Row gutter={16}>
                                <Col xs={(availabilityType === EngineDestinationTypeLocation ? 24 : 18)}>
                                    {
                                        availabilityType === EngineDestinationTypeLocation ?
                                                LocationToAppAvailability(locationAvailability, bookingEngineContext, modal)
                                            :
                                                (
                                                    actualStepRoomSelector > 0 && actualStepRoomSelector <= roomSteps.length ?
                                                        roomsAvailability
                                                    :
                                                        <BookingData />
                                                )
                                    }
                                </Col>
                                <Col className="cart" xs={(availabilityType === EngineDestinationTypeLocation ? 0 : 6)}>
                                    <Cart onClickInPayButton={toogleSimulateBookingPayment} hotelData={hotelUtils.hotel} from={bookingEngineContext.dates.from} to={bookingEngineContext.dates.to} notifyRemoveRoom={onRemoveRoomFromCart}/>
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <div className="alert-search-availability">
                        <Alert message={t('pages.bookings-page.search-availability.title')} description={t('pages.bookings-page.search-availability.description')} type="info" showIcon />
                    </div>
            }
            
            </div>

            <div className="postponed-bookings-container">
                <PostponedBookings list={postponedBookings}/>
            </div>
        </div>
    )
}

export default BookingEnginePage;