import { DestinationListChilds, DestinationListItem, HistoryItem, HotelProperties, SelectedRoom } from "@beds2b-group/reusable-components/dist/types"
import { FC, useEffect, useState } from "react"
import { useBookingEngineContext } from "../../../Contexts/EngineDataContext"
import { useNavigate } from "react-router-dom"
import { GetFormattedUrl } from "../../../utils/urls"
import { useAppConfig } from "../../../Contexts/AppConfigContext"
import { getRandomItem } from "../../../mocks/calendar"
import { t } from "i18next"
import { Place } from "../../../utils/utils"
import { AvailableDestinationsList } from "../../../models/HotelInfo"

import hotelUtils from "../../../mocks/hotels"
import moment from "moment"

import { WidgetWrapper } from "@beds2b-group/reusable-components"

import '../MapModalWrapper'

import { useModal } from "@gluedigital/modal"
import { EngineDestinationTypeHotel, EngineDestinationTypeLocation } from "../../../utils/constants"
import { GoogleMaps } from "../.."
import { useGoogleMapsContext } from "../../../Contexts/GoogleMapsContext"
import { useCart } from "../../../Contexts/CartContexts"
import { notification } from "antd"


interface SearchStatusProps {
    value: string
    hasResults: boolean,
}

const minLength = 3



  const RelatedItemComponent: FC<{ item: HotelProperties; handleSelect: (item: HotelProperties) => void; }> = ({ item, handleSelect }) => {
    // Aquí puedes usar las props `item` y `handleSelect` para renderizar tu componente
    return <div>Related Items</div>;
  };

 

function SearchStatus({ value, hasResults }: SearchStatusProps) {
    if (hasResults) return null

    if (value.length < minLength)
        return (
            <li className="status short" style={{ margin: 32, listStyle: 'none' }}>
                Escribe al menos 3 letras para buscar
            </li>
        )

    return (
        <li className="status no-results" style={{ margin: 32, listStyle: 'none' }}>
            Sin resultados
        </li>
    )
}

interface WidgetComponentProps {
  userHistory: Array<HistoryItem>,
  searchCoincidences: Array<any>,
  availableDestinations: Array<AvailableDestinationsList>,
  onGetCoincidences: (value: string) => void,
}

const WidgetComponent = (props: WidgetComponentProps): JSX.Element => {
    const cartContext = useCart();

    const modal = useModal()

    const navigate = useNavigate();
    const appConfig = useAppConfig();
    
    const bookingEngineContext = useBookingEngineContext();

    const { userHistory, onGetCoincidences, searchCoincidences, availableDestinations } = props;

    const onChangeSearchValue = (value: string) => {
      onGetCoincidences(value);
    }
    
    const [countries, setCountries] = useState<Array<DestinationListItem>>([]);
    const setInitialCountries = (data: Array<AvailableDestinationsList>) => {
      // obtenemos los countries para ponerlos en countries usando setCountries:
      const countryList : Array<DestinationListItem> = data.map((d: AvailableDestinationsList) => {
        return {
          id: d.countryCode,
          label: d.countryName,
          component: <Place place={d.countryName} hotelsNumber={d.cities.length}/>,
        }
      })

      setCountries(countryList);
    };

    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
    const handleSelectCountry = (id: string) : Array<DestinationListItem> => {
      const country = availableDestinations.find((d: AvailableDestinationsList) => d.countryCode === id);
      setSelectedCountryCode(id);
      const cities = country?.cities.map((c) => {
        return {
          id: c.cityCode,
          label: c.cityName,
          component: <Place place={c.cityName} hotelsNumber={c.hotels.length}/>,
        }
      }) as Array<DestinationListItem>;

      return cities;
    }

    const handleSelectRegion = (id: string) : Array<DestinationListItem> => {
      const country = availableDestinations.find((d: AvailableDestinationsList) => d.countryCode === selectedCountryCode);
      const region = country?.cities.find((c) => c.cityCode === id);
      const hotels = region?.hotels.map((h) => {
        return {
          id: h.hotelCode,
          label: h.hotelName,
          component: <p style={{ margin: 0 }}>
                      <span>{h.hotelName}</span>
                    </p>,
        }
      }) as Array<DestinationListItem>;


      return hotels;
    }

    async function handleSelect(id: string,category: string): Promise<DestinationListChilds | null> {
      if (category === 'countries')
        return {
          category: 'regions',
          items: handleSelectCountry(id),
          header: <h4>{t('components.widget-component.regions')}</h4>,
        }
      if (category === 'regions')
        return {
          category: 'hotels',
          items: handleSelectRegion(id),
          header: <h4>{t('components.widget-component.hotels')}</h4>,
        }
      return null
    }

    const [selectedHotel, setSelectedHotel] = useState<{name: string, code: string}>({name: '', code: ''});
    const searchConf = { history: userHistory, list: searchCoincidences, mapObject: <GoogleMaps apiKey={appConfig.config.scripts.find(script => script.key === 'google-maps-api-key')?.value} hotelList={appConfig.config.hotels} onSelectedHotel={setSelectedHotel}/> };

    const destinationList = {
                                category: 'countries',
                                elements: countries,
                                handleSelectItem: ({ category, selection }: any) => handleSelect(selection.id, category),
                                header: <h4>{t('components.widget-component.destinations')}</h4>,
                            };

    const [lastSearchType, setLastSearchType] = useState<number>(1);
    const [lastSearchCode, setLastSearchCode] = useState<string>('');

    useEffect(()=>{
      setInitialCountries(availableDestinations)
    },[availableDestinations])

    const googleMapsContext = useGoogleMapsContext();
    useEffect(() => {
        const googleMapsApiKey = appConfig.config.scripts.find(script => script.key === 'google-maps-api-key')?.value;
        googleMapsContext.initMapScript(googleMapsApiKey);
      }, []);


    return <div >
                <WidgetWrapper
                  defaultLabel={selectedHotel?.name ?? bookingEngineContext.label}
                  initialFrom={bookingEngineContext.dates.from}
                  initialTo={bookingEngineContext.dates.to}
                  handleChangeValue={ async (value: any) => onChangeSearchValue(value) }
                  map={{
                    handleShowMap: (props) => modal.show('map-modal', props),
                    closeMap: () => modal.hide(),
                    items: [hotelUtils.hotel],
                    mapKey: 'AIzaSyDIdhJZxHzb9cNHbj_e10MEeRH6zYynVlY',
                  }} 
                  
                  search={searchConf} 

                  destinationList={destinationList} 
                
                  handleClickSearch={function (d): Promise<void> { 

                      console.log("Push search:", d);  

                      //Limpiamos el carrito
                      cartContext.clearCart();

                      let searchType = lastSearchType ?? EngineDestinationTypeLocation;
                      let searchCode = lastSearchCode ?? '';

                      let theRooms = d.rooms.map((r: any) => {
                        return {
                            persons: {
                                adults: {
                                    quantity: r.persons.adults.quantity,
                                    min: r.persons.adults.min,
                                    max: r.persons.adults.max
                                },
                                kids: {
                                    ages: r.persons.kids.ages,
                                    quantity: r.persons.kids.quantity,
                                    min: r.persons.kids.min,
                                    max: r.persons.kids.max
                                }
                            },
                            personsAges: {
                                kids: {}
                            },
                            roomId: r.roomId
                        }
                      });

                      var errorInSearch : boolean = false;
                      //TODO: Hay que hacer que cuando se selecciona algo venga el code en el widget
                      if (!d.destination.label || d.destination.label === '') {
                        errorInSearch = true;
                        notification.warning({
                          message: t('components.widget-component.alert'),
                          description: t('components.widget-component.error-select-destinations'),
                        })
                      }else if (d.dates.from === null || d.dates.to === null) {
                        errorInSearch = true;
                        notification.warning({
                          message: t('components.widget-component.alert'),
                          description: t('components.widget-component.error-select-dates'),
                        })
                      }

                      if (!errorInSearch) {
                        let newContext = {
                          searchType: searchType,
                          searchCode: searchCode,
                          promotionalCode: d.code,
                          label: d.label,
                          dates: {
                              from: moment(d.dates.from).toDate(),
                              to: moment(d.dates.to).toDate()
                          },
                          rooms: theRooms
                        };
  
                        console.log("New Context", newContext);
  
                        bookingEngineContext.updateContext(newContext);

                        if (window.location.pathname !== GetFormattedUrl('private/booking-engine')) {
                            navigate(GetFormattedUrl('private/booking-engine'));
                        }
                      }
                      

                      return Promise.resolve();
                  } } 
                
                  status={SearchStatus} 
                
                  calendar={{
                      RelatedItemComponent: RelatedItemComponent,
                      ValueComponent: (props) => <div className="app-calendar-pvp-details">{ props.price.pvp.toFixed(2) }</div>,
                      relatedHotels: [],
                      config: { maxDuration: 10, minAntelation: 1, minDuration: 2 },
                      data: {
                          '2023-10': Array.from({ length: 31 }, getRandomItem),
                          '2023-11': Array.from({ length: 30 }, getRandomItem),
                          '2023-12': Array.from({ length: 31 }, getRandomItem),
                          '2024-01': Array.from({ length: 31 }, getRandomItem),
                          '2024-02': Array.from({ length: 31 }, getRandomItem),
                          '2024-03': Array.from({ length: 31 }, getRandomItem),
                      } as any,
                      handleInterval: function (interval, status): void {console.error("Function not implemented 4.") },
                      handleUpdateData: function (key: string): Promise<void> { console.error("Function not implemented 5."); return Promise.resolve(); }
                  }} 
                
                  roomType={{ options: appConfig.config.widget.availableRoomTypes as SelectedRoom[] }} 
                
                  rooms={{
                            // Sirve para especificar los datos de la primera habitación de múltiple
                            defaultRoom: {
                              persons: {
                                adults: {
                                  quantity: 2,
                                  min: appConfig.config.widget.minGuestsPerRoom,
                                  max: appConfig.config.widget.maxAdultsPerRoom,
                                },
                                kids: {
                                  quantity: 0,
                                  min: 0,
                                  max: appConfig.config.widget.maxChildsPerRoom,
                                  ages: {},
                                },
                              },
                              roomId: 1,
                            },
                            handlePersonsAge: async (roomId, index, value) =>
                              //Método para actualizar la edad de un niño
                              console.log(
                                `a) room with id:${roomId}, index:${index} updated age with value:${value}`
                              ),
                            handlePersonsAmount: async (type, roomId, value) =>
                              //Método para actualizar la cantidad de personas
                              console.log(
                                `b) room with id:${roomId}, type: ${type} updated amount with value:${value}`
                              ),
                            maxGuests: appConfig.config.widget.maxGuestsPerRoom,
                            minGuests: appConfig.config.widget.minGuestsPerRoom,
                            maxRooms: appConfig.config.widget.maxRooms,
                            minRooms: appConfig.config.widget.minRooms,
                    }} 
                
                  handleHotelSelection={(items: any) => console.log(`current selection ${items}`, items) }
                
                  retrievers={{
                      retrieveItemsByName: (v: any) => { 
                        console.log("V", v)
                        throw new Error("Not implemented (1)"); 
                      },
                      retrieveItemsByCategory: (searchType: any, searchData: any) => { 
                        //Search Type is hotel or place. If hotel then search availability by hotel code, else if place then search availability by place code
                        setLastSearchType(searchType === 'hotel' ? EngineDestinationTypeHotel : EngineDestinationTypeLocation);
                        setLastSearchCode(searchData.id);

                        return Promise.resolve([]);
                      },
                      retrieveItemsById: (v: any) => { 
                        console.log("V", v)  
                        return Promise.resolve([]);
                      },
                  }} 
                />
            </div>
}

export default WidgetComponent;