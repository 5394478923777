import {
  AdditionalInfoIcons,
  Meal,
  Policy,
  Room,
  RoomRate,
} from "@beds2b-group/reusable-components/dist/types";
import { Hotel, RoomStaticData, Image, AmountType, ModifierType } from "../models/availability/Commons";
import {
  AdditionalInfo,
  AlertType,
  CancellationPolicy,
  HotelAvailability,
  HotelAvailabilityResponse,
  PenaltyType,
  RoomAvailability,
} from "../models/availability/HotelAvailability";
import { RoomCard } from "@beds2b-group/reusable-components";

export function HotelsToAppAvailability(
  hotelAvailability: HotelAvailabilityResponse,
  actualStepRoomSelector: number,
  onSelectRoom: (roomData: any, mealData: any, rateData: any) => void,
  modal: any
): JSX.Element[] {
  let result: JSX.Element[] = [];

  let hotelCode: string = '';

  const availabilityData: RoomAvailability[] = [];

  hotelAvailability.HotelAvailabilities.forEach((hotelAvailability) => {
    hotelCode = hotelAvailability.HotelCode;

    hotelAvailability.RoomAvailabilities.forEach((roomAvailability) => {
      if (Number.parseInt(roomAvailability.AvailabilityRoomIndex) == actualStepRoomSelector) {
        availabilityData.push(roomAvailability);
      }
    });
  });
  


  availabilityData.forEach((roomAvailability: RoomAvailability, index: number) => {
    const staticHotelData: Hotel | undefined = hotelAvailability.Hotels.find(
      (hotel) => {
        return hotel.Code == hotelCode;
      }
    );

    const staticRoomData =
      staticHotelData?.StaticHotelData?.RoomStaticData.find(
        (room: RoomStaticData) => {
          return room.Code == roomAvailability.RoomTypeCode;
        }
      );

    const staticRoomImages: string[] =
      staticRoomData?.Images.map((image: Image) => image.LargeImageUrl).filter(
        (url): url is string => url !== null
      ) || [];

    const staticHotelImages: string[] =
      staticHotelData?.StaticHotelData?.Images?.map(
        (image: Image) => image.LargeImageUrl
      ).filter((url): url is string => url !== null) || [];

    let priceFrom = Number.MAX_SAFE_INTEGER;
    let currency = "";
    const rates: Array<RoomRate> = roomAvailability.Rates.map((rateInfo) => {
      let meals: Array<Meal> = [];

      rateInfo.Meals.sort((mealA, mealB) => {
        //Ordenamos por precio de menor a mayor
        return mealA.PricingInfo.PvpWithoutGuarantee.Pvp <=
          mealB.PricingInfo.PvpWithoutGuarantee.Pvp
          ? -1
          : 1;
      }).map((meal) => {
        let staticMealData =
          staticHotelData?.StaticHotelData?.MealStaticData.find(
            (staticMeal) => {
              return staticMeal.Code == meal.Code;
            }
          );

          let discountData : {
            amount: number;
            type: 'percentage' | 'fixed';
        } | undefined = undefined;

          if (meal.PriceModifier){
            discountData = {
              amount: meal.PriceModifier.Amount * (meal.PriceModifier.ModifierType === ModifierType.Discount ? -1 : 1),
              type: meal.PriceModifier.AmountType == AmountType.Fixed ? 'fixed' : 'percentage'
            }
          }


          priceFrom =
            meal.PricingInfo.PvpWithoutGuarantee.Pvp < priceFrom
              ? meal.PricingInfo.PvpWithoutGuarantee.Pvp
              : priceFrom;
          currency = meal.PricingInfo.PvpWithoutGuarantee.CurrencyIsoCode;
          meals.push({
            rateKey: meal.RateKey,
            code: meal.Code,
            name: staticMealData?.Name ?? "N/D",
            price: {
              currency: meal.PricingInfo.PvpWithoutGuarantee.CurrencyIsoCode,
              pvp: meal.PricingInfo.PvpWithoutGuarantee.Pvp,
            },
            discount: discountData
          });
      });

      var additionalInfo = rateInfo.AdditionalInfo.map(
        (info: AdditionalInfo) => {
          let iconType: string = "ok" as AdditionalInfoIcons;
          switch (info.AlertType) {
            case AlertType.Warning:
              iconType = "attention-circled";
              break;
            case AlertType.Danger:
              iconType = "attention";
              break;
            case AlertType.Info:
            default:
              iconType = "ok";
              break;
          }

          return {
            icon: iconType as AdditionalInfoIcons,
            message: info.Message,
          };
        }
      );

      let cancellationPolicies = {
        policies: [] as {
          refundable: boolean;
          currency: string;
          deadLine: Date;
          penalty: string;
        }[],
        description: "",
      };

      rateInfo.CancellationPolicies.forEach((policy: CancellationPolicy) => {
        let penaltyType: string = "";
        switch (policy.Penalty.Type) {
          case PenaltyType.Fix:
            penaltyType = "Fija";
            break;
          case PenaltyType.Percent:
            penaltyType = "Porcentaje";
            break;
          case PenaltyType.Nights:
            penaltyType = "Noches";
            break;
        }

        const penaltyAmount = `${policy.Penalty.Amount} ${penaltyType}`;

        cancellationPolicies.policies.push({
          refundable: !policy.NonRefundable,
          currency: currency,
          deadLine: policy.DeadLine ? new Date(policy.DeadLine) : new Date(),
          penalty: penaltyAmount,
        } as Policy);
      });

      const rate: RoomRate = {
        meals: meals,
        name: rateInfo.Name,
        dates: [new Date(), new Date()],
        additionalInfo: additionalInfo,
        isOffer: rateInfo.IsOffer,
        cancelPolicies: cancellationPolicies,
      };

      return rate;
    });

    let roomData: Room = {
      code: roomAvailability.RoomTypeCode,
      beds: staticRoomData?.RoomBeds.length ?? 0,
      images: staticRoomImages ?? [],
      name: staticRoomData?.Name ?? "N/D",
      price: {
        currency: currency,
        pvp: priceFrom,
      },
      remainingRooms: roomAvailability.Quota,
      services: [
        { icon: "wifi", name: "Escritorio" },
        { icon: "taxi", name: "Escritorio" },
        { icon: "wifi", name: "Minibar de pago" },
        { icon: "taxi", name: "Minibar de pago" },
        { icon: "wifi", name: "Minibar de pago" },
        { icon: "wifi", name: "Terraza" },
        { icon: "taxi", name: "Terraza" },
        { icon: "diamond", name: "Escritorio" },
        { icon: "taxi", name: "Minibar de pago" },
      ],
      description: staticRoomData?.Description ?? "N/D",
      shortDescription: staticRoomData?.ShortDescription ?? "N/D",
    };

    result.push(
      <RoomCard
        key={`${roomData.code}-${index}`}
        roomIndex={Number.parseInt(roomAvailability.AvailabilityRoomIndex)}
        room={roomData}
        roomRates={rates}
        nights={0}
        modals={{
          showImages: ()=> modal.show('room-modal'),
          showInfo: ()=> modal.show('room-rate-modal')
        }}
        handleBooking={function (
          room: Omit<Room, "beds">,
          meal: Meal,
          rate: RoomRate
        ): Promise<void> {
          onSelectRoom(room, meal, rate);
          return Promise.resolve();
        }}
      />
    );
  });

  return result;
}
