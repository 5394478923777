import './PublicHeaderComponent.scss'
import { useTranslation } from 'react-i18next';

import { LanguageSelector } from '..';
import { Link, Outlet } from 'react-router-dom';
import { GetDefaultLanguage, GetLanguageInUrl, IsHome, IsLaguagePresentInUrl, IsSectionInPath } from '../../../utils/urls';
import { useEffect, useState } from 'react';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { GetAgentIndependentIcon, GetContactIcon, GetHomeIcon, GetUserIcon } from '../../../utils/icons';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

interface PublicHeaderProps{
    showMenu?: boolean
}

const PublicHeaderComponent = (props: PublicHeaderProps) : JSX.Element => {
    const { showMenu } = props;
    const [languageUrl, setlanguageUrl] = useState<string>();

    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const goHome = () : void => {
        window.location.href = languageUrl ?? GetDefaultLanguage();
    }

    const [showDrawerMenu, setShowDrawerMenu] = useState<boolean>(false);
    const onShowDrawerMenu = () : void => {
        setShowDrawerMenu(true);
    }

    const onCloseDrawerMenu = () : void => {
        setShowDrawerMenu(false);
    }

    useEffect(()=>{
        var defaultLanguage = GetDefaultLanguage();

        if (IsLaguagePresentInUrl()){
            setlanguageUrl(GetLanguageInUrl());
        }else{
            setlanguageUrl(defaultLanguage);
        }
    },[])

    const mobileWithMenu = <header className={`public-header ${appConfig.device}`}>
                                <div className="menu-button" onClick={onShowDrawerMenu}>
                                    <MenuOutlined className='app-icon menu-icon' style={{width: 24, height: 24}}/>
                                </div>
                                <div className={`logo ${appConfig.device}`}>
                                    <img onClick={goHome} src={appConfig.config.images.appLogo} />
                                </div>
                                <div className="user-menu">
                                    <Link className={`app-link ${IsSectionInPath('login') ? 'app-active-link' : ''}`} to={`${languageUrl}/login`}>
                                        {
                                            GetUserIcon('app-icon')
                                        }
                                    </Link>
                                </div>
                                
                            </header>

    const withMenu = <header className={`public-header ${appConfig.device}`}>
                        <div className="logo">
                            <img onClick={goHome} src={appConfig.config.images.appLogo} />
                        </div>
                        <nav className={`main-menu ${appConfig.device}`}>
                            <Link className={`app-link ${IsHome() ? 'app-active-link' : ''}`} to={`${languageUrl}`}>{t('header-menu.home')}</Link>
                            <Link className={`app-link ${IsSectionInPath('register') ? 'app-active-link' : ''}`} to={`${languageUrl}/register`}>{t('header-menu.register')}</Link>
                            <Outlet />
                        </nav>
                        <div className="user-menu">
                            <Link className={`app-link ${IsSectionInPath('contact') ? 'app-active-link' : ''}`} to={`${languageUrl}/contact`}>
                            {
                                GetContactIcon('app-icon')
                            }
                            </Link>
                            
                            <Link className={`app-link ${IsSectionInPath('login') ? 'app-active-link' : ''}`} to={`${languageUrl}/login`}>
                                {
                                    GetUserIcon('app-icon')
                                }
                            </Link>
                            <Outlet />
                            <LanguageSelector />
                        </div>
                    </header>
                    

    const withoutMenu = <header className={`public-header ${appConfig.device}`}>
                            <div className="logo">
                                <img onClick={goHome} src={appConfig.config.images.appLogo} />    
                            </div>
                            <div className="user-menu">
                                <Link className={`app-link ${IsSectionInPath('contact') ? 'app-active-link' : ''}`} to={`${languageUrl}/contact`}>
                                {
                                    GetContactIcon('app-icon')
                                }
                                </Link>
                                
                                <Link className={`app-link ${IsSectionInPath('login') ? 'app-active-link' : ''}`} to={`${languageUrl}/login`}>
                                    {
                                        GetUserIcon('app-icon')
                                    }
                                </Link>
                                <Outlet />
                                <LanguageSelector />
                            </div>
                        </header>

    const getMenu = () : JSX.Element => {

        return showMenu ? (appConfig.device === "mobile" ? mobileWithMenu : withMenu)  : withoutMenu;
    }

    return(
        <div>
            <Drawer title={<LanguageSelector />} placement='left' open={showDrawerMenu} onClose={onCloseDrawerMenu} width={256}>
                <nav className={`main-menu vertical ${appConfig.device}`}>
                    <Link className={`app-link ${IsHome() ? 'app-active-link' : ''}`} to={`${languageUrl}`} onClick={()=>{setShowDrawerMenu(false);}}>
                        {GetHomeIcon('app-icon')}
                        {t('header-menu.home')}
                    </Link>
                    <Link className={`app-link ${IsSectionInPath('register') ? 'app-active-link' : ''}`} to={`${languageUrl}/register`} onClick={()=>{setShowDrawerMenu(false);}}>
                        {GetAgentIndependentIcon('app-icon')}
                        {t('header-menu.register')}
                    </Link>
                    <Link className={`app-link ${IsSectionInPath('contact') ? 'app-active-link' : ''}`} to={`${languageUrl}/contact`} onClick={()=>{setShowDrawerMenu(false);}}>
                        {GetContactIcon('app-icon')}
                        {t('header-menu.contact')}
                    </Link>
                    <Outlet />
                </nav>
            </Drawer>
            {getMenu()}
        </div>
    )
}

export default PublicHeaderComponent;

