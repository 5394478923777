import { useModal } from '@gluedigital/modal'
import { registerModal } from '@gluedigital/modal'
import hotelUtils from '../../mocks/hotels'
import { MapModal } from '@beds2b-group/reusable-components'

export interface MapModalWrapperProps {
  mapKey: string
  showList: () => void
  handleSelectHotel: (selection: Selection) => Promise<void>
}

export default function MapModalWrapper({
  mapKey,
  showList,
  handleSelectHotel,
}: MapModalWrapperProps) {
  const copyHotel1 = { ...hotelUtils.hotel }
  copyHotel1.address.geoLocation = {
    latitude: 38.371521,
    longitude: -0.418915,
  }
  const copyHotel2 = { ...hotelUtils.hotel }
  copyHotel2.address.geoLocation = {
    latitude: 37.968005,
    longitude: -1.132719,
  }
  const copyHotel3 = { ...hotelUtils.hotel }
  copyHotel3.address.geoLocation = {
    latitude: 39.988284,
    longitude: 0.00989,
  }

  const modal = useModal()
  return (
    <MapModal
      acceptList
      closeMap={() => modal.hide()}
      handleSelect={(e:any)=>{
        console.log("handleSelect", e);
      }}
      items={[hotelUtils.hotel, copyHotel1, copyHotel2, copyHotel3]}
      mapKey={mapKey}
      showList={showList}
    />
  )
}

registerModal('map-modal', MapModalWrapper)
