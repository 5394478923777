

import { useState } from 'react';
import { BookingPax, PaxTypeEnum } from '../../../models/BookingData'
import './OccupationComponent.scss'
import { useTranslation } from 'react-i18next';
import { GetShowMoreIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';

interface OccupationComponentProps{
    occupation: BookingPax[],
    checkOut: Date
}

const OccupationComponent = (props: OccupationComponentProps) : JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    
    const { occupation, checkOut } = props;
    
    const [visible, setVisible] = useState<boolean>(false);

    const toogleVisibility = () => {
        setVisible(!visible);
    } 

    const getPaxInfo = (pax: BookingPax, index: number, checkoutDate: any) : JSX.Element => {

        let paxType: string = "";

        switch(pax.paxType){
            case PaxTypeEnum.adult:
                paxType = `(${t('pages.booking-detail.pax-type.adult')})`;
                break;
            case PaxTypeEnum.child:
                paxType = `(${t('pages.booking-detail.pax-type.child')} - ${(t('pages.booking-detail.pax-type.years-in-checkout', [pax.age, moment(checkoutDate).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))})`;
                break;
            case PaxTypeEnum.infant:
                paxType = `(${t('pages.booking-detail.pax-type.infant')} - ${(t('pages.booking-detail.pax-type.years-in-checkout', [pax.age, moment(checkoutDate).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))})`;
                break;
            case PaxTypeEnum.teenager:
                paxType = `(${t('pages.booking-detail.pax-type.teenager')} - ${(t('pages.booking-detail.pax-type.years-in-checkout', [pax.age, moment(checkoutDate).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))})`;
                break;
            default:
                paxType = `(${pax.paxType})})`;
        }

        return <div className="pax-info">
                    <span>{index}. {pax.name} {pax.surname} {pax.secondSurname ?? '' } {paxType}</span>
               </div>
    }


    return(
        <div className="occupation-component">
            <div className="occupation" onClick={toogleVisibility}>
                {GetShowMoreIcon("plus-icon")} {t('pages.booking-detail.occupation')}
            </div>

            <ul className={`occupation-list ${visible ? 'visible' : 'hidden'}`}>
                {
                    occupation.map((pax: BookingPax, index: number) => {
                        return (
                            <li>
                                {
                                    getPaxInfo(pax, index+1, checkOut)
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default OccupationComponent;