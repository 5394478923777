import { Calendar } from "@beds2b-group/reusable-components/dist/types"

function randomItem(): Calendar {
  const toDate = new Date()
  toDate.setDate(toDate.getDate() + Math.floor(Math.random() * (3 - 1 + 1) + 1))

  return {
    dates: { from: new Date(), to: toDate },
    price: {
      currency: '€',
      pvp: Math.floor(Math.random() * (300 - 60 + 1) + 60),
    },
  }
}

const calendar: Record<number, Array<Calendar>> = {
  0: Array.from({ length: 31 }, randomItem),
  1: Array.from({ length: 29 }, randomItem),
  2: Array.from({ length: 31 }, randomItem),
  3: Array.from({ length: 30 }, randomItem),
  4: Array.from({ length: 31 }, randomItem),
  5: Array.from({ length: 30 }, randomItem),
  6: Array.from({ length: 31 }, randomItem),
  7: Array.from({ length: 31 }, randomItem),
  8: Array.from({ length: 30 }, randomItem),
  9: Array.from({ length: 31 }, randomItem),
  10: Array.from({ length: 30 }, randomItem),
  11: Array.from({ length: 31 }, randomItem),
}

export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

export function getRandomItem(): any {
  return {
    available: Math.random() < 0.9,
    price: {
      pvp: getRandomArbitrary(60, 300),
      currency: '',
    },
    culture: {
      exchangeRates: { $: 1 },
      localCurrency: '',
    },
  }
}

function getData(): Record<string, Array<any>> {
  const data: Record<string, Array<any>> = {
    '2023-10': Array.from({ length: 31 }, getRandomItem),
    '2023-11': Array.from({ length: 30 }, getRandomItem),
    '2023-12': Array.from({ length: 31 }, getRandomItem),
    '2024-01': Array.from({ length: 31 }, getRandomItem),
  }

  return data
}

const calendarUtils = {
  calendar,
  getData,
}



export default calendarUtils
