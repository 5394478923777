// Interfaces TypeScript

import { AlternativeDay, PriceModifier, Hotel, Price } from "./Commons";

interface LocationAvailabilityResponse {
  Hotels: Hotel[];
  LocationAvailabilities: LocationAvailability[];
}

interface LocationAvailability {
  HotelCode: string;
  MinPrice: Price;
  PriceModifier: PriceModifier | null;
  AvailabilityTypeData: AvailabilityTypeData;
  AlternativeDays: AlternativeDay[];
}

interface AvailabilityTypeData {
  AvailabilityType: AvailabilityType;
  Restrictions: Restrictions;
  AvailabilityTypeText: string;
  RestrictionText: string;
}

export enum AvailabilityType {
  HasAvailability = 0,
  NoAvailabilityHotelClosed = 1,
  NoAvailabilityNoQuota = 2,
  NoAvailabilityExistingRestrictions = 3,
}

export enum Restrictions {
  MinLos = 0,
  MaxLos = 1,
  Release = 2,
  Unknow = 3,
}

export type{
  LocationAvailabilityResponse,
  LocationAvailability,
  AvailabilityTypeData,
}


