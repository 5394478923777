import { LoginForm } from "../components";
import ShortCodeMethod from "../models/ShortCodeMethod";


export const generateComponent = (html: string, key: string | number, arrayMethods: ShortCodeMethod[]) : JSX.Element => {
    //Buscamos el tipo de componente que queremos añadir
    const regex = /\[COMPONENT_(.+)\]/g;
    const match = regex.exec(html);

    let componentType = '';
    match?.forEach(match => {
        if (match.charAt(0)!=="["){
            componentType = match;
        }
    })

    let component = <></> 

    switch(componentType){
        case 'LOGIN':
            component = <LoginForm onLogin={arrayMethods.find((m)=>m.name === 'onDoLogin')?.method as (userName: string, password: string)=>void} 
                                   doingLogin={false} 
                                   haveError={false}
                                   onForgetPassword={arrayMethods.find((m)=>m.name === 'onForgetPassword')?.method as ()=>void} 
                        />
            break;
        case 'REGISTER':
            component = <div>Este es un registro</div>
            break;
        default:
            component = <div>{componentType}</div>;
            break;
    }

    return component
}