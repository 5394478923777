import { AmountType, ModifierType, TaxType } from "../models/availability/Commons";
import {
  AlertType,
  HotelAvailabilityResponse,
  PaymentType,
  PenaltyType,
} from "../models/availability/HotelAvailability";

export const roomAvailabilityMock: HotelAvailabilityResponse = {
  HotelAvailabilities: [
    {
      HotelCode: "H123",
      ErrorCode: "",
      RoomAvailabilities: [
        {
          RoomTypeCode: "RT001",
          AvailabilityRoomIndex: "1",
          Quota: 10,
          Rates: [
            {
              Code: "R001",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit",
                  RateKey: "Ratekey12345",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 120 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 74 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 92,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 5, AmountType: AmountType.Fixed },
                  },
                  PriceModifier: {
                    Amount: 10,
                    Code: "D001",
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Supplement,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  RateKey: "Ratekey12346",
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 80 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 100 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 74 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 92,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 5, AmountType: AmountType.Fixed },
                  },
                  PriceModifier: {
                    Amount: 10,
                    Code: "D001",
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  }
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  RateKey: "Ratekey12347",
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 150 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 170 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 160,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 7, AmountType: AmountType.Fixed },
                  },
                  PriceModifier: {
                    Amount: 10,
                    Code: "D001",
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  }
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: PaymentType.Credit,
                  RateKey: "Ratekey12348",
                  PaymentTypeText: "Cash",

                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 220 },
                    GuaranteePrice: 30,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 15,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 188 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 208,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 10, AmountType: AmountType.Fixed },
                  },
                  PriceModifier: {
                    Amount: 10,
                    Code: "D001",
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  }
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  RateKey: "Ratekey12349",
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 270 },
                    GuaranteePrice: 40,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 20,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 232 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 252,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 15, AmountType: AmountType.Fixed },
                  },
                  PriceModifier: {
                    Amount: 10,
                    Code: "D001",
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  }
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Venta anticipada",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: true,
            },
            {
              Code: "R001",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: PaymentType.PrePayCard,
                  PaymentTypeText: "PrePayCard",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 120 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 112 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 130,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 5, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123410",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: PaymentType.PrePayCard,
                  PaymentTypeText: "PrePayCard",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 80 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 100 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 74 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 92,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 3, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123411",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 150 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 170 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 160,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 7, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123412",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 220 },
                    GuaranteePrice: 30,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 15,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 188 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 208,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 10, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123413",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 270 },
                    GuaranteePrice: 40,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 20,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 232 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 252,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 15, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123414",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Tarifa estándar",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
        },
        {
          RoomTypeCode: "RT002",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123415",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123416",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123417",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123418",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123419",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Tarifa Demo 1",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "1",
        },
        {
          RoomTypeCode: "RT003",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "RateKey123420",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123421",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123422",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123423",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123424",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "1",
        }
      ],
    },
    {
      HotelCode: "H123",
      ErrorCode: "E001",
      RoomAvailabilities: [
        {
          RoomTypeCode: "RT004",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123435",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123436",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123437",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123438",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123439",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "2",
        },
        {
          RoomTypeCode: "RT005",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123440",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123441",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123442",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123443",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123444",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "2",
        },
        {
          RoomTypeCode: "RT006",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123445",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123446",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123447",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123448",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123449",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "2",
        },
        {
          RoomTypeCode: "RT007",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123450",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123451",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123452",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123453",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123453",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "2",
        },
        {
          RoomTypeCode: "RT008",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123455",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123456",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123457",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123458",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123459",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "2",
        }
      ],
    },
    {
      HotelCode: "H123",
      ErrorCode: "",
      RoomAvailabilities: [
        {
          AvailabilityRoomIndex: "3",
          RoomTypeCode: "RT009",
          Quota: 8,
          Rates: [
            {
              Code: "R003",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 100 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 120 },
                    GuaranteePrice: 18,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 110,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 5, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123470",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 60 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 80 },
                    GuaranteePrice: 10,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 5,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 55 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 75,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 3, AmountType: 1 },
                  },
                  RateKey: "Ratekey123471",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 150 },
                    GuaranteePrice: 22,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 120 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 140,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 7, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123472",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 170 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 190 },
                    GuaranteePrice: 28,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 15,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 155 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 175,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 9, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123473",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 220 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 240 },
                    GuaranteePrice: 38,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 20,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 202 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 222,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 14, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123474",
                },
              ],
              AlternativeDays: [{
                AccommodationDates: {
                  From: "2023-12-05T14:00:00Z",
                  To: "2023-12-10T12:00:00Z",
                  Format: "yyyy-MM-ddTHH:mm:ssZ",
                },
                MinimalPrice: {
                  CurrencyIsoCode: "USD",
                  Pvp: 100,
                },
                PriceModifier: {
                  Code: "D001",
                  Amount: 10,
                  AmountType: AmountType.Percent,
                  AmountTypeText: "Percent",
                  ModifierType: ModifierType.Discount,
                  ModifierTypeText: "Early Booking",
                  IsDiscountAlreadyApplied: true,
                  AmountBeforeTax: 90,
                  AmountAfterTax: 80,
                  CurrencyIsoCode: "USD",
                  Description: "Early Booking Discount",
                  ModifierName: "Special Offer",
                },
              }],
              Name: "Premium Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: true,
            },
          ],
        },
        {
          RoomTypeCode: "RT010",
          Quota: 5,
          Rates: [
            {
              Code: "R002",
              CancellationPolicies: [
                {
                  HasExpired: false,
                  Code: "CP001",
                  NonRefundable: false,
                  DeadLine: "2023-12-01T12:00:00Z",
                  Penalty: {
                    Type: PenaltyType.Percent,
                    PenaltyTypeText: "Percentage",
                    Amount: 20,
                  },
                },
              ],
              Meals: [
                {
                  Code: "BB",
                  IsOffer: true,
                  PaymentType: 1,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 110 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 130 },
                    GuaranteePrice: 15,
                    Commissions: [
                      {
                        PaymentType: 1,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 104 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 122,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 4, AmountType: 1 },
                  },
                  RateKey: "Ratekey123475",
                },
                {
                  Code: "RO",
                  IsOffer: false,
                  PaymentType: 2,
                  PaymentTypeText: "PayPal",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 70 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 90 },
                    GuaranteePrice: 12,
                    Commissions: [
                      {
                        PaymentType: 2,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 6,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 4,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 64 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 82,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 2, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123476",
                },
                {
                  Code: "HB",
                  IsOffer: true,
                  PaymentType: 3,
                  PaymentTypeText: "Bank Transfer",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 140 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                    GuaranteePrice: 20,
                    Commissions: [
                      {
                        PaymentType: 3,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 8,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 124 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 144,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 6, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123477",
                },
                {
                  Code: "FB",
                  IsOffer: false,
                  PaymentType: 4,
                  PaymentTypeText: "Cash",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 180 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 200 },
                    GuaranteePrice: 25,
                    Commissions: [
                      {
                        PaymentType: 4,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 12,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 10,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 160 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 180,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 8, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123478",
                },
                {
                  Code: "AI",
                  IsOffer: true,
                  PaymentType: PaymentType.Credit,
                  PaymentTypeText: "Credit Card",
                  PricingInfo: {
                    PvpWithoutGuarantee: { CurrencyIsoCode: "USD", Pvp: 230 },
                    PvpWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 250 },
                    GuaranteePrice: 35,
                    Commissions: [
                      {
                        PaymentType: PaymentType.Credit,
                        CommissionWithGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 18,
                        },
                        CommissionWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 16,
                        },
                        NetWithGuarantee: { CurrencyIsoCode: "USD", Pvp: 194 },
                        NetWithoutGuarantee: {
                          CurrencyIsoCode: "USD",
                          Pvp: 214,
                        },
                      },
                    ],
                    MixedPayment: { Amount: 12, AmountType: AmountType.Fixed },
                  },
                  RateKey: "Ratekey123479",
                },
              ],
              AlternativeDays: [
                {
                  AccommodationDates: {
                    From: "2023-12-05T14:00:00Z",
                    To: "2023-12-10T12:00:00Z",
                    Format: "yyyy-MM-ddTHH:mm:ssZ",
                  },
                  MinimalPrice: {
                    CurrencyIsoCode: "USD",
                    Pvp: 100,
                  },
                  PriceModifier: {
                    Code: "D001",
                    Amount: 10,
                    AmountType: AmountType.Percent,
                    AmountTypeText: "Percent",
                    ModifierType: ModifierType.Discount,
                    ModifierTypeText: "Early Booking",
                    IsDiscountAlreadyApplied: true,
                    AmountBeforeTax: 90,
                    AmountAfterTax: 80,
                    CurrencyIsoCode: "USD",
                    Description: "Early Booking Discount",
                    ModifierName: "Special Offer",
                  },
                }
              ],
              Name: "Flexible Rate",
              AdditionalInfo: [
                {
                  Message: "Additional information for the guest 1",
                  AlertType: AlertType.Danger,
                },
                {
                  Message: "Additional information for the guest 2",
                  AlertType: AlertType.Info,
                },
                {
                  Message: "Additional information for the guest 3",
                  AlertType: AlertType.Warning,
                },
              ],
              IsOffer: false,
            },
          ],
          AvailabilityRoomIndex: "3",
        }
      ],
    },
  ],
  Hotels: [
    {
      Code: "H123",
      Name: "Grand Hotel",
      Category: "5-star",
      CurrencyIsoCode: "USD",
      LanguageIsoCode: "English",
      TimeZone: "UTC-5:00",
      Description: "A luxurious 5-star hotel with breathtaking views",
      ShortDescription: "Luxury hotel with stunning views",
      ContactData: {
        ContactPerson: "John Smith",
        Email: "info@grandhotel.com",
        Phone: {
          Number: "34",
          Prefix: "123456",
        },
        WebSite: "www.grandhotel.com",
      },
      StaticHotelData: {
        Images: [
          {
            IsMainImage: true,
            LargeImageUrl: "https://example.com/grandhotel/large.jpg",
            MediumImageUrl: "https://example.com/grandhotel/medium.jpg",
            SmallImageUrl: "https://example.com/grandhotel/small.jpg",
          },
        ],
        RoomStaticData: [
          {
            Code: "RT001",
            NumberOfUnits: 100,
            Name: "Deluxe Room",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT002",
            NumberOfUnits: 100,
            Name: "Deluxe Room 2",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room 2",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT003",
            NumberOfUnits: 100,
            Name: "Deluxe Room 3",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT004",
            NumberOfUnits: 100,
            Name: "Deluxe Room 4",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT005",
            NumberOfUnits: 100,
            Name: "Deluxe Room 5",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT006",
            NumberOfUnits: 100,
            Name: "Deluxe Room 6",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT007",
            NumberOfUnits: 100,
            Name: "Deluxe Room 7",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT008",
            NumberOfUnits: 100,
            Name: "Deluxe Room 8",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT009",
            NumberOfUnits: 100,
            Name: "Deluxe Room 9",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
          {
            Code: "RT010",
            NumberOfUnits: 100,
            Name: "Deluxe Room 10",
            Type: "Standard",
            Description: "Spacious room with modern amenities",
            ShortDescription: "Luxurious Deluxe Room",
            RoomBeds: [
              {
                Type: 1,
                Size: 1.8,
                Description: "King-sized bed",
              },
            ],
            ServiceCategories: [
              {
                Code: "SC001",
                Name: "Room Amenities",
                Icon: "icon-bed",
                Description: "Services provided in the room",
                Services: [
                  {
                    Code: "S001",
                    Icon: "icon-coffee",
                    Name: "Coffee Maker",
                    Description: "Complimentary coffee maker in the room",
                  },
                  {
                    Code: "S002",
                    Icon: "icon-wifi",
                    Name: "Free Wi-Fi",
                    Description: "High-speed internet access",
                  },
                ],
              },
            ],
            Images: [
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//082_slider-vip.jpg",
              },
              {
                IsMainImage: true,
                LargeImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                MediumImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
                SmallImageUrl: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//125_slider-vip.jpg",
              },
            ],
            IsRoomAdapted: true,
          },
        ],
        MealStaticData: [
          {
            Code: "BB",
            Name: "Bed and Breakfast",
            Description: "Accommodation with breakfast included",
          },
          {
            Code: "RO",
            Name: "Room Only",
            Description: "Accommodation only, no meals included",
          },
          {
            Code: "HB",
            Name: "Half Board",
            Description: "Accommodation with breakfast and one main meal",
          },
          {
            Code: "FB",
            Name: "Full Board",
            Description: "Accommodation with all meals included",
          },
          {
            Code: "AI",
            Name: "All Inclusive",
            Description:
              "Accommodation with all meals and selected drinks included",
          },
        ],
        RateStaticData: [
          {
            Code: "R001",
            Name: "Standard Rate",
            Description: "Regular room rate",
          },
        ],
        ServiceCategories: [
          {
            Code: "SC001",
            Name: "Hotel Services",
            Icon: "icon-services",
            Description: "Various services offered by the hotel",
            Services: [
              {
                Code: "S001",
                Icon: "icon-pool",
                Name: "Swimming Pool",
                Description: "Outdoor pool for guests",
              },
              {
                Code: "S002",
                Icon: "icon-spa",
                Name: "Spa",
                Description: "Relaxing spa services",
              },
            ],
          },
        ],
        HotelAddress: {
          Altitude: 0,
          CityCode: "MLG",
          CityName: "Málaga",
          CountryCode: "SP",
          CountryName: "Spain",
          Latitude: 40.7128,
          Longitude: -74.006,
          StreetName: "123 Main Street, Cityville",
        },
        Tax: {
          Amount: 10,
          Description: "Taxes and fees",
          TaxType: TaxType.Tax,
        },
        PaxConfiguration: {
          Baby: {
            Minimum: 0,
            Maximum: 2.99,
          },
          Child: {
            Minimum: 3,
            Maximum: 12.99,
          },
          Teenager: {
            Minimum: 13,
            Maximum: 18,
          },
        },
      },
      Reputation: {
        Value: 4.5,
        Description: "Excellent",
      },
    },
  ],
  CrossSellingProducts: [],
};
