import { createContext, useContext, useEffect, useState } from "react"
import AppConfig from "../models/AppConfig";
import { fetchClientAppCustomData } from '../api/repositoryEP';

export const availableAppLanguages : Array<string> = ['es','us','mx','pt','gb'];

const defaultconfigValues : AppConfig = {
    mainColor: "black",
    secondaryColor: "black",
    scripts: [],
    widget:{
        searchTheme: "dark",
        mainColor: "white",
        secondaryColor: "black",
        availableRoomTypes: ['individual', 'double','multiple'],
        maxGuestsPerRoom: 4,
        maxAdultsPerRoom: 4,
        maxChildsPerRoom: 4,
        minGuestsPerRoom: 1,
        maxRooms: 10,
        minRooms: 1,
    },
    dateFormat: "DD/MM/YYYY",
    availableLanguages: [],
    siteName: '',
    legalTexts:{
        legalWarning: '',
        privacity: '',
        cookies: '',
        termsAndConditions: ''
    },
    images:{
        brandLogo: "",
        appLogo: "",
        loginBackGround: ""
    },
    availableUserTypes: [],
    availableCurrencies: [],
    prefixNames: [],
    customRegisterFields: [],
    hotels:[],
    contactPhones: [],
    contactFormParameters: {
        release: 1,
        minRoomsNumber: 1,
        maxRoomsNumber: 1,
        minOccupancyNumber: 1,
        maxOccupancyNumber: 1,
        availableBudgetTypes: [],
        availableServices: []
    }
};

const AppConfigContext = createContext<{config: AppConfig, device: 'mobile' | 'tablet' | 'desktop'}>({config: defaultconfigValues, device: 'desktop'});

export const AppConfigProvider: React.FC<{ children: React.ReactNode, responsive: 'mobile' | 'tablet' | 'desktop' }> = ({ children, responsive }) => {
    const [config, setConfig] = useState<AppConfig>(defaultconfigValues);
    const [device, setDevice] = useState<'mobile' | 'tablet' | 'desktop'>('desktop');

    useEffect(()=>{
        if (window.location.href.indexOf("/error/") == -1){
            fetchClientAppCustomData().then(response=> {
                if (response){
                    setConfig((response.data as AppConfig));
                }
            }).finally(()=>{
            });
        }
        
    },[]);

    useEffect(()=>{
        setDevice(responsive);
    },[responsive])

    return(
        <AppConfigContext.Provider value={{config, device}}>
            {children}
        </AppConfigContext.Provider>
    )
}

export const useAppConfig = () : {config: AppConfig, device: 'mobile' | 'tablet' | 'desktop'} => {
    return useContext(AppConfigContext);
}