import AppConfig from "../models/AppConfig";
import { clientHotelList } from "./hotels";

export var configClientApplication : AppConfig = {
    mainColor: "#D49B30",
    secondaryColor: "#212331",
    scripts: [
        {
            key: 'google-maps-api-key',
            value: 'AIzaSyDIdhJZxHzb9cNHbj_e10MEeRH6zYynVlY'
        }
    ],
    widget:{
        searchTheme: "light",
        mainColor: "#D49B30",
        secondaryColor: "#424C53",
        availableRoomTypes: ['single', 'double', 'multiple'],
        maxGuestsPerRoom: 4,
        maxAdultsPerRoom: 4,
        maxChildsPerRoom: 4,
        minGuestsPerRoom: 1,
        maxRooms: 10,
        minRooms: 1,
    },
    dateFormat: "DD/MM/YYYY",
    contactFormParameters: {
        release: 5,
        minRoomsNumber: 1,
        maxRoomsNumber: 10,
        minOccupancyNumber: 1,
        maxOccupancyNumber: 50,
        availableBudgetTypes: [
            'Reserva de habitaciones para grupos',
            'Reserva de salón para banquetes',
            'Reserva de salón para reunión',
            'Reserva de salón para exposición o ponencias',
            'Otros'
        ],
        availableServices: [
            'Botones',
            'Coffe Break',
            'Cocktail',
            'Cena de gala'
        ]
    },
    availableLanguages: [
        {"iso": "es-ES", "shortCode": "es", "description": "Español (España)", "enabled": true},
        {"iso": "es-MX", "shortCode": "mx", "description": "Español (México)", "enabled": false},
        {"iso": "pt-PT", "shortCode": "mx", "description": "Português (Portugal)", "enabled": false},
        {"iso": "pt-BR", "shortCode": "mx", "description": "Português (Brasil)", "enabled": false},
        {"iso": "en-EN", "shortCode": "en", "description": "English (GB)", "enabled": false},
        {"iso": "en-US", "shortCode": "us", "description": "English (EEUU)", "enabled": true},
        {"iso": "fr-FR", "shortCode": "fr", "description": "French", "enabled": false},
    ].sort((a, b) => a.description.localeCompare(b.description)),
    siteName: "Hotel Brand PRO",
    legalTexts:{
        legalWarning: 'Texto del aviso legal',
        privacity: 'Texto de la política de privacidad',
        cookies: 'Texto de las cookies',
        termsAndConditions: 'Texto de los términos y condiciones'
    },
    images:{
        brandLogo: "/images/hotelbrand-logo.png",
        loginBackGround: "",
        appLogo: "/images/hotelbrand-logo.png"
    },
    availableUserTypes: ["agent", "business", "independent", "events"],
    availableCurrencies: [
        {description: "Euro", id: "EUR", iso: "EUR", symbol: "€", available: true},
        {description: "Canadian Dollar", id: "CAN", iso: "CAN", symbol: "C$", available: false},
        {description: "American Dollar", id: "USD", iso: "USD", symbol: "$", available: true},
        {description: "Great British Pound", id: "GBP", iso: "GBP", symbol: "£", available: false}
    ],
    prefixNames: [
        "Sr.", "Sra.", "Srta"
    ],
    customRegisterFields: [
        {
            availableLanguages: [],
            order: 0,
            searchable: true,
            labels: [
                {
                    label: 'Campo personalizado no obligatorio',
                    languageIso: 'es-ES'
                },
                {
                    label: 'No mandatory field',
                    languageIso: 'en-US'
                }
            ],
            alone: false,
            name: "field1",
            defaultValue: '',
            options: [],
            required: false,
            type: 'input'
        },
        {
            availableLanguages: [],
            order: 1,
            searchable: true,
            labels: [
                {
                    label: 'Campo personalizado obligatorio',
                    languageIso: 'es-ES'
                },
                {
                    label: 'Mandatory field',
                    languageIso: 'en-US'
                }
            ],
            alone: false,
            name: "field2",
            defaultValue: '',
            options: [],
            required: true,
            type: 'input'
        },
        {
            availableLanguages: [],
            order: 3,
            searchable: true,
            labels: [
                {
                    label: 'Desplegable',
                    languageIso: 'es-ES'
                },
                {
                    label: 'Mandatory field',
                    languageIso: 'en-US'
                }
            ],
            alone: false,
            name: "field3",
            defaultValue: 'select-3',
            options: [
                {key: 'select-1', value: [
                    {
                        label: 'Opción 1',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 1',
                        languageIso: 'en-US'
                    }
                ]},
                {key: 'select-2', value: [
                    {
                        label: 'Opción 2',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 2',
                        languageIso: 'en-US'
                    }
                ]},
                {key: 'select-3', value: [
                    {
                        label: 'Opción 3',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 3',
                        languageIso: 'en-US'
                    }
                ]},
                {key: 'select-4', value: [
                    {
                        label: 'Opción 4',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 4',
                        languageIso: 'en-US'
                    }
                ]},
            ],
            required: true,
            type: 'select'
        },
        {
            availableLanguages: ['es'],
            order: 4,
            searchable: true,
            labels: [
                {
                    label: 'Cuadro de texto (solo disponible en español)',
                    languageIso: 'es-ES'
                }
            ],
            alone: true,
            name: "field5",
            defaultValue: '',
            options: [],
            required: true,
            type: 'text-area'
        }
    ],
    hotels: clientHotelList,
    contactPhones: [
        {
            isoCountry: "es",
            countryName: "España",
            phone: "123456789"
        },
        {
            isoCountry: "fr",
            countryName: "Francia",
            phone: "369852147"
        },
        {
            isoCountry: "us",
            countryName: "Estados Unidos",
            phone: "951623487"
        },
        {
            isoCountry: "cn",
            countryName: "China",
            phone: "369852147"
        },
        {
            isoCountry: "br",
            countryName: "Brasil",
            phone: "369852147"
        }
    ]
}