interface Address {
    Altitude: number;
    CityCode: string;
    CityName: string;
    CountryCode: string;
    CountryName: string;
    Latitude: number;
    Longitude: number;
    StreetName: string;
  }
  
  interface AgeRange {
    Maximum: number;
    Minimum: number;
  }
  
  interface AlternativeDay {
    AccommodationDates: RangeDateTime;
    PriceModifier: PriceModifier | null;
    MinimalPrice: Price;
  }
  
  interface ContactData {
    ContactPerson: string;
    Email: string | null;
    Phone: Phone | null;
    WebSite: string | null;
  }
  
  interface PriceModifier {
    Amount: number;
    AmountAfterTax: number;
    AmountBeforeTax: number;
    Code: string;
    CurrencyIsoCode: string;
    Description: string;
    AmountType: AmountType;
    AmountTypeText: string;
    IsDiscountAlreadyApplied: boolean;
    ModifierName: string;
    ModifierType: ModifierType;
    ModifierTypeText: string;
  }

  interface Hotel {
    Code: string;
    Name: string;
    Category: string;
    Description: string;
    ShortDescription: string;
    CurrencyIsoCode: string;
    LanguageIsoCode: string;
    TimeZone: string;
    ContactData: ContactData;
    StaticHotelData: HotelStaticData | null;
    Reputation: Reputation | null;
  }
  
  interface HotelStaticData {
    HotelAddress: Address;
    Images: Image[] | null;
    MealStaticData: MealStaticData[];
    PaxConfiguration: PaxConfiguration;
    RateStaticData: RateStaticData[];
    RoomStaticData: RoomStaticData[];
    ServiceCategories: ServiceCategory[];
    Tax: Tax;
  }
  
  interface Image {
    IsMainImage: boolean;
    LargeImageUrl: string | null;
    MediumImageUrl: string | null;
    SmallImageUrl: string | null;
  }
  
  interface MealStaticData {
    Code: string;
    Description: string;
    Name: string;
  }
  
  interface PaxConfiguration {
    Baby: AgeRange;
    Child: AgeRange;
    Teenager: AgeRange;
  }
  
  interface Phone {
    Number: string;
    Prefix: string;
  }
  
  interface Price {
    CurrencyIsoCode: string;
    Pvp: number;
  }
  
  interface RateStaticData {
    Code: string;
    Description: string;
    Name: string;
  }
  
  interface RangeDateTime {
    Format: string;
    From: string;
    To: string;
  }
  
  interface Reputation {
    Description: string;
    Value: number;
  }
  
  interface RoomBed {
    Description: string;
    Size: number;
    Type: RoomBedType;
  }
  
  interface RoomStaticData {
    Code: string;
    Description: string;
    Images: Image[];
    IsRoomAdapted: boolean;
    Name: string;
    NumberOfUnits: number;
    RoomBeds: RoomBed[];
    ServiceCategories: ServiceCategory[];
    ShortDescription: string;
    Type: string;
  }
  
 
  interface Service {
    Code: string;
    Description: string;
    Icon: string;
    Name: string;
  }
  
  interface ServiceCategory {
    Code: string;
    Description: string;
    Icon: string;
    Name: string;
    Services: Service[];
  }
  
  interface Tax {
    Amount: number;
    Description: string;
    TaxType: TaxType;
  }
  
  export enum AmountType {
    Fixed,
    Percent,
  }
  
  export enum ModifierType {
    Discount,
    Supplement,
    Unknow,
  }
  
  export enum TaxType {
    Tax,
    Vat,
  }

  export enum RoomBedType {
    Double,
    Single
  }
  
  export type {
    Address,
    AgeRange,
    AlternativeDay,
    ContactData,
    PriceModifier,
    Hotel,
    HotelStaticData,
    Image,
    MealStaticData,
    PaxConfiguration,
    Phone,
    Price,
    RateStaticData,
    RangeDateTime,
    Reputation,
    RoomBed,
    RoomStaticData,
    Service,
    ServiceCategory,
    Tax,
  } 