
import { useTranslation } from 'react-i18next';
import UpdateProfileData from '../../../models/UpdateProfileData';
import { useForm } from 'antd/es/form/Form';
import { Button, Col, Divider, Form, Input, Row, Upload, UploadProps, notification } from 'antd';
import { InfoCircleOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { useSessionData } from '../../../Contexts/SessionDataContext';
import HTMLReactParser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';

import './EditProfileComponent.scss'
import UpdatePersonalData from '../../../models/UpdatePersonalData';
import ChangePasswordData from '../../../models/ChangePasswordData';

interface EditProfileComponentProps{
    onSaveProfile: (data: UpdateProfileData) => void;
    onSaveContactData: (data: UpdatePersonalData) => void;
    onSavePassword: (data: ChangePasswordData) => void;
    savingIndex: number;
}

//Size in KB
const maxImageSize = 512;
const EditProfileComponent = (props: EditProfileComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const [message, setMessage] = useState<string>('');

    const uploaderProps: UploadProps = {
        beforeUpload: (file) => {
            const isValidImg = file.type === 'image/png' || file.type === 'image/jpeg';
            if (!isValidImg) {
                notification.error({
                    message: t('components.edit-profile.avatar-error')!,
                    description: t('components.edit-profile.avatar-error-description')!,
                });
            }

            //permitimos solo imagenes de 512KB
            const isLt512K = file.size / 1024 < maxImageSize;

            if (isValidImg && !isLt512K) {
                notification.error({
                    message: t('components.edit-profile.avatar-error')!,
                    description: t('components.edit-profile.avatar-error-description-size', [maxImageSize])!,
                });
            }

            return isValidImg && isLt512K;
        },
        onChange: (info) => {
          console.log(info.fileList);
        },
      };

    const { savingIndex } = props;

    const sessionData = useSessionData();

    const [avatarImage, setAvatarImage] = useState<string>('');

    const [formPersonalData] = useForm();
    const [formContactData] = useForm();
    const [formPassword] = useForm();

    const onSavePersonalData = () : void => {
        props.onSaveProfile(formPersonalData.getFieldsValue() as UpdateProfileData);
    }

    const onSaveContactData = () : void => {
        props.onSaveContactData(formContactData.getFieldsValue() as UpdatePersonalData);
    }

    const onSavePassword = () : void => {
        props.onSavePassword(formPassword.getFieldsValue() as ChangePasswordData);
    }

    const [disableButtonIndex, setDisableButtonIndex] = useState<number>(0);
    useEffect(()=>{
        setDisableButtonIndex(savingIndex);
    },[savingIndex])

    useEffect(()=>{
        if (sessionData){
            const avatar = createAvatar(identicon, {
                seed: sessionData.userData.UAID,
                // ... other options
              });
    
              if (avatar){
                setAvatarImage(avatar.toString());
            }
        }else{
            console.log("ERROR: No se ha podido generar el avatar");
        }

        
    },[sessionData])

    
    return(
        <div className="edit-profile-component">
                <Row gutter={16}>
                    <Col xs={24} lg={4}>
                        <Divider className='app-divider' orientation='left'>
                            {t('components.edit-profile.avatar')}
                        </Divider>
                        <div className="avatar-image">
                            <Upload
                                {...uploaderProps}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            >
                                {
                                    sessionData?.userData?.avatar ? <img src={sessionData?.userData?.avatar} /> : HTMLReactParser(avatarImage)
                                }
                            </Upload>
                            <small className="avatar-description">
                                {t('components.edit-profile.avatar-description')}
                            </small>
                        </div>
                        
                    </Col>
                    <Col xs={24} lg={20}>
                        <Divider className='app-divider' orientation='left'>{t('components.edit-profile.personal-data')}</Divider>
                        
                        <Form
                                form={formPersonalData}
                                layout="vertical"
                                name="edit-profile-form"
                                scrollToFirstError
                                onFinish={onSavePersonalData}
                            >
                                <Row gutter={16}>
                                

                            
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.name")}
                                        name="name"
                                        initialValue={sessionData?.userData?.name}
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.surname")}
                                        name="surname"
                                        initialValue={sessionData?.userData?.surname}
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}  lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.second-surname")}
                                        name="secondSurname"
                                        initialValue={sessionData?.userData?.secondSurname}
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <div className="actions">
                                        <Button className="app-button send-form-data" disabled={savingIndex === 1} htmlType='submit'>
                                            {savingIndex === 1 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                        </Button>
                                    </div>
                                </Col>
                                </Row>
                        </Form>

                        <Divider className='app-divider' orientation='left'>{t('components.edit-profile.contact-data')}</Divider>

                        <Form
                            form={formContactData}
                            layout="vertical"
                            name="edit-profile-form"
                            scrollToFirstError
                            onFinish={onSaveContactData}
                        >
                            <Row gutter={16}>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.email")}
                                        name="email"
                                        initialValue={sessionData?.userData?.email}
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.repeat-email")}
                                        name="emailRepeat"
                                        initialValue={sessionData?.userData?.email}
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.phone")}
                                        initialValue={sessionData?.userData?.phone}
                                        name="phone"
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <div className="actions">
                                        <Button className="app-button send-form-data" disabled={savingIndex === 2} htmlType='submit'>
                                            {savingIndex === 2 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                        <Divider className='app-divider' orientation='left'>{t('components.edit-profile.password-and-username')}</Divider>

                        <Form
                            form={formPassword}
                            layout="vertical"
                            name="edit-profile-form"
                            scrollToFirstError
                            onFinish={onSavePassword}
                        >
                            <Row gutter={16}>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.username")}
                                        name="username"
                                        initialValue={sessionData?.userData?.username}
                                        rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                    >
                                        <Input allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.password")}
                                        name="password"
                                    >
                                        <Input type="password" allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Form.Item
                                        label={t("components.edit-profile.repeat-password")}
                                        name="passwordRepeat"
                                    >
                                        <Input type="password" allowClear className="app-input"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <div className="actions">
                                        <Button className="app-button send-form-data" disabled={savingIndex === 3} htmlType='submit'>
                                             {savingIndex === 3 ? <LoadingOutlined /> : <SaveOutlined />} {t('components.edit-profile.update')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
        </div>
    )
}

export default EditProfileComponent;