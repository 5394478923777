import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import './ContactPhonesComponent.scss';
import { ContactPhoneInfo } from "../../../models/AppConfig";
import { ContactPhone } from "../..";


interface ContactPhonesComponentProps {
    contactPhoneList: ContactPhoneInfo[] 
}
const ContactPhonesComponent = (props: ContactPhonesComponentProps) : JSX.Element => {
    
    const {contactPhoneList} = props;

    const { t } = useTranslation();

    const [maxItems, setMaxItems] = useState<number>(2);
    const [contactPhones, setContactPhones] = useState<ContactPhoneInfo[]>([]);

    const showItems = (n : number) : void => {
        setMaxItems(n);
    }

    useEffect(()=>{
        if (contactPhoneList){
            setContactPhones(contactPhoneList);
        }    
    },[contactPhoneList])

    return(
        <div >
                <div className="phone-container">
                    {
                        contactPhones?.slice(0, 2).map((data: ContactPhoneInfo, index: number)=>{
                            return <ContactPhone phone={data.phone} isoCountry={data.isoCountry} countryName={data.countryName} key={index}/>
                        })
                    }
                </div>
                <div className="more-and-less">
                    {
                        maxItems == 2 && contactPhones.length > 0 ? <span className="app-link no-hover" onClick={()=>{showItems(contactPhones.length)}}>{t('pages.contact-page.show-more')}</span> : <span className="app-link no-hover" onClick={()=>{showItems(2)}}>{t('pages.contact-page.show-less')}</span>
                    }
                </div>
                <div className="phone-container">
                    {
                        maxItems > 2 ? 
                        contactPhones?.slice(2, maxItems).map((data: ContactPhoneInfo, index: number)=>{
                            return <ContactPhone phone={data.phone} isoCountry={data.isoCountry} countryName={data.countryName} key={index}/>
                        })
                        :
                        <></>
                    }
                </div>
        </div>
    )
}

export default ContactPhonesComponent;