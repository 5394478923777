import { Code } from "@beds2b-group/reusable-components";


const EngineCodeComponent = () : JSX.Element => {

    const onChangeCode = (value: string) => {
        throw new Error("Function not implemented (1).");
    };

    return (
        <div>
            <Code handleCode={onChangeCode} code={""} enabled={true} />
        </div>
    )
}

export default EngineCodeComponent;