import {
  AlternativeDates,
  HotelProperties,
  RibbonError,
} from "@beds2b-group/reusable-components/dist/types";
import { AlternativeDay, Hotel } from "../models/availability/Commons";
import {
  AvailabilityType,
  LocationAvailability,
  LocationAvailabilityResponse,
  Restrictions,
} from "../models/availability/LocationsAvailability";
import { HotelCard } from "@beds2b-group/reusable-components";
import { EngineDestinationTypeHotel } from "./constants";
import hotelUtils from "../mocks/hotels";


const Link = ({ handleClick, to, children, className }: any) => {
    return (
      <button className={className} onClick={handleClick}>
        <a href={to}>{children}</a>
      </button>
    )
  }

export function LocationToAppAvailability(
  locationAvailability: LocationAvailabilityResponse,
  bookingEngineContext: any,
  modal: any
): JSX.Element[] {
  let result: JSX.Element[] = [];

  locationAvailability.LocationAvailabilities.forEach(
    (availability: LocationAvailability, index: number) => {
      let hotelData: Hotel | undefined = locationAvailability.Hotels.find(
        (hotel) => {
          return hotel.Code == availability.HotelCode;
        }
      );

      if (hotelData) {
        let hotelImages =
          hotelData.StaticHotelData && hotelData.StaticHotelData.Images
            ? hotelData.StaticHotelData.Images.map((image: any) => {
                return image.LargeImageUrl!;
              })
            : [];

        let alternativeDates: Array<AlternativeDates> = [];

        if (availability.AlternativeDays) {
          availability.AlternativeDays.forEach(
            (alternativeDay: AlternativeDay) => {
              let alternativeDate: AlternativeDates = {
                from: new Date(alternativeDay.AccommodationDates.From),
                to: new Date(alternativeDay.AccommodationDates.To),
                price: {
                  currency: alternativeDay.MinimalPrice.CurrencyIsoCode,
                  pvp: alternativeDay.MinimalPrice.Pvp,
                },
              };

              alternativeDates.push(alternativeDate);
            }
          );
        }

        let services : any[] = [];
        hotelData.StaticHotelData?.ServiceCategories.map((category) => {
          category.Services.map((service) => {
            services.push({
              icon: service.Icon,
              name: service.Description,
            })
          });
        });

        let hotel: HotelProperties = {
          code: hotelData?.Code ?? "",
          images: hotelImages,
          starts: Number.parseInt(hotelData?.Category ?? "0"),
          services: services,
          shortDescription: hotelData.ShortDescription,
          alternativeDays: alternativeDates,
          price: {
            currency: availability.MinPrice.CurrencyIsoCode,
            pvp: availability.MinPrice.Pvp,
          },
          description: hotelData.Description,
          address: {
            city: hotelData.StaticHotelData?.HotelAddress.CityName ?? "",
            country: hotelData.StaticHotelData?.HotelAddress.CountryName ?? "",
            geoLocation: {
              latitude: hotelData.StaticHotelData?.HotelAddress.Latitude ?? 0,
              longitude: hotelData.StaticHotelData?.HotelAddress.Longitude ?? 0,
            },
            line1: hotelData.StaticHotelData?.HotelAddress.StreetName ?? "",
            zipCode: "ZC N/D",
          },
          name: hotelData.Name,
        };

        let hotelError: RibbonError | undefined = undefined;
        if (availability.AvailabilityTypeData) {
          let type: string = "";
          switch (availability.AvailabilityTypeData.AvailabilityType) {
            case AvailabilityType.HasAvailability:
              // Tiene dispo, así que no hay problema.
              break;
            case AvailabilityType.NoAvailabilityHotelClosed:
              hotelError = {
                code: "HotelClosed",
                translation:
                  "El hotel se encuentra cerrado en las fechas indicadas",
                classes: ["ribbon-error"],
                type: "error",
              };
              break;
            case AvailabilityType.NoAvailabilityNoQuota:
              hotelError = {
                code: "HotelNoQuota",
                translation:
                  "El hotel no dispone de cupo para las fechas indicadas",
                classes: ["ribbon-error"],
                type: "error",
              };
              break;
            case AvailabilityType.NoAvailabilityExistingRestrictions:
              let text: string = "";
              switch (availability.AvailabilityTypeData.Restrictions) {
                case Restrictions.MinLos:
                  text = "El hotel tiene una estancia mínima de xxx noches";
                  break;
                case Restrictions.MaxLos:
                  text = "El hotel tiene una estancia máxima de xxx noches";
                  break;
                case Restrictions.Release:
                  text = "El hotel tiene una release de xxx noches";
                  break;
                case Restrictions.Unknow:
                  text =
                    "Existe una restricción no especificada en el sistema.";
                  break;
              }

              hotelError = {
                code: "HotelRestrictions",
                translation: text,
                classes: ["ribbon-warning"],
                type: "warning",
              };

              break;
          }
        }

        result.push(
          <HotelCard
            key={index}
            Link={Link}
            handleBooking={async (data: any) => {
              let newContext = {
                searchType: EngineDestinationTypeHotel,
                searchCode: data.hotel.code,
                promotionalCode: bookingEngineContext.promotionalCode,
                label: data.hotel.name,
                dates: bookingEngineContext.dates,
                rooms: bookingEngineContext.rooms,
              };
              bookingEngineContext.updateContext(newContext);

              return new Promise((res) => null);
            }}
            routes={{
              alternativeDates: "",
              booking: "",
            }}
            hotel={hotel}
            showImagesModal={(props) => {
              modal.show("hotel-modal", hotel);
            }}
            error={hotelError}
          />
        );
      }
    }
  );

  return result;
}
