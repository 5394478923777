import { useEffect, useState } from "react"
import { PublicFooter, PublicHeader } from "../../components/common"

import './PublicPageWrapper.scss'
import { useAppConfig } from "../../Contexts/AppConfigContext"
import { FloatButton } from "antd"

interface PublicPageWrapperProps {
    showMenu?: boolean
    onlyContent?: boolean
    children: JSX.Element
    widthPage?: string
}


const PublicPageWrapper = (props: PublicPageWrapperProps) : JSX.Element => {
    const appConfig = useAppConfig();

    function hexToRgb(hex: any) {
      // Elimina el signo '#' si está presente
      hex = hex.replace(/^#/, '');
    
      // Divide el valor hexadecimal en componentes RGB
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
    
      return `${r}, ${g}, ${b}`;
    }

    const [style, setStyle] = useState<string>('');
    useEffect(()=>{
      setStyle(`
              :root {
                --primary-app-color: ${appConfig.config.mainColor};
                --secondary-app-color: ${appConfig.config.secondaryColor};
                --rgba-05: rgba(${hexToRgb(appConfig.config.mainColor)}, 0.2);
              }
            `)
    },[appConfig])
  
    return (
        <div className="page-container public-page-wrapper" >
            <style>{style}</style>
            {
              props.onlyContent != null && props.onlyContent == true ? '' : <PublicHeader  showMenu={props.showMenu ?? false}/>
            }

            <div className={`page-content ${props.widthPage ?? 'max-width'}`}>
              {props.children}
            </div>

            {
              props.onlyContent != null && props.onlyContent == true ? '' : <PublicFooter />
            }

            <FloatButton.BackTop />
        </div>
    )
}

export default PublicPageWrapper;



