import moment from "moment"
import { createContext, useContext, useEffect, useState } from "react"
import { EngineDestinationTypeHotel, EngineDestinationTypeLocation } from "../utils/constants"



export interface BookingEngineContextData {
    searchType: number,
    searchCode: string,
    label: string,
    dates: {
        from: Date | null,
        to: Date | null
    },
    rooms: {
        persons: {
            adults: {
                quantity: number,
                min: number,
                max: number
            },
            kids: {
                ages: number[],
                quantity: number,
                min: number,
                max: number
            }
        },
        personsAges: {
            kids: {}
        }
    }[],
    promotionalCode: string,
    updateContext: (data: Partial<BookingEngineContextData>) => void;
    clearContext: () => void;
    isValidData: () => boolean;
}

const emptyContext = {
    searchType: EngineDestinationTypeLocation,
    searchCode: "",
    label: "",
    dates: {
        from: null,
        to: null
    },
    rooms: [],
    promotionalCode: "",
    clearContext: () => {
        // implementa la lógica de validación aquí
        return true; // o false, dependiendo de la lógica de validación
    },
    updateContext: (data: Partial<BookingEngineContextData>) => {
        // implementa la lógica de actualización aquí
    },
    isValidData: () => {
        // implementa la lógica de validación aquí
        return true; // o false, dependiendo de la lógica de validación
    }
} as BookingEngineContextData;

// Creamos un método en el contexto para limpiar los campos
export const clearBookingEngineContext = () => {
    return emptyContext;
}

// Creamos el contexto
const BookingEngineContext = createContext<BookingEngineContextData>({} as BookingEngineContextData);


interface BookingEngineProviderProps {
    children: React.ReactNode;
  }
export const BookingEngineProvider: React.FC<BookingEngineProviderProps> = ({ children }) => {
    const [contextData, setContextData] = useState<BookingEngineContextData>(clearBookingEngineContext());

    const updateContext = (data: Partial<BookingEngineContextData>) => {
        console.log("Updating context", data);
        setContextData((prevData: any) => ({ ...prevData, ...data }));
    };

    const clearContext = () => {
        console.error("entra en clearContext");
        setContextData(clearBookingEngineContext());
    }

    const isValidData = () : boolean => {

        //Verificamos el formato de las fechas
        var isValid = contextData.dates.from != null && moment(contextData.dates.from).isValid() &&  contextData.dates.to != null && moment(contextData.dates.to).isValid();

        if (!isValid){
            console.warn("Dates are not valid", contextData.dates.from, contextData.dates.to)
        }

        //Verificamos que el tipo de búsqueda sea válido
        isValid = isValid && (contextData.searchType === EngineDestinationTypeLocation || contextData.searchType === EngineDestinationTypeHotel);

        if (!isValid){
            console.warn("Search type is not valid", contextData.searchType)
        }

        //Verificamos que haya al menos una habitación
        isValid = isValid && contextData.rooms.length > 0;

        if (!isValid){
            console.warn("Rooms are not valid", contextData.rooms)
        }

        //Verificamos que haya al menos un adulto en cada habitación
        isValid = isValid && contextData.rooms.every(room => room.persons.adults.quantity > 0);

        if (!isValid){
            console.warn("Adults are not valid", contextData.rooms)
        }

        //Verificamos que haya un lugar para buscar
        isValid = isValid && contextData.label.length > 0;

        if (!isValid){
            console.warn("Label is not valid", contextData.label)
        }

        //Verificamos que si hay niños, todas las edades de éstos deben estar indicadas
        contextData.rooms.map( room => {
            if (room.persons.kids && room.persons.kids.quantity > 0){
                isValid = room.persons.kids.quantity === Object.keys(room.persons.kids.ages).length;
            }
        });

        if (!isValid){
            console.warn("Kids are not valid", contextData.rooms)
        }

        return isValid;
    }

    const contextValue = { ...contextData, updateContext, isValidData, clearContext };

    return (
        <BookingEngineContext.Provider value={contextValue}>
            {children}
        </BookingEngineContext.Provider>
    );
};

// Hook para usar el contexto
export const useBookingEngineContext = () => useContext(BookingEngineContext);
