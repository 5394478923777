

import { useTranslation } from 'react-i18next';
import './BookingDataForm.scss'
import { Button, Checkbox, Col, Form, Input, Radio, Row, Space, Typography } from 'antd';
import { useCart } from '../../../Contexts/CartContexts';
import { CartRoomData } from '@beds2b-group/reusable-components/dist/types';
import { useEffect, useState } from 'react';

interface BookingDataFormProps {}

const BookingDataForm: React.FC<BookingDataFormProps> = () => {
    const { t } = useTranslation();

    const cartContext = useCart();

    const [form] = Form.useForm();

    const onFinish = () => {
    }
    
    return (
        <div className="booking-data-form">
            <Form form={form} layout="vertical" onFinish={onFinish} >
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.owner-data.title')}</Typography.Title>
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <Form.Item 
                                rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                name="ownerName" 
                                label={t('components.booking-data-form.owner-data.name')}
                                className="app-input">
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item 
                                rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                name="ownerSurnames" label={t('components.booking-data-form.owner-data.surnames')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item 
                                rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                name="ownerEmail" label={t('components.booking-data-form.owner-data.email')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item 
                                rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                name="ownerPhone" label={t('components.booking-data-form.owner-data.phone')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item 
                                name="ownerDocument" label={t('components.booking-data-form.owner-data.document')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="commercialMails">
                                <Checkbox className="accept-commercial-emails">{t('components.booking-data-form.owner-data.accept-commercial-emails')}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.booking-data.title')}</Typography.Title>
                    {
                        cartContext.data.rooms.map((room, index) => {
                            return <div key={index}>
                                    <RoomForm room={room} form={form} />
                                </div>
                        })
                    }
                </div>
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.additional-info.title')}</Typography.Title>
                    <Form.Item name="observations" label={t('components.booking-data-form.additional-info.observations')}>
                        <Input.TextArea className="app-input" allowClear rows={6}/>    
                    </Form.Item>
                </div>
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.payment-methods.title')}</Typography.Title>
                    <Col xs={24}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={1}>{t('components.booking-data-form.payment-methods.credit-card')}</Radio>
                                <Radio value={2}>{t('components.booking-data-form.payment-methods.amazon-pay')}</Radio>
                                <Radio value={3}>{t('components.booking-data-form.payment-methods.credit')}</Radio>
                            </Space>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} className="app-actions centered">
                        <Button className="app-button" size='large'>
                            <span className={`sol-icon-ok-circled`} style={{ color: 'white' }} />
                            {t('components.booking-data-form.actions.confirm-booking-and-pay')}
                        </Button>
                    </Col>
                </div>
            </Form>
        </div>
    );
};

interface RoomFormProps {
    room: CartRoomData,
    form: any,
}
const RoomForm = (props : RoomFormProps) => {

    const { room, form } = props;

    const { t } = useTranslation();

    const numAdults = room.occupancy.adults;
    const numChilds = room.occupancy.childs;
    const childAges = room.occupancy.childsAges;

    const copyOfOwner = () => {
        form.setFieldsValue({
            [`room${room.roomIndex}adult${0}name`]: form.getFieldValue("ownerName"),
            [`room${room.roomIndex}adult${0}surname`]: form.getFieldValue("ownerSurnames"),
        });
    }

    const getRowAdult = (index: number) => {
        return <Row gutter={16}>
                    <Col xs={24} className={index === 0 ? 'copy-of-titular' : ''}>
                        <Typography.Title level={4} className='guest-type-title'>{t('components.booking-data-form.booking-data.adult-number',[index+1])}</Typography.Title>
                        {
                            index === 0 ? <Button className="app-button" size='small' onClick={copyOfOwner}>{t('components.booking-data-form.booking-data.copy-of-owner')}</Button> : <></>
                        }
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name={`room${room.roomIndex}adult${index}name`} label={t('components.booking-data-form.booking-data.name')}>
                            <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name={`room${room.roomIndex}adult${index}surname`} label={t('components.booking-data-form.booking-data.surnames')}>
                            <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                </Row>
    }

    const getRowChild = (index: number) => {
        const age = childAges[index];

        return <Row gutter={16}>
                    <Col xs={24}>
                        <Typography.Title level={4} className='guest-type-title'>{t('components.booking-data-form.booking-data.child-number',[index+1])}</Typography.Title>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name={`room${room.roomIndex}childs${index}name`} label={t('components.booking-data-form.booking-data.name')}>
                            <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item name={`room${room.roomIndex}childs${index}surname`} label={t('components.booking-data-form.booking-data.surnames')}>
                            <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item 
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                            initialValue={age} name={`room${room.roomIndex}childs${index}age`} label={t('components.booking-data-form.booking-data.age')}>
                            <Input disabled value={childAges[index]} type="number" className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                </Row>
    }

    const [formData, setFormData]  =useState<Array<JSX.Element>>([])

    useEffect(()=>{
        console.log("All data -> ", numAdults, numChilds, childAges);

        let data = [];
        for(let i=0; i<numAdults; i++){
            data.push(getRowAdult(i));
        }

        for(let i=0; i<numChilds; i++){
            data.push(getRowChild(i));
        }
        setFormData(data);
    },[]);

    return (
        <>
            <Col xs={24}>
                <Typography.Title level={4} className='room-title'>{t('components.booking-data-form.booking-data.header-occupancy', [(room.roomIndex), room.name, numAdults, numChilds])}. {room.price.pvp} {room.price.currency}</Typography.Title>
            </Col>
            {formData}
        </>
    )
}

export default BookingDataForm;