import { registerModal, useModal } from '@gluedigital/modal'
import { RoomModal } from '@beds2b-group/reusable-components'

export default function RoomModalWrapper(room: any) {
  const modal = useModal()

  return (
    <RoomModal handleClose={() => modal.hide()} room={{
      code: '1',
      beds: 2,
      images: [
        'https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//002_slider-vip.jpg',
        'https://www.claridges.co.uk/siteassets/rooms/2023-claridges-rooms-and-suites-balcony-room-1920_720.jpg',
        'https://assets-global.website-files.com/5c6d6c45eaa55f57c6367749/65045f093c166fdddb4a94a5_x-65045f0266217.webp',
      ],
      name: 'Habitación Doble con vistas',
      price: {
        currency: 'EUR',
        pvp: 100,
      },
      remainingRooms: 55,
      services: [
        { icon: 'wifi', name: 'Escritorio' },
        { icon: 'taxi', name: 'Escritorio' },
        { icon: 'wifi', name: 'Minibar de pago' },
        { icon: 'taxi', name: 'Minibar de pago' },
        { icon: 'wifi', name: 'Minibar de pago' },
        { icon: 'wifi', name: 'Terraza' },
        { icon: 'taxi', name: 'Terraza' },
        { icon: 'diamond', name: 'Escritorio' },
        { icon: 'taxi', name: 'Minibar de pago' },
      ],
      description:
        'Habitaciones perfectamente amuebladas y equipadas con dos camas de 1.35x2, telefono, caja fuerte de alquiler, TC, satelite, calefaccion/aire acondicionado, cuarto de baño completo con secador de pelo, y terraza',
      shortDescription: '2 camas de matrimonio 180 cm',
    }}/>
  )
}

registerModal('room-modal', RoomModalWrapper)
