import { Button, Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { GetArrowSelectIcon } from "../../../utils/icons";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { Currency } from "../../../models/Currency";
import { useEffect, useState } from "react";
import { SmallCorporateData } from "../../../models/SmallCorporateData";
import GoogleMapsAutoCompleteComponent from "../../google/geocode/GoogleMapsAutoCompleteComponent";

import './CorporateFormComponent.scss';
import { GooglePlaceData } from "../../../models/GooglePlaceData";

interface CorporateFormComponentProps{
    onPreviousStep: (step?: number) => void,
    onNextStep: (step?:number) => void,
    onFillCorprateData: (corprateData: SmallCorporateData) => void
}

const CorporateFormComponent = (props: CorporateFormComponentProps) : JSX.Element => {
    const { t } = useTranslation();
    const appConfig = useAppConfig();
    const [form] = useForm();

    const {onPreviousStep, onNextStep, onFillCorprateData} = props;

    const [googleData, setGoogleData] = useState<GooglePlaceData>();

    const resetGoogleDataValue = () : void => {
        setGoogleData(undefined);
    }

    const onSetGoogleData = (placeData: any) => {
        if (placeData){
            setGoogleData({
                address_components: placeData.address_components,
                formatted_address: placeData.formatted_address,
                name: placeData.name,
                place_id: placeData.place_id,
                geometry:{
                    lat: placeData.geometry.location.lat(),
                    lng: placeData.geometry.location.lng()
                }
            } as GooglePlaceData);
        }
    }

    const [enabledButton, setEnabledButton] = useState<boolean>(false);
    const verifyFields = () : void => {

        const hasErrors = form.getFieldsError().some((field) => {
            const fieldName: string = field.name[0].toString();
            let hasValue: boolean = form.getFieldValue(fieldName) !== undefined && form.getFieldValue(fieldName)?.length > 0;
            let hasError: boolean = field.errors.length > 0 || !hasValue;

            if (field.name[0].toString() === "address"){
                hasError = !googleData || googleData.formatted_address.length === 0;
            }

            return hasError;
        });

        setEnabledButton(!hasErrors);

        if (!hasErrors){
            onFillCorprateData({
                address: googleData!,
                commercialName: form.getFieldValue("commercialName"),
                currencyID: form.getFieldValue("currencyId"),
            })
        }
    }

    useEffect(()=>{
        verifyFields();
    }, [googleData])

    return(
        <div className="corporate-selector-component">
            <Form form={form} layout="vertical" onFieldsChange={verifyFields}>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.corporate-form.commercial-name")}
                            name="commercialName"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" type="email"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.corporate-form.currency")}
                            name="currencyId"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}>
                            <Select className='currency-selector' suffixIcon={GetArrowSelectIcon()}>
                                {
                                    appConfig.config.availableCurrencies.map((c: Currency) => {
                                        return <Select.Option disabled={!c.available} key={c.id}>{c.description} ({c.iso})</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.corporate-form.address")}
                            name="address"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue('address') && value === undefined) {
                                            return Promise.reject(t("forms.validations.required-field"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <GoogleMapsAutoCompleteComponent onClearInput={resetGoogleDataValue} isRequiredField={false} onSetData={onSetGoogleData} showSelectedInfo={false}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="app-actions">
                    <Button onClick={()=>{onPreviousStep();}} className="app-white-button">
                        {t('components.corporate-form.actions.prev')}
                    </Button>

                    <Button onClick={()=>{onNextStep();}} className="app-button" disabled={!enabledButton}>
                        {t('components.corporate-form.actions.next')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default CorporateFormComponent;