

import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import './ContactFormComponent.scss';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GetFormattedUrl } from '../../../utils/urls';
import { useEffect, useState } from 'react';
import { ContactFormInfo } from '../../../models/ContactFormInfo';
import { LoadingOutlined } from '@ant-design/icons';

interface ContactFormComponentProps{
    onFinish: (values: ContactFormInfo) => void,
    sending: boolean,
    defaultValues?: {name:string, email: string},
    sendStatus: 'ok' | 'ko' | ''
}

const ContactFormComponent = (props: ContactFormComponentProps) : JSX.Element => {
    const { t } = useTranslation();
    
    const [form] = useForm();

    const [isValidData, setIsValidData] = useState<boolean>(false);

    const onFinishForm = () : void => {
        form.validateFields().then((values)=>{
            props.onFinish(values as ContactFormInfo);
        })
    }

    const [checked, isChecked] = useState<boolean>(false);

    const onCheckChange = (e: any) : void => {
        isChecked(e.target.checked);
    }

    const onChange = (e: any) : void => {
        validateForm();
    }

    const validateForm = () : void => {
        var name: string = form.getFieldValue('name');
        var email: string = form.getFieldValue('email');
        var subject: string = form.getFieldValue('subject');
        var message: string = form.getFieldValue('message');

        setIsValidData(checked && name?.length > 0 && email?.length > 0 && subject?.length > 0 && message?.length > 0 && form.getFieldError('email')?.length == 0);
    } 

    useEffect(()=>{
        validateForm();
    },[checked])

    useEffect(()=>{
        if (props.defaultValues){
            form.setFieldsValue(props.defaultValues);
        }
    },[props.defaultValues])

    useEffect(()=>{
        if (props.sendStatus === 'ok'){
            form.resetFields();
        }

        if (props.defaultValues){
            form.setFieldsValue(props.defaultValues);
        }
    },[props.sendStatus])

    return(
        <div className="contact-form-component">
            <Form form={form} layout='vertical' onFinish={onFinishForm} onChange={onChange} onBlur={onChange}>
                <Row gutter={16}>
                    <Col xs={24} lg={12}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item
                                    label={t("components.contact-form.name")}
                                    name="name"
                                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input 
                                        disabled={(props?.defaultValues?.name ? props?.defaultValues?.name?.length > 0 : false)}
                                        allowClear className="app-input"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label={t("components.contact-form.email")}
                                    
                                    name="email"
                                    rules={[
                                        { required: true, message: t("forms.validations.required-field")! },
                                        { type: "email", message: t("forms.validations.invalid-format")!}
                                    ]}
                                >
                                    <Input 
                                        disabled={(props?.defaultValues?.email ? props?.defaultValues?.email?.length > 0 : false)}
                                        onCopy={(e)=>{
                                        e.preventDefault();
                                    }} onPaste={(e)=>{
                                        e.preventDefault();
                                    }} className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label={t("components.contact-form.subject")}
                                    name="subject"
                                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input allowClear className="app-input"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("components.contact-form.message")}
                            name="message"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input.TextArea className='app-input' rows={9}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Checkbox className="app-checkbox" onChange={onCheckChange}>
                    <Link className='app-link' target='_blank' to={`${GetFormattedUrl('termns-and-conditions')}`}>{t('components.contact-form.legal-texts')}</Link>
                </Checkbox>
                <div className="actions">
                    <Button className="app-button send-form-data" disabled={!isValidData || props.sending} htmlType='submit'>
                        {props.sending ? <LoadingOutlined /> : ''} {t('components.contact-form.send-data')}
                    </Button>
                </div>
            </Form>
            
        </div>
    )
}

export default ContactFormComponent;