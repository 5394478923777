import React, { useEffect, useState } from 'react'

import './PublicFooterComponent.scss'
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetDefaultLanguage, GetLanguageInUrl, IsLaguagePresentInUrl } from '../../../utils/urls';
import moment from 'moment';

interface PublicFooterProps{}

const PublicFooterComponent = (props: PublicFooterProps) : JSX.Element => {
    const { t } = useTranslation();
    const [languageUrl, setlanguageUrl] = useState<string>();
    
    const appConfigContext = useAppConfig();

    useEffect(()=>{
        var defaultLanguage = GetDefaultLanguage();

        if (IsLaguagePresentInUrl()){
            setlanguageUrl(GetLanguageInUrl());
        }else{
            setlanguageUrl(defaultLanguage);
        }
    },[])

    return(
        <div className={`public-footer ${appConfigContext.device}`}>
            <nav className="menu">
                <Link className='menu-link-element' to={`${languageUrl}/legal-warning`}>{t('components.public-footer.legal-warning')}</Link>
                <Link className='menu-link-element' to={`${languageUrl}/privacity`}>{t('components.public-footer.privacity')}</Link>
                <span className='menu-link-element'>{appConfigContext.config.siteName}<sup>®</sup> {moment().year()}</span>
                <Link className='menu-link-element' to={`${languageUrl}/cookies`}>{t('components.public-footer.cookies')}</Link>
                <Link className='menu-link-element' to={`${languageUrl}/termns-and-conditions`}>{t('components.public-footer.termns-and-conditions')}</Link>
            </nav>
           
        </div>
    )
}

export default PublicFooterComponent;