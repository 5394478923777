import { TemplateClientInfo } from "../models/TemplateClientInfo";

export var templateInfo: TemplateClientInfo = {
    "isDefaultTemplate": true,
    "defaultTemplateName": "template1",
    "template": [
      {
        "order": 1,
        "html": "<div class='header'><h1>Mi Landing Page</h1></div>"
      },
      
      {
        "order": 3,
        "html": "<div class='container'><p>Bienvenido a nuestra página de destino.</p></div>"
      },
      {
        "order": 4,
        "html": "<div class='grid'><div class='item'>Item 1</div><div class='item'>Item 2</div><div class='item'>Item 3</div></div>"
      },
      {
        "order": 5,
        "html": "<div class='the-login-container'><h3>Iniciar sesión</h3><div class='login-container-2' id='login-container-2'>[COMPONENT_LOGIN]</div></div>"
      },
      {
        "order": 6,
        "html": "<div class='slider'><p>Slider aquí</p></div>"
      },
      {
        "order": 7,
        "html": "<div class='map'><p>Mapa aquí</p></div>"
      },
      {
        "order": 8,
        "html": "<div class='features'><h2>Características</h2><ul><li>Característica 1</li><li>Característica 2</li><li>Característica 3</li></ul></div>"
      },
      {
        "order": 9,
        "html": "<div class='cta'><button id='btnCta'>¡Regístrate ahora!</button></div>"
      }
    ],
    "css": `
      .header {
        text-align: center;
        background-color: #007bff;
        color: white;
        padding: 20px;
      }

      .container {
        text-align: center;
        padding: 20px;
      }
  
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        text-align: center;
        padding: 20px;
      }
  
      .item {
        background-color: #f0f0f0;
        padding: 20px;
      }

      .the-login-container{
        text-align: center;
        width: 100%;
      }

      .login-container-2{
        margin-top: 10px;
        max-width: 50%;
        margin-left: 25%;
      }
  
      .login-form {
        text-align: center;
        padding: 20px;
        border: 1px solid #ccc;
        background-color: #f9f9f9;
      }
  
      form input {
        margin: 10px;
        padding: 10px;
      }
  
      .slider {
        text-align: center;
        padding: 20px;
      }
  
      .map {
        text-align: center;
        padding: 20px;
      }
  
      .features {
        text-align: center;
        padding: 20px;
      }
  
      ul {
        list-style: none;
        padding: 0;
      }
  
      .cta {
        text-align: center;
        padding: 20px;
      }
  
      button {
        background-color: #ff6600;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
      }
    `,
    "javascript": `
      document.getElementById('btnCta').addEventListener('click', function() {
        alert('¡Gracias por registrarte!');
      });
    `
  }