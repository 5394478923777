
import { Button, Col, Form, Input, Row } from 'antd';
import UsersInfo from '../../../models/UsersInfo';
import './UsersEditComponent.scss'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface UsersEditComponentProps {
    editingUser: UsersInfo | null;
    onSaveChanges: (userInfo: UsersInfo) => void;
}

const UsersEditComponent = (props: UsersEditComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const { editingUser, onSaveChanges } = props;

    const saveChanges = () : void => {
        onSaveChanges(form.getFieldsValue());
    }

    useEffect(()=>{
        if (editingUser){
            form.setFieldsValue(editingUser);
        }
    },[editingUser])


    return(
        <div className="users-edit">
            <Form form={form} layout='vertical'>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-edit.name")}
                            name="name"
                            initialValue={editingUser?.name}
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-edit.surname")}
                            name="surname"
                            initialValue={editingUser?.surname}
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-edit.second-surname")}
                            name="secondSurname"
                            initialValue={editingUser?.secondSurname}
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-edit.email")}
                            name="email"
                            initialValue={editingUser?.email}
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                        label={t("components.users-edit.phone")}
                        name="phone"
                        initialValue={editingUser?.phone}
                        >
                            <Input  className='app-input'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} className="form-actions">
                        <Button htmlType='submit' className='app-button' onClick={saveChanges}>
                            {t("components.users-edit.update")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default UsersEditComponent;