

import { Col, Progress, Row, Tooltip } from 'antd';
import './StatisticComponent.scss'

import ReactECharts from 'echarts-for-react';
import echarts from 'echarts'; // Deja esta importación

import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/title';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/axisPointer';
import { useAppConfig } from '../../Contexts/AppConfigContext';
import { hexToRgb } from '../../utils/utils';
//import 'echarts/lib/extension/dataTool';


interface StatisticComponentProps {
    header: string,
    symbol?: string
    title: string,
    subtitle: string,
    progress: number,
    data: number[]
}

//https://ant-design-charts.antgroup.com/en/examples
const StatisticComponent = (props: StatisticComponentProps) : JSX.Element => {

    const appConfig = useAppConfig();

    const { header, title, subtitle, progress, data, symbol } = props;

    const option = {
        width: '95%',  // Establece el ancho en píxeles
        height: '115px', // Establece la altura en píxeles
        xAxis: {
          type: 'category',
          boundaryGap: false,
          show: false,
          data: [
                    '',
                    'Enero', 
                    'Febrero', 
                    'Marzo', 
                    'Abril', 
                    'Mayo', 
                    'Junio', 
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre',
                    ''],
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            name: '',
            type: 'line',
            stack: 'stack',
            smooth: true,
            lineStyle: {
                width: 2,
                color: appConfig.config.mainColor
              },
            showSymbol: false,
            data: [0, ...data, 0],
            areaStyle: {
                color: `rgba(${hexToRgb(appConfig.config.mainColor)}, 0.5)`,
            } 
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: 0,   // Margen izquierdo
          right: 0,  // Margen derecho
          top: 5,    // Margen superior
          bottom: 0, // Margen inferior
          containLabel: true,
        },
      }; 

    return(
        <div className="statistic-component">
            <Row gutter={0}>
                <Col xs={24} className="header">
                    {header}
                </Col>
                <Col xs={24} className="content">
                    <Row gutter={0}>
                        <Col xs={24} md={12} className="resume">
                            <span className="amount">
                                {
                                    //Amount es la suma de todos los valores del array data
                                    data.reduce((a, b) => a + b, 0)
                                }
                                {
                                    symbol ?? ''
                                }
                            </span>
                            <span className="title">
                                {title}
                            </span>
                            <span className='subtitle'>
                                {subtitle}
                            </span>
                            <span className='progress-bar'>
                                <Tooltip title={`${progress}%`}>
                                    <Progress percent={progress} showInfo={false} strokeColor={appConfig.config.mainColor}/>
                                </Tooltip>
                            </span>
                        </Col>
                        <Col xs={24} md={12} className="chart">
                            <ReactECharts option={option} style={{width: "100%", height: "100%"}}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default StatisticComponent;