  // Enums
  
  /**
   * d => D Habitación/día UNID * DIA * PRECIO
   * p => P Pax/día PAX * DÍA * PRECIO
   * u => U Paquete/habitación UNID * PRECIO
   * x => X Paquete/pax PAX * PRECIO
   * */
  export enum ApplicationPriceTypeEnum {
    d = 'D',
    p = 'P',
    u = 'U',
    x = 'X',
  }
  
  export enum PaxTypeEnum {
    adult = 'adult',
    teenager = 'teenager',
    child = 'child',
    infant = 'infant',
  }
  
  export enum OfferSupplementTypeEnum {
    offer = 'offer',
    supplement = 'supplement',
  }
  
  export enum PaymentTypeEnum {
    fixed = 'fixed',
    percent = 'percent',
  }

  // Interfaces for other tables
  
 export interface BookingPax {
    id: number;
    paxType: PaxTypeEnum;
    age: number;
    name: string;
    surname: string;
    secondSurname?: string;
  }
  
  export default interface BookingData {
    id: number;
    isCancelled: boolean;
    cancelledDate?: Date;
    clientId: number;
    clientCode: string;
    bookingStatusCode: string;
    systemLocator: string;
    clientLocator: string;
    customerLocator?: string;
    recordDate: Date;
    lastUpdateDate: Date;
    observations: string;
    rateKey: string;
    currencyCode: string;
    taxesIncluded: boolean;
    ownerData: OwnerData; // Reference to OwnerData
    hotelInfo: HotelInfo; // Reference to HotelInfo
    bookingLines: BookingLine[]; // Collection of BookingLine
    bookingPricing: BookingPricing; // Reference to BookingPricing
    extras: Extra[]; // Collection of Extra
    offersAndSupplements: OffersAndSupplement[]; // Collection of OffersAndSupplement
    taxes: Tax[]; // Collection of Tax
    cancellationPolicies: CancellationPolicy[]; // Collection of CancellationPolicy
  }

 export interface CancellationPolicy{
    from: Date | null;
    to: Date | null;
    penalty: number;
    penaltyType: PaymentTypeEnum;
  }

 export interface BookingLine {
    id: number;
    roomImage: string;
    roomName: string;
    roomTypeId: number;
    roomTypeCode: string;
    regimeName: string;
    regimeTypeId: number;
    regimeTypeCode: string;
    checkIn: Date;
    checkOut: Date;
    totalAmount: number;
    totalCommissionAmount: number;
    totalAmountBeforeTaxes: number;
    totalCommissionBeforeTaxes: number;
    roomKey: string;
    paxes: BookingPax[]; // Collection of BookingPax
    bookingLineSummary: BookingLineSummary[]; // Collection of BookingLineSummary
    taxes: Tax[]; // Collection of Tax
  }
  
 export interface BookingPricing {
    id: number;
    currencyCode: string;
    pvp: number;
    net: number;
    commission: number;
    exchangeValue: number;
    exchangeValueDate: Date;
    pvpExchange: number;
    netExchange: number;
    commissionExchange: number;
    amountType: ApplicationPriceTypeEnum;
  }
  
 export interface BookingLineSummary {
    id: number;
    from: Date;
    to: Date;
    applicationPriceType: ApplicationPriceTypeEnum;
    applicationRegimePriceType: ApplicationPriceTypeEnum;
    price: number;
    priceBeforeTaxes: number;
    agencyCommission: number;
    agencyCommissionBeforeTaxes: number;
    regimePrice: number;
    regimePriceBeforeTaxes: number;
  }
  
 export interface Extra {
    id: number;
    extraId: number;
    extraCode: string;
    description: string;
    applicationDateFrom: Date;
    applicationDateTo: Date;
    quantity: number;
    price: number;
    priceBeforeTax: number;
    applicationPriceType: ApplicationPriceTypeEnum;
    image: string;
  }
  
 export interface OwnerData {
    id: number;
    name: string;
    surname: string;
    secondSurname?: string;
    phone?: string;
    email: string;
  }
  
 export interface OffersAndSupplement {
    id: number;
    offerAndSupplementId: number;
    offerAndSupplemetCode: string;
    type: OfferSupplementTypeEnum;
    description: string;
    applyFrom: Date;
    applyTo: Date;
    applyOrder: number;
    amount: number;
    amountBeforeTax: number;
  }
  
 export interface Tax {
    id: number;
    percent: number;
  }
  
 export interface HotelInfo{
    id: number;
    code: string;
    name: string;
    address: string;
    phone: string;
    latitude: number,
    longitude: number,
    mainImage: string,
    website: string,
    director: string
  }

  export const bookingDataExample: BookingData = {
    id: 1,
    clientId: 1,
    isCancelled: false,
    cancelledDate: new Date(),
    clientCode: "C123",
    bookingStatusCode: "Confirmed",
    systemLocator: "S123",
    clientLocator: "CL123",
    customerLocator: "CU123",
    recordDate: new Date(),
    lastUpdateDate: new Date(),
    observations: "Special requests",
    rateKey: "R123",
    currencyCode: "USD",
    taxesIncluded: true,
    cancellationPolicies: [
        {
            //Esto quiere decir que si cancela antes del 2023-10-20 23:59:59 no hay penalización
            from: null,
            to: new Date("2023-10-18 23:59:59"),
            penalty: 0,
            penaltyType: PaymentTypeEnum.fixed
        },
        {
            //Esto quiere decir que si cancela entre el 20/10/2023 y el 21/10/2023 hay una penalización del 50%
            from: new Date("2023-10-19 00:00:00"),
            to: new Date("2023-10-21 23:59:59"),
            penalty: 50,
            penaltyType: PaymentTypeEnum.percent
        },
        {
            //Esto quiere decir que si cancela entre el 22/10/2023 y el 23/10/2023 hay una penalización del 75%
            from: new Date("2023-10-22 00:00:00"),
            to: new Date("2023-10-23 23:59:59"),
            penalty: 75,
            penaltyType: PaymentTypeEnum.percent
        },
        {
            //Esto quiere decir que si cancela a partir del 24/10/2023 hay una penalización del 100%
            from: new Date("2023-10-24 00:00:00"),
            to: null,
            penalty: 100,
            penaltyType: PaymentTypeEnum.percent
        }
    ],
    ownerData: {
        id: 1,
        name: "John",
        surname: "Doe",
        email: "johndoe@example.com",
      },
    hotelInfo: {
        id: 1,
        code: "H123",
        name: "Grand Hotel",
        address: "123 Main St, City",
        phone: "+1234567890",
        latitude: 40.416775,
        longitude: -3.703790,
        mainImage: "https://images.pexels.com/photos/356830/pexels-photo-356830.jpeg",
        website: "https://www.beds2brewards.com/hoteles/grand-hotel-example/",
        director: "Journey Doe"
      },
    bookingPricing: {
        id: 1,
        currencyCode: "USD",
        pvp: 1500,
        net: 1350,
        commission: 150,
        exchangeValue: 1.0,
        exchangeValueDate: new Date(),
        pvpExchange: 1500,
        netExchange: 1350,
        commissionExchange: 150,
        amountType: ApplicationPriceTypeEnum.d,
      },
    bookingLines: [
      {
        id: 1,
        roomImage: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/zimbali-fotos/ZIMBALI_GALERIA025.jpg",
        roomName: "Habitación doble",
        roomTypeId: 101,
        roomTypeCode: "R101",
        regimeName: "Alojamiento y desayuno",
        regimeTypeId: 201,
        regimeTypeCode: "RG201",
        checkIn: new Date("2023-10-20"),
        checkOut: new Date("2023-10-25"),
        totalAmount: 500,
        totalCommissionAmount: 50,
        totalAmountBeforeTaxes: 450,
        totalCommissionBeforeTaxes: 45,
        roomKey: "Room key for Room 101",
        paxes: [
          {
            id: 1,
            paxType: PaxTypeEnum.adult,
            age: 30,
            name: "Guest 1",
            surname: "Doe",
          },
          {
            id: 2,
            paxType: PaxTypeEnum.adult,
            age: 28,
            name: "Guest 2",
            surname: "Doe",
          },
          {
            id: 3,
            paxType: PaxTypeEnum.child,
            age: 8,
            name: "Child 1",
            surname: "Doe",
          },
        ],
        bookingLineSummary: [
            {
              id: 1,
              from: new Date("2023-10-20"),
              to: new Date("2023-10-21"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 2,
              from: new Date("2023-10-21"),
              to: new Date("2023-10-22"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 3,
              from: new Date("2023-10-22"),
              to: new Date("2023-10-23"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 4,
              from: new Date("2023-10-23"),
              to: new Date("2023-10-24"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 5,
              from: new Date("2023-10-24"),
              to: new Date("2023-10-25"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            }
          ]
          ,
        taxes: [
          {
            id: 1,
            percent: 10,
          },
          {
            id: 2,
            percent: 5,
          },
        ],
      },
      {
        id: 2,
        roomName: "Habitación tripe",
        roomImage: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/zimbali-fotos/ZIMBALI_GALERIA034.jpg",// "https://via.placeholder.com/260x135",
        roomTypeId: 102,
        roomTypeCode: "R102",
        regimeName: "Alojamiento y desayuno",
        regimeTypeId: 202,
        regimeTypeCode: "RG202",
        checkIn: new Date("2023-10-20"),
        checkOut: new Date("2023-10-25"),
        totalAmount: 600,
        totalCommissionAmount: 60,
        totalAmountBeforeTaxes: 540,
        totalCommissionBeforeTaxes: 54,
        roomKey: "Room key for Room 102",
        paxes: [
          {
            id: 4,
            paxType: PaxTypeEnum.adult,
            age: 35,
            name: "Guest 3",
            surname: "Smith",
          },
          {
            id: 5,
            paxType: PaxTypeEnum.child,
            age: 5,
            name: "Guest 4",
            surname: "Smith",
          },
          {
            id: 6,
            paxType: PaxTypeEnum.child,
            age: 7,
            name: "Child 2",
            surname: "Smith",
          },
        ],
        bookingLineSummary: [
            {
              id: 1,
              from: new Date("2023-10-20"),
              to: new Date("2023-10-21"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 2,
              from: new Date("2023-10-21"),
              to: new Date("2023-10-22"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 3,
              from: new Date("2023-10-22"),
              to: new Date("2023-10-23"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 4,
              from: new Date("2023-10-23"),
              to: new Date("2023-10-24"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 5,
              from: new Date("2023-10-24"),
              to: new Date("2023-10-25"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            }
          ]
          ,
        taxes: [
          {
            id: 3,
            percent: 10,
          },
          {
            id: 4,
            percent: 5,
          },
        ],
      },
    ],
    extras: [
      {
        id: 1,
        extraId: 301,
        extraCode: "E301",
        description: "Extra 1 - Breakfast",
        applicationDateFrom: new Date("2023-10-20"),
        applicationDateTo: new Date("2023-10-25"),
        quantity: 1,
        price: 0,
        priceBeforeTax: 0,
        applicationPriceType: ApplicationPriceTypeEnum.u,
        image: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/hotel-zimbali//bar_piscina.jpg", //"https://via.placeholder.com/260x135"
      },
      {
        id: 2,
        extraId: 302,
        extraCode: "E302",
        description: "Extra 2 - Parking",
        applicationDateFrom: new Date("2023-10-20"),
        applicationDateTo: new Date("2023-10-25"),
        quantity: 1,
        price: 20,
        priceBeforeTax: 18,
        applicationPriceType: ApplicationPriceTypeEnum.u,
        image: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/hotel-zimbali//restaurante_buffet.jpg"//"https://via.placeholder.com/260x135"
      },
    ],
    offersAndSupplements: [
      {
        id: 1,
        offerAndSupplementId: 401,
        offerAndSupplemetCode: "O401",
        type: OfferSupplementTypeEnum.offer,
        description: "Offer 1 - Early Booking Discount",
        applyFrom: new Date("2023-09-01"),
        applyTo: new Date("2023-09-30"),
        applyOrder: 1,
        amount: -100,
        amountBeforeTax: -90,
      },
      {
        id: 2,
        offerAndSupplementId: 402,
        offerAndSupplemetCode: "O402",
        type: OfferSupplementTypeEnum.supplement,
        description: "Supplement 1 - Sea View",
        applyFrom: new Date("2023-10-01"),
        applyTo: new Date("2023-12-31"),
        applyOrder: 2,
        amount: 50,
        amountBeforeTax: 45,
      },
    ],
    taxes: [
      {
        id: 5,
        percent: 10, // IVA
      },
      {
        id: 6,
        percent: 5, // Service Charge
      },
    ],
  };
  
  