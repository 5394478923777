import { createContext, useContext, ReactNode, useState } from "react";

interface GoogleMapsContextData {
  apiKey: string;
  mapApiJs: string;
  initMapScript: (googleMapsApiKey: string) => Promise<HTMLScriptElement|void>;
  loadAsyncScript: (src: string) => Promise<HTMLScriptElement>;
  setApiKey: (apiKey: string) => void; // Nuevo método para establecer la API key
}

const emptyContext = {
  apiKey: "",
  mapApiJs: "https://maps.googleapis.com/maps/api/js",
  initMapScript: () => Promise.resolve(new HTMLScriptElement()),
  loadAsyncScript: (src: string) => Promise.resolve(new HTMLScriptElement()),
  setApiKey: (apiKey: string) => {}
};

const GoogleMapsContext = createContext<GoogleMapsContextData>(emptyContext);

interface GoogleMapsProviderProps {
  children: ReactNode;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({ children }) => {
  const [apiKey, setApiKey] = useState<string>(emptyContext.apiKey);

  const initMapScript = (googleMapsApiKey: string) : Promise<HTMLScriptElement|void> => {
    if (!googleMapsApiKey && !apiKey) {
      return Promise.reject('Google Maps API key is required');
    }

    if (googleMapsApiKey) {
      setApiKey(googleMapsApiKey);
    }

    if ((window as any).google) {
      return Promise.resolve();
    }

    return loadAsyncScript(`${emptyContext.mapApiJs}?key=${googleMapsApiKey}&libraries=places`);
  };

  const loadAsyncScript = (src: string) : Promise<HTMLScriptElement> => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = src;
    
        script.addEventListener('load', () => {
          resolve(script);
        });
    
        document.head.appendChild(script);
      });
  };

  const contextValue: GoogleMapsContextData = {
    ...emptyContext,
    apiKey,
    setApiKey,
    initMapScript,
    loadAsyncScript,
  };

  return <GoogleMapsContext.Provider value={contextValue}>{children}</GoogleMapsContext.Provider>;
};

export const useGoogleMapsContext = () => useContext(GoogleMapsContext);
