import { Button, Col, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import BookingData, { BookingLine, BookingPax, CancellationPolicy, Extra, PaxTypeEnum, PaymentTypeEnum } from '../../../models/BookingData';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';


import './BookingDetailPage.scss'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GetShowMoreIcon } from '../../../utils/icons';
import { CancellationPolicies, Occupation } from '../../../components';
import { cancellBooking, sendEmailToCustomer } from '../../../api/repositoryEP';
import { CloseCircleOutlined, InfoCircleOutlined, LoadingOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useNavigate, useNavigation } from 'react-router-dom';
import { GetFormattedUrl } from '../../../utils/urls';

interface BookingDetailPageProps {
    bookingData?: BookingData;
}
const BookingDetailPage = (props: BookingDetailPageProps) : JSX.Element => {

    const appConfig = useAppConfig();

    const {bookingData} = props;

    const { t } = useTranslation();

    
    const [visible, setVisible] = useState<boolean>(false);

    const toogleVisibility = () => {
        setVisible(!visible);
    }

    useEffect(()=>{
        console.log("Booking data", bookingData);
    }, [bookingData])


    const center = {
        lat: bookingData?.hotelInfo.latitude ?? 0,
        lng: bookingData?.hotelInfo.longitude ?? 0
    };

    const containerStyle = {
        width: '100%',
        height: '206.359px'
    };

    const { isLoaded } = useJsApiLoader({
                            id: 'google-map-script',
                            googleMapsApiKey: "AIzaSyC34CpfBPB0eXXmONTzlreC2nt7atGRir0"
                        })
    
    const [map, setMap] = useState<google.maps.Map | null>(null)
    
    const onLoad = useCallback(function callback(map: google.maps.Map) {

        map.setZoom(14);

        const markerPosition = { lat: bookingData?.hotelInfo.latitude ?? 0, lng: bookingData?.hotelInfo.longitude ?? 0 };

        const marker = new google.maps.Marker({
            position: markerPosition,
            map: map,
            title: bookingData?.hotelInfo.name, 
          });

        setMap(map)
    }, [])
    
    const onUnmount = useCallback(function callback(map: any) {
                        setMap(null)
                        }, [])

    const getPenalty = (cancellationPolicies: CancellationPolicy[] | undefined) : string => {
        var penaltyAmount = `0 ${bookingData?.bookingPricing.currencyCode}`;
        if (bookingData && cancellationPolicies){
            var appliedCancellationPolicies = cancellationPolicies.filter((cancellationPolicy) => {
                return cancellationPolicy.penalty > 0 && moment(cancellationPolicy.from).isBefore(moment()) && moment(cancellationPolicy.to).isAfter(moment());
            });

            if (appliedCancellationPolicies.length > 0){
                var penalty = appliedCancellationPolicies.reduce((acc, cancellationPolicy) => {
                    if (cancellationPolicy.penaltyType === PaymentTypeEnum.percent){
                        return acc + bookingData.bookingPricing.pvp * cancellationPolicy.penalty / 100;
                    }else{
                        return acc + cancellationPolicy.penalty;
                    }
                }, 0);
                penaltyAmount = `${penalty.toFixed(2)} ${bookingData?.bookingPricing.currencyCode}`;
            }

        }

        return penaltyAmount;
    }
    
    const [cancelligData, setCancelligData] = useState<boolean>(false);
    const cancelReservation = () : void => {
        //Si la reserva tiene políticas de cancelación que apliquen se muestra un mensaje de alerta

        if (bookingData){
            setCancelligData(true);
            cancellBooking(bookingData.id).then((response) => {
                bookingData.isCancelled = true;
                bookingData.cancelledDate = new Date();
            }).finally(() => {
                setCancelligData(false);
            });

        }
    }

    const [isSendingCopyByEmail, setIsSendingCopyByEmail] = useState<boolean>(false);
    const [isSendingDisabled, setIsSendingDisabled] = useState<boolean>(false);
    const [leftTimeToEnableSendButton, setLeftTimeToEnableSendButton] = useState<number>(0);
    const sendCopyByEmail = () : void => {
        if (bookingData){
            setIsSendingCopyByEmail(true);
            sendEmailToCustomer(bookingData.id).finally(() => {
                setIsSendingCopyByEmail(false);

                //Una vez enviado el correo deshabilitamos el botón durante 30 segundos
                setIsSendingDisabled(true);
                setTimeout(() => {
                    setIsSendingDisabled(false);
                }, 30000);

                //Cada segundo actualizamos el tiempo que queda para habilitar el botón
                setLeftTimeToEnableSendButton(30);
                const interval = setInterval(() => {
                    setLeftTimeToEnableSendButton((prev) => prev - 1);
                }, 1000);

            });
        }
    }

    const navigate = useNavigate();
    const goCustomerVoucher = () : void => {
        navigate(GetFormattedUrl(`private/customer-voucher/${bookingData?.customerLocator}`));
    }

    /**Añadimos un manejador para que cuando se pinche sobre un elemento con la clase resume-link se haga scrollo hasta el elemento con el atributo tag que contenga el nombre del id que tiene resume-link */
    useEffect(() => {
        const resumeLinks = document.querySelectorAll(".resume-link");
      
        resumeLinks.forEach((resumeLink: any) => {
            const handleClick = (event: MouseEvent) => {
            event.preventDefault();
            const tag = (event.currentTarget as HTMLAnchorElement)?.getAttribute(
              "id"
            );
            const element = document.querySelector(`[data-tag='${tag}']`);
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }
          };
      
          resumeLink.addEventListener("click", handleClick);
      
          return () => {
            resumeLink.removeEventListener("click", handleClick);
          };
        });
      }, []);

    return (
        <div className="booking-detail-page ">
            <div className="print-page">
                <div className="resume print-block">
                        <Row gutter={0} >
                            <Col xs={24} lg={16} className="background-image-resume" style={{backgroundImage: `url(${bookingData?.hotelInfo.mainImage})`}}>
                            </Col>
                            <Col xs={24} lg={8}>
                                {
                                    isLoaded ?<GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={center}
                                                    onLoad={onLoad}
                                                    options={{
                                                        //https://developers.google.com/maps/documentation/javascript/reference?csw=1#MapOptions
                                                        zoomControl: false, // Deshabilita el control de zoom
                                                        streetViewControl: false, // Deshabilita el control de Street View
                                                        mapTypeControl: false, // Deshabilita el control de tipo de mapa
                                                        scaleControl: false, // Deshabilita el control de escala
                                                        rotateControl: false, // Deshabilita el control de rotación
                                                        fullscreenControl: true, // Habilita el control de pantalla completa
                                                    }}
                                                    onUnmount={onUnmount}
                                                >
                                                { /* Child components, such as markers, info windows, etc. */ }

                                                    <Marker title={bookingData?.hotelInfo.name} position={{ lat: bookingData?.hotelInfo.latitude ?? 0, lng: bookingData?.hotelInfo.longitude ?? 0 }} />
                                                <></>
                                                </GoogleMap> : <>Cargando mapa...</>
                                }
                            </Col>
                            <Col xs={24} lg={14} className="content">
                                <h3 className="main-title">
                                    {
                                        t('pages.booking-detail.main-title')
                                    }
                                </h3>
                                <h3 className="hotel-name">
                                    {bookingData?.hotelInfo.name}
                                </h3>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-address')}: {bookingData?.hotelInfo.address}&nbsp;&nbsp;&nbsp;&nbsp;{t('pages.booking-detail.hotel-phone')}: {bookingData?.hotelInfo.phone}
                                </p>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-website')}: <a target='_blank' className="app-link" href={`${bookingData?.hotelInfo.website}`}>{bookingData?.hotelInfo.website}</a>
                                </p>
                                <p className="hotel-address">
                                    {t('pages.booking-detail.hotel-director')}: {bookingData?.hotelInfo.director}
                                </p>
                                <p className="booking-header-resume">
                                    <span>
                                        <Tag color={bookingData?.isCancelled ? 'red' : 'green'}>
                                            {
                                                bookingData?.isCancelled ? t('pages.booking-detail.booking-status.cancelled') : t('pages.booking-detail.booking-status.active')
                                            }
                                            {
                                                bookingData?.isCancelled ? 
                                                    <Tooltip title={t('pages.booking-detail.booking-status.cancelled-at',[bookingData.cancelledDate ? moment(bookingData.cancelledDate).format(appConfig.config.dateFormat) : 'N/D'])}>
                                                        <InfoCircleOutlined className="icon-cancelled-at"/>
                                                    </Tooltip>
                                                :
                                                    ''
                                            }
                                        
                                        </Tag> {t('pages.booking-detail.locator')}: {bookingData?.customerLocator}
                                    </span>
                                    <span>
                                        {t('pages.booking-detail.check-in')}:&nbsp; 
                                        {
                                            moment(bookingData?.bookingLines
                                                .map((bookingLine) => bookingLine.checkIn)
                                                .sort((a, b) => a.getTime() - b.getTime())[0]
                                                .toString() // Convert Date object to string
                                            ).format(appConfig.config.dateFormat)
                                        }
                                    </span>
                                    <span>{t('pages.booking-detail.check-out')}:&nbsp;{
                                            moment(bookingData?.bookingLines
                                                .map((bookingLine) => bookingLine.checkOut)
                                                .sort((a, b) => b.getTime() - a.getTime())[0]
                                                .toString()).format(appConfig.config.dateFormat) // Convert Date object to string
                                        }</span>
                                    <span>
                                        {
                                            HTMLReactParser(
                                                t('pages.booking-detail.room-occupancy', [
                                                    //Total de habitaciones
                                                    bookingData?.bookingLines.length,
                                                    //Total adultos (aquellos que paxType == adult|teneerger) de todas las habitaciones
                                                    bookingData?.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.paxes.filter((pax) => pax.paxType === PaxTypeEnum.adult || pax.paxType === PaxTypeEnum.teenager).length, 0),
                                                    //Total niños (aquellos que paxType == child|infant) de todas las habitaciones
                                                    bookingData?.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.paxes.filter((pax) => pax.paxType === PaxTypeEnum.child || pax.paxType === PaxTypeEnum.infant).length, 0),
                                                    bookingData?.extras.length
                                                    ])
                                            )
                                        }

                                        {
                                            //Pintamos un tag especial en función del estado de la reserva

                                        }
                                    </span>
                                </p>
                            </Col>
                            <Col xs={24} lg={10} className="content">
                                <div className="owner-data">
                                    <h3 className="main-title">
                                        {
                                            t('pages.booking-detail.owner-data.title')
                                        }
                                    </h3>
                                    <p className="owner-name">
                                        {t('pages.booking-detail.owner-data.name')}: {bookingData?.ownerData.name} {bookingData?.ownerData.surname} {bookingData?.ownerData.secondSurname}  
                                    </p>
                                    <p className="owner-phone">
                                        {t('pages.booking-detail.owner-data.phone')}: {bookingData?.ownerData.phone ?? '-'}  
                                    </p>
                                    <p className="owner-phone">
                                        {t('pages.booking-detail.owner-data.email')}: {bookingData?.ownerData.email ? <a className='app-link' href={`mailto:${bookingData?.ownerData.email}`}>{bookingData?.ownerData.email}</a> : '-'}  
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} className="content">
                                <div className="total-resume">
                                    <Row gutter={0}>
                                        <Col xs={24}>
                                            <div className="agency-more-info" onClick={toogleVisibility}>
                                                {GetShowMoreIcon("plus-icon")} {t('pages.booking-detail.more-agency-info')}
                                            </div>
                                        </Col>
                                        <Col xs={24} lg={12} className={`agency-more-info-content ${visible ? 'visible' : 'hidden'}`}>
                                            <p>{t('pages.booking-detail.total-resume.total-commission')}: {bookingData?.bookingPricing.commission.toFixed(2)} {bookingData?.bookingPricing.currencyCode} { (bookingData?.bookingPricing.commission && bookingData?.bookingPricing.commission > 0) ? ` (${(bookingData?.bookingPricing.commission / bookingData?.bookingPricing.pvp * 100).toFixed(2)}%)` : ''}</p>
                                            <p>{t('pages.booking-detail.total-resume.total-net')}: {bookingData?.bookingPricing.net} {bookingData?.bookingPricing.currencyCode} </p>
                                            <p>{t('pages.booking-detail.total-resume.client-locator')}: <span className="client-locator">{bookingData?.clientLocator}</span></p>
                                            <p>{t('pages.booking-detail.total-resume.system-locator')}: <span className="system-locator">{bookingData?.systemLocator}</span></p>
                                        </Col>
                                        <Col xs={24} className="total-price">
                                            {t('pages.booking-detail.total-resume.total-price')}: {bookingData?.bookingPricing.pvp.toFixed(2)} {bookingData?.bookingPricing.currencyCode}
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} className="content">
                            <div className="btn-actions">
                                    {
                                        bookingData?.isCancelled === false ? 
                                            <Popconfirm 
                                                okText={t('pages.booking-detail.cancel-booking.ok-text')} 
                                                cancelText={t('pages.booking-detail.cancel-booking.cancel-text')} 
                                                title={t('pages.booking-detail.cancel-booking.title')} 
                                                description={HTMLReactParser(t('pages.booking-detail.cancel-booking.description', [bookingData?.customerLocator, t('pages.booking-detail.cancel-booking.have-penalty',[getPenalty(bookingData?.cancellationPolicies)])]))}
                                                onConfirm={cancelReservation}
                                                >
                                                <Button className="app-button danger" disabled={cancelligData}>
                                                    {
                                                        cancelligData ? 
                                                            <LoadingOutlined className="loading-icon"/>
                                                            :
                                                            ''
                                                    }
                                                    <CloseCircleOutlined /> {t('pages.booking-detail.actions.cancel-booking')}
                                                </Button>  
                                            </Popconfirm>
                                        : 
                                            <></>
                                    }
                                    
                                    
                                    <Button className="app-button" onClick={sendCopyByEmail} disabled={isSendingCopyByEmail || isSendingDisabled}>
                                        {
                                            isSendingCopyByEmail ? 
                                                <LoadingOutlined className="loading-icon"/>
                                                :
                                                ''
                                        }
                                        <MailOutlined /> {t('pages.booking-detail.actions.send-copy-to-client')}
                                    </Button>  

                                    <Button className="app-button" onClick={goCustomerVoucher}>
                                        <UserOutlined /> {t('pages.booking-detail.actions.go-customer-voucher')}
                                    </Button>

                                    {
                                        leftTimeToEnableSendButton > 0 ?
                                            <span className="left-time-to-enable-send-button">{t('pages.booking-detail.left-time-to-enable-send-button', [leftTimeToEnableSendButton])}</span>
                                        :
                                            ''
                                    }
                            </div>
                            </Col>
                    </Row>
                </div>
                <div className="rooms print-block" data-tag="rooms">
                    <Row>
                        <Col xs={24} className="content">
                            <h3 className="main-title">
                                {
                                    t('pages.booking-detail.rooms-title')
                                }
                            </h3>

                            {
                                bookingData?.bookingLines.map((bookingLine: BookingLine, index: number) => {
                                    return (
                                        <div className="room">
                                            <Row gutter={0}>
                                                <Tooltip title={bookingLine.roomName}>
                                                    <Col xs={24} lg={4} className="room-image" style={{backgroundImage: `url(${bookingLine.roomImage})`}}>
                                                    </Col>
                                                </Tooltip>
                                                <Col xs={24} lg={15} className="room-details">
                                                    <h4>{HTMLReactParser(t("pages.booking-detail.room-for", [
                                                        index+1,
                                                        bookingLine.roomName,
                                                        bookingLine.paxes.filter((pax) => pax.paxType === PaxTypeEnum.adult).length,
                                                        bookingLine.paxes.filter((pax) => pax.paxType !== PaxTypeEnum.adult).length]))}</h4>
                                                    <p className="regime-name">{bookingLine.regimeName}</p>

                                                    
                                                    <CancellationPolicies cancellationPolicies={bookingData.cancellationPolicies} currencyCode={bookingData.currencyCode}/>

                                                    <Occupation occupation={bookingLine.paxes} checkOut={bookingLine.checkOut} />
                                                    
                                                </Col>
                                                <Col xs={24} lg={5} className="room-price">
                                                    <span>{bookingLine.totalAmount.toFixed(2)} {bookingData.bookingPricing.currencyCode}</span>
                                                </Col>
                                            </Row>
                                            
                                            
                                            
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                <div className="services print-block" data-tag="extras">
                    <Row>
                        <Col xs={24} className="content">
                            <h3 className='main-title'>
                                {t('pages.booking-detail.extra-services.title')}
                            </h3>

                            <div>
                                {
                                    bookingData?.extras.map((extra: Extra, index: number) => {
                                        return (
                                            <div className="service">
                                                <Row gutter={0}>
                                                    <Tooltip title={extra.description}>
                                                        <Col xs={24} lg={4} className="image" style={{backgroundImage: `url(${extra.image})`}}>
                                                        </Col>
                                                    </Tooltip>
                                                    <Col xs={24} lg={15} className="description">
                                                        <h4>{index+1}. {extra.description}</h4>
                                                        <p>{t('pages.booking-detail.extra-services.quantity')}: {extra.quantity} </p>
                                                        <p>{t('pages.booking-detail.extra-services.date')}: {moment(extra.applicationDateFrom).format(appConfig.config.dateFormat)} - {moment(extra.applicationDateTo).format(appConfig.config.dateFormat)}</p>
                                                    </Col>
                                                    <Col xs={24} lg={5} className="service-price">
                                                    <span>{extra.price > 0 ? `${extra.price.toFixed(2)} ${bookingData.bookingPricing.currencyCode}`: t('pages.booking-detail.extra-services.free')} </span>
                                                </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        )
}

export default BookingDetailPage;