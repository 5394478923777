import { Button, Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { Currency } from "../../../models/Currency";
import { useEffect, useState } from "react";
import GoogleMapsAutoCompleteComponent from "../../google/geocode/GoogleMapsAutoCompleteComponent";
import { createOrganization } from "../../../api/repositoryEP";
import { AddAgency, SmallAgencyData } from "../../../models/SmallAgencyData";
import { AddCompany, SmallCompanyData } from "../../../models/SmallCompanyData";
import { LoadingOutlined } from "@ant-design/icons";
import ApiResponse from "../../../models/api/ApiResponse";
import { GooglePlaceData } from "../../../models/GooglePlaceData";


interface AgencyAndCompanyAddFormComponentProps {
    recordType: "agency" | "company",
    onCreatedRecord: (record: AddCompany | AddAgency) => void,
    onCloseModal: () => void
}

const AgencyAndCompanyAddFormComponent = (props: AgencyAndCompanyAddFormComponentProps) : JSX.Element => {
    const { t } = useTranslation();
    const appConfig = useAppConfig();

    const { recordType, onCreatedRecord, onCloseModal} = props;

    const [googleData, setGoogleData] = useState<GooglePlaceData>();
    const [form] = useForm();
    const [availableCurrencies, setAvailableCurrencies] = useState<Currency[]>([]);
    const [isCreatingOrganization, setIsCreatingOrganization] = useState<boolean>(false);
    const [enabledButton, setEnabledButton] = useState<boolean>(false);

    const resetGoogleDataValue = () : void => {
        setGoogleData(undefined);
    }

    const onSetGoogleData = (placeData: any) => {
        if (placeData){
            setGoogleData({
                address_components: placeData.address_components,
                formatted_address: placeData.formatted_address,
                name: placeData.name,
                place_id: placeData.place_id,
                geometry:{
                    lat: placeData.geometry.location.lat(),
                    lng: placeData.geometry.location.lng()
                }
            } as GooglePlaceData);

            form.setFieldValue("address", placeData.formatted_address);
        }else{
            setGoogleData(undefined);
        }
    }

    const onFinish = () : void => {
        let organizationData : AddCompany | AddAgency;

        switch(recordType){
            case 'agency':
                organizationData = {
                    fiscalId: form.getFieldValue("fiscalId"),
                    brandName: form.getFieldValue("brandName"),
                    commercialName: form.getFieldValue("commercialName"),
                    currencyId: form.getFieldValue("currencyId"),
                    googleData: googleData,
                    agentName: form.getFieldValue("agentName"),
                    contactPhone: form.getFieldValue("contactPhone"),
                    contactEmail: form.getFieldValue("contactEmail")
                } as AddAgency;
                break;
            case 'company':
                organizationData = {
                    fiscalId: form.getFieldValue("fiscalId"),
                    brandName: form.getFieldValue("brandName"),
                    commercialName: form.getFieldValue("commercialName"),
                    currencyId: form.getFieldValue("currencyId"),
                    googleData: googleData,
                    agentName: form.getFieldValue("agentName"),
                    contactPhone: form.getFieldValue("contactPhone"),
                    contactEmail: form.getFieldValue("contactEmail")
                } as AddCompany;

                break;
        }

        console.log("Organization data: ", organizationData);

        setIsCreatingOrganization(true);
        createOrganization(recordType, organizationData).then((r: ApiResponse | null)=>{
            if (r?.typeText === "success"){
                form.resetFields();
                onCreatedRecord(organizationData);
            }
        }).finally(()=>{
            setIsCreatingOrganization(false);
        })
    }

    const verifyFields = () : void => {
            const hasErrors = form.getFieldsError().some((field) => {
                const hasValue: boolean = form.getFieldValue(field.name[0].toString()) !== undefined && form.getFieldValue(field.name[0].toString())?.length > 0;
                let hasError: boolean = field.errors.length > 0 || !hasValue;

                if (field.name[0].toString() === "address"){
                    hasError = !googleData || googleData.formatted_address.length === 0;
                }

                return hasError;
            });

            setEnabledButton(!hasErrors);
    }

    useEffect(()=>{
        verifyFields();
    }, [googleData])

    useEffect(()=>{
        if (appConfig){
            setAvailableCurrencies(appConfig.config.availableCurrencies);
        }else{
            setAvailableCurrencies([]);
        }
    },[])

    return(
        <div className="add-record-form">
            <Form form={form} layout="vertical" onFinish={onFinish} onFieldsChange={verifyFields}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <h3><strong>{t(`components.agency-and-company-add-form.your-${recordType}-data`)}</strong></h3>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.fiscal-id")}
                            name="fiscalId"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.brand-name")}
                            name="brandName"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.commercial-name")}
                            name="commercialName"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.currency")}
                            name="currencyId"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select
                                className="app-select"
                            >
                                {
                                    availableCurrencies.map((currency: Currency)=>{
                                        return <Select.Option disabled={!currency.available} key={currency.id} value={currency.id}>
                                                {currency.iso} ({currency.symbol})
                                               </Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.address")}
                            name="address"
                            rules={[
                                { required: true, message: t("forms.validations.required-field")! },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        console.log("getFieldValue('address')", getFieldValue('address'));
                                        if (getFieldValue('address') && value === undefined) {
                                            return Promise.reject(t("forms.validations.required-field"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <GoogleMapsAutoCompleteComponent isRequiredField={false} onSetData={onSetGoogleData} showSelectedInfo={false} onClearInput={resetGoogleDataValue}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <h3><strong>{t("components.agency-and-company-add-form.your-agent-data")}</strong></h3>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.agent-name")}
                            name="agentName"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.contact-phone")}
                            name="contactPhone"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.agency-and-company-add-form.contact-email")}
                            name="contactEmail"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input app-width-100"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} className="app-actions">
                        <Button className="app-white-button" onClick={onCloseModal}>
                            {t("components.agency-and-company-add-form.buttons.cancel")}
                        </Button>
                        <Button className="app-button" htmlType="submit" disabled={!enabledButton}>
                            {isCreatingOrganization ? <LoadingOutlined /> : ''}
                            {t("components.agency-and-company-add-form.buttons.accept")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AgencyAndCompanyAddFormComponent;