import { PaginationLocale } from "antd/es/pagination/Pagination";


export const GetPaginationLocale = (t: any) : PaginationLocale => {
    return {
        items_per_page: t("tables.items-per-page")!,
        page: t("tables.page")!,
        prev_page: t("tables.prev-page")!,
        next_page: t("tables.next-page")!,
        prev_5: t("tables.prev-5")!,
        next_5: t("tables.next-5")!,
        prev_3: t("tables.prev-3")!,
        next_3: t("tables.next-3")!,
    } as PaginationLocale;
}