// Interfaces TypeScript

import { AmountType, PriceModifier, Price, Image, AlternativeDay, Hotel } from "./Commons";

interface HotelAvailabilityResponse {
  Hotels: Hotel[];
  HotelAvailabilities: HotelAvailability[];
  CrossSellingProducts: CrossSellingProduct[];
}

interface HotelAvailability {
  HotelCode: string;
  RoomAvailabilities: RoomAvailability[];
  ErrorCode: string;
}

interface RoomAvailability {
  RoomTypeCode: string;
  Quota: number;
  Rates: Rate[];
  AvailabilityRoomIndex: string;
}


interface Rate {
  Code: string;
  Name: string;
  IsOffer: boolean;
  CancellationPolicies: CancellationPolicy[];
  Meals: Meal[];
  AlternativeDays: AlternativeDay[];
  AdditionalInfo: AdditionalInfo[];
}

interface CancellationPolicy {
  Code: string;
  Penalty: Penalty;
  DeadLine: string | null;
  HasExpired: boolean;
  NonRefundable: boolean;
}

interface Penalty {
  Type: PenaltyType;
  PenaltyTypeText: string;
  Amount: number;
}



interface Meal {
  Code: string;
  IsOffer: boolean;
  PaymentType: PaymentType;
  PaymentTypeText: string;
  PricingInfo: PricingInfo;
  RateKey: string;
  PriceModifier?: PriceModifier;
}



interface PricingInfo {
  PvpWithGuarantee: Price;
  PvpWithoutGuarantee: Price;
  GuaranteePrice: number;
  Commissions: Commission[];
  MixedPayment?: MixedPayment;
}



interface Commission {
  PaymentType: PaymentType;
  CommissionWithGuarantee: Price;
  CommissionWithoutGuarantee: Price;
  NetWithGuarantee: Price;
  NetWithoutGuarantee: Price;
}

interface MixedPayment {
  Amount: number;
  AmountType: AmountType;
}





interface AdditionalInfo {
  Message: string;
  AlertType: AlertType;
}



interface CrossSellingProduct {
  ProductCode: string;
  ServicePricingType: string;
  RoomCodes: string[];
  HotelCodes: string[];
  Name: string;
  ShortDescription: string;
  LargeDescription: string;
  ImageUrl: Image;
  IsOffer: boolean;
  Price: Price;
  PriceModifier: PriceModifier;
}

export enum PenaltyType {
  Percent,
  Fix,
  Nights,
}

export enum PaymentType {
  Hotel,
  PrePayCard,
  PrePayTransfer,
  PrePayPayPal,
  Credit,
}

export enum AlertType {
  Info,
  Warning,
  Danger,
}

export type{
  HotelAvailabilityResponse,
  HotelAvailability,
  RoomAvailability,
  Rate,
  CancellationPolicy,
  Penalty,
  Meal,
  PricingInfo,
  Commission,
  MixedPayment,
  AdditionalInfo,
  CrossSellingProduct,
}
