

import { useTranslation } from 'react-i18next';
import { GetEmailSentIcon } from '../../../utils/icons';
import './WaigintEmailSignUpComponent.scss';
import { Button } from 'antd';
import { GoHome } from '../../../utils/urls';

interface WaigintEmailSignUpComponentProps{
    noticeType: 'agency' | 'company'
}

const WaigintEmailSignUpComponent = (props: WaigintEmailSignUpComponentProps) :JSX.Element => {
    const { t } = useTranslation();

    const {noticeType} = props;

    return(
        <div className="waiting-email-sign-up">
            {
                GetEmailSentIcon()
            }

            <div className="info">
                <p>
                    <strong>{t("components.waiting-email-sign-up.email-has-sent")}</strong>
                </p>
                <p>
                    {t(`components.waiting-email-sign-up.notice-text-${noticeType}`)}
                </p>
                <Button className="app-button" onClick={GoHome}>
                    {t("components.waiting-email-sign-up.ok-understand")}
                </Button>
            </div>
        </div>
    )
}

export default WaigintEmailSignUpComponent;