import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


import './LoginForm.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { GetFormattedUrl } from "../../utils/urls";

interface LoginFormComponentProps{
    onLogin: (username: string, password: string)=>void,
    doingLogin: boolean,
    onForgetPassword: () => void,
    haveError: boolean
}

const LoginFormComponent = (props: LoginFormComponentProps) : JSX.Element => {
    
    const { t } = useTranslation();
    
    const [form] = useForm();

    const { onLogin, onForgetPassword, haveError, doingLogin } = props;

    const onFinish = () : void => {
        onLogin(form.getFieldValue("username"), form.getFieldValue("password"));
    }

    return(
        <div className="login-form">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    label={t("components.login-form.username")}
                    name="username"
                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                >
                    <Input className="app-input" />
                </Form.Item>

                <Form.Item
                    label={t("components.login-form.password")}
                    name="password"
                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                >
                    <Input.Password className="app-input" />
                </Form.Item>
                <div className="reminder-password-link">
                    <span className="app-colored-main-font app-link" onClick={onForgetPassword}>{t("components.login-form.reminder-password-link")}</span>
                </div>

                {
                    haveError ?
                    <div className="error-message">
                        {t("components.login-form.error-message")}
                    </div>
                    :
                    '' 
                }
            
                <Button size="large" className="app-button btn-submit" htmlType="submit">
                    {doingLogin ? <LoadingOutlined /> : ''}{t("components.login-form.access")}
                </Button>

                

            </Form>
            <div className="not-register">
                <a className="app-colored-main-font app-link" href={`${GetFormattedUrl('register')}`} onClick={()=>{window.location.href=GetFormattedUrl('register')}}>{t("components.login-form.not-register")}</a>
            </div>
        </div>
    )
}

export default LoginFormComponent;