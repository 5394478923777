import { AmountType, Hotel, ModifierType, TaxType } from "../models/availability/Commons";
import { LocationAvailabilityResponse } from "../models/availability/LocationsAvailability";

const theHotels: Hotel[] = [
  {
    Code: "H001",
    Name: "The Grand Hotel",
    Category: "4",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "USD",
    LanguageIsoCode: "en",
    TimeZone: "UTC-05:00",
    ContactData: {
      ContactPerson: "John Doe",
      Email: "john.doe@beds2b.com",
      Phone: {
        Prefix: "+1",
        Number: "123-456-7890",
      },
      WebSite: "http://www.beds2b.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "MAD",
        CityName: "Madrid",
        CountryCode: "ES",
        CountryName: "Spain",
        StreetName: "123 Main St",
        Latitude: 40.7128,
        Longitude: -74.006,
        Altitude: 10,
      },
      Tax: {
        Description: "Sales Tax",
        Amount: 10.5,
        TaxType: 1,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC001",
          Name: "Basic Amenities",
          Description: "Includes basic amenities",
          Icon: "icon-basic",
          Services: [
            {
              Icon: "water-solid",
              Code: "S002",
              Description: "Piscina con toboganes",
              Name: "Piscina",
            },
            {
              Icon: "wifi",
              Code: "S003",
              Description: "Free Wi-Fi",
              Name: "Wi-Fi",
            },
            {
              Icon: "basket",
              Code: "S004",
              Description: "Tiendas en hotel",
              Name: "Supermercados",
            },
            {
              Icon: "umbrella-beach-solid",
              Code: "S005",
              Description: "Sombrillas en piscina",
              Name: "Sombrillas",
            },
            {
              Icon: "luggage-cart-solid",
              Code: "S006",
              Description: "Servicio de botones",
              Name: "Servicio de maletas",
            },
            {
              Icon: "food",
              Code: "S007",
              Description: "Comedor con bufet libre",
              Name: "Bufet libre",
            },
            {
              Icon: "water-solid",
              Code: "S008",
              Description: "Spa con piscina climatizada",
              Name: "Piscina",
            }
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H002",
    Name: "Big Hotel 456",
    Category: "5",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "USD",
    LanguageIsoCode: "en",
    TimeZone: "UTC-05:00",
    ContactData: {
      ContactPerson: "John Doe",
      Email: "john.doe@beds2b.com",
      Phone: {
        Prefix: "+1",
        Number: "123-456-7890",
      },
      WebSite: "http://www.beds2b.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "MAD",
        CityName: "Madrid",
        CountryCode: "ES",
        CountryName: "Spain",
        StreetName: "123 Main St",
        Latitude: 40.7128,
        Longitude: -74.006,
        Altitude: 10,
      },
      Tax: {
        Description: "Sales Tax",
        Amount: 10.5,
        TaxType: 1,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC001",
          Name: "Basic Amenities",
          Description: "Includes basic amenities",
          Icon: "icon-basic",
          Services: [
            {
              Code: "S001",
              Name: "Wi-Fi",
              Description: "Free Wi-Fi",
              Icon: "icon-wifi",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H003",
    Name: "Sunrise Resort",
    Category: "5",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "EUR",
    LanguageIsoCode: "es",
    TimeZone: "UTC+02:00",
    ContactData: {
      ContactPerson: "Maria Rodriguez",
      Email: "maria.rodriguez@sunriseresort.com",
      Phone: {
        Prefix: "+34",
        Number: "987-654-321",
      },
      WebSite: "http://www.sunriseresort.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "BCN",
        CityName: "Barcelona",
        CountryCode: "ES",
        CountryName: "Spain",
        StreetName: "456 Ocean Avenue",
        Latitude: 41.3879,
        Longitude: 2.1699,
        Altitude: 50,
      },
      Tax: {
        Description: "Tourist Tax",
        Amount: 8.75,
        TaxType: TaxType.Vat,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC002",
          Name: "Luxury Amenities",
          Description: "Includes premium amenities",
          Icon: "icon-luxury",
          Services: [
            {
              Code: "S002",
              Name: "Private Butler",
              Description: "24/7 dedicated private butler service",
              Icon: "icon-butler",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H004",
    Name: "Mountain Retreat Lodge",
    Category: "3",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "CAD",
    LanguageIsoCode: "en",
    TimeZone: "UTC-07:00",
    ContactData: {
      ContactPerson: "Robert Smith",
      Email: "robert.smith@mountainlodge.com",
      Phone: {
        Prefix: "+1",
        Number: "555-123-4567",
      },
      WebSite: "http://www.mountainlodge.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "YVR",
        CityName: "Vancouver",
        CountryCode: "CA",
        CountryName: "Canada",
        StreetName: "789 Summit Avenue",
        Latitude: 49.2827,
        Longitude: -123.1207,
        Altitude: 800,
      },
      Tax: {
        Description: "Mountain Fee",
        Amount: 5.25,
        TaxType: 1,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC003",
          Name: "Nature Excursions",
          Description: "Guided excursions into the natural surroundings",
          Icon: "icon-nature",
          Services: [
            {
              Code: "S003",
              Name: "Mountain Trail Hike",
              Description: "Explore scenic mountain trails with a guide",
              Icon: "icon-hike",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H005",
    Name: "Maravilla Resort",
    Category: "5",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "EUR",
    LanguageIsoCode: "es",
    TimeZone: "UTC+02:00",
    ContactData: {
      ContactPerson: "Maria Sanchez",
      Email: "maria.sanchez@maravillaresort.com",
      Phone: {
        Prefix: "+34",
        Number: "987-654-321",
      },
      WebSite: "http://www.maravillaresort.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "RIO",
        CityName: "Río de Janeiro",
        CountryCode: "BR",
        CountryName: "Brazil",
        StreetName: "456 Ocean Avenue",
        Latitude: -22.9083,
        Longitude: -43.1964,
        Altitude: 5,
      },
      Tax: {
        Description: "Tourist Tax",
        Amount: 12.2,
        TaxType: TaxType.Vat,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC002",
          Name: "Exclusive Services",
          Description: "Premium services for an extraordinary experience",
          Icon: "icon-exclusive",
          Services: [
            {
              Code: "S002",
              Name: "Private Butler",
              Description: "24/7 dedicated butler service",
              Icon: "icon-butler",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H006",
    Name: "Montaña Lodge",
    Category: "3",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "CAD",
    LanguageIsoCode: "en",
    TimeZone: "UTC-08:00",
    ContactData: {
      ContactPerson: "Robert Johnson",
      Email: "robert.johnson@montanalodge.com",
      Phone: {
        Prefix: "+1",
        Number: "555-789-0123",
      },
      WebSite: "http://www.montanalodge.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "VAN",
        CityName: "Vancouver",
        CountryCode: "CA",
        CountryName: "Canada",
        StreetName: "789 Mountain Way",
        Latitude: 49.2827,
        Longitude: -123.1207,
        Altitude: 1500,
      },
      Tax: {
        Description: "Mountain Fee",
        Amount: 8.75,
        TaxType: 1,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC003",
          Name: "Nature Activities",
          Description: "Guided activities to explore the surroundings",
          Icon: "icon-nature",
          Services: [
            {
              Code: "S003",
              Name: "Guided Hikes",
              Description: "Explore scenic trails with expert guides",
              Icon: "icon-hike",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H007",
    Name: "City Lights Inn",
    Category: "2",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "USD",
    LanguageIsoCode: "en",
    TimeZone: "UTC-05:00",
    ContactData: {
      ContactPerson: "Emily Rodriguez",
      Email: "emily.rodriguez@citylightsinn.com",
      Phone: {
        Prefix: "+1",
        Number: "987-123-4567",
      },
      WebSite: "http://www.citylightsinn.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "NYC",
        CityName: "New York City",
        CountryCode: "US",
        CountryName: "United States",
        StreetName: "567 Downtown Street",
        Latitude: 40.7128,
        Longitude: -74.006,
        Altitude: 50,
      },
      Tax: {
        Description: "City Tax",
        Amount: 7.5,
        TaxType: 1,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC004",
          Name: "Urban Experience",
          Description: "Services tailored for an urban stay",
          Icon: "icon-urban",
          Services: [
            {
              Code: "S004",
              Name: "City Tours",
              Description: "Guided tours to explore the city's landmarks",
              Icon: "icon-tour",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
  {
    Code: "H008",
    Name: "Serene Retreat",
    Category: "4",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempor lobortis auctor. Donec vehicula est sodales, sodales erat at, consectetur lorem. Sed molestie, ipsum nec dignissim porttitor, risus augue vulputate nulla, imperdiet tempus mauris tortor in tellus. Praesent sagittis condimentum diam eget feugiat. Nam laoreet pulvinar enim, id aliquam nulla lacinia id. Donec placerat nisi at est consectetur luctus nec id est. Mauris non augue consequat, convallis urna eget, consequat lorem. Cras sed dapibus justo. Ut ipsum tellus, eleifend ac bibendum aliquam, faucibus a justo. In hac habitasse platea dictumst. Curabitur gravida mi ut porta ultrices. Nullam vitae augue non.",
    ShortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et erat at ex pellentesque sagittis. Aenean non congue diam. Ut vestibulum viverra massa nec tempor. Cras pulvinar felis vitae dictum elementum. Proin leo quam, dictum.",
    CurrencyIsoCode: "GBP",
    LanguageIsoCode: "en",
    TimeZone: "UTC+00:00",
    ContactData: {
      ContactPerson: "Sophie Turner",
      Email: "sophie.turner@sereneretreat.com",
      Phone: {
        Prefix: "+44",
        Number: "20-7890-1234",
      },
      WebSite: "http://www.sereneretreat.com",
    },
    StaticHotelData: {
      HotelAddress: {
        CityCode: "LON",
        CityName: "London",
        CountryCode: "GB",
        CountryName: "United Kingdom",
        StreetName: "789 Tranquil Lane",
        Latitude: 51.509865,
        Longitude: -0.118092,
        Altitude: 100,
      },
      Tax: {
        Description: "Nature Conservation Fee",
        Amount: 15.0,
        TaxType: TaxType.Vat,
      },
      PaxConfiguration: {
        Baby: {
          Minimum: 0,
          Maximum: 2.99,
        },
        Child: {
          Minimum: 3,
          Maximum: 12.99,
        },
        Teenager: {
          Minimum: 13,
          Maximum: 17.99,
        },
      },
      Images: [
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-malaga.jpg",
          IsMainImage: true,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/headers//MARCAS_SENATOR_CABECERA.jpg",
          IsMainImage: false,
        },
        {
          LargeImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          MediumImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          SmallImageUrl:
            "https://www.playasenator.com/wp-content/blogs.dir/1031/files/landings//cabecera-landing-cadiz.jpg",
          IsMainImage: false,
        },
      ],
      RoomStaticData: [],
      MealStaticData: [],
      RateStaticData: [],
      ServiceCategories: [
        {
          Code: "SC005",
          Name: "Nature Retreat",
          Description: "Services for a peaceful retreat",
          Icon: "icon-nature-retreat",
          Services: [
            {
              Code: "S005",
              Name: "Nature Walks",
              Description: "Guided walks to explore the surroundings",
              Icon: "icon-nature-walk",
            },
          ],
        },
      ],
    },
    Reputation: null,
  },
];

export const locationAvailabilityResponseExample: LocationAvailabilityResponse = {
  Hotels: theHotels,
  LocationAvailabilities: [
    {
      HotelCode: "H001",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 150.0,
      },
      PriceModifier: {
        Code: "DISC001",
        Description: "10% Off",
        ModifierName: "Early Booking Discount",
        Amount: 15.0,
        AmountBeforeTax: 150.0,
        AmountAfterTax: 135.0,
        CurrencyIsoCode: "USD",
        AmountType: AmountType.Percent,
        AmountTypeText: "Percent",
        ModifierType: ModifierType.Discount,
        ModifierTypeText: "Discount",
        IsDiscountAlreadyApplied: false,
      },
      AvailabilityTypeData: {
        AvailabilityType: 0,
        Restrictions: 3,
        AvailabilityTypeText: "Has Availability",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [],
    },
    {
      HotelCode: "H008",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 80.0,
      },
      PriceModifier: {
        Code: "DISC001",
        Description: "10% Off",
        ModifierName: "Early Booking Discount",
        Amount: 15.0,
        AmountBeforeTax: 150.0,
        AmountAfterTax: 135.0,
        CurrencyIsoCode: "USD",
        AmountType: AmountType.Percent,
        AmountTypeText: "Percent",
        ModifierType: ModifierType.Discount,
        ModifierTypeText: "Discount",
        IsDiscountAlreadyApplied: false,
      },
      AvailabilityTypeData: {
        AvailabilityType: 0,
        Restrictions: 3,
        AvailabilityTypeText: "Has Availability",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [],
    },
    {
      HotelCode: "H002",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 0,
      },
      PriceModifier: null,
      AvailabilityTypeData: {
        AvailabilityType: 1,
        Restrictions: 3,
        AvailabilityTypeText: "NoAvailabilityHotelClosed",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [
        {
          AccommodationDates: {
            From: "2024-05-01T00:00:00Z",
            To: "2024-05-05T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 140.0,
          },
          PriceModifier: null,
        },
        {
          AccommodationDates: {
            From: "2024-05-06T00:00:00Z",
            To: "2024-05-11T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 80.0,
          },
          PriceModifier: null,
        },
      ],
    },
    {
      HotelCode: "H003",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 0,
      },
      PriceModifier: null,
      AvailabilityTypeData: {
        AvailabilityType: 2,
        Restrictions: 3,
        AvailabilityTypeText: "NoAvailabilityNoQuota",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [
        {
          AccommodationDates: {
            From: "2024-05-01T00:00:00Z",
            To: "2024-05-05T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 140.0,
          },
          PriceModifier: null,
        },
        {
          AccommodationDates: {
            From: "2024-05-06T00:00:00Z",
            To: "2024-05-11T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 80.0,
          },
          PriceModifier: null,
        },
      ],
    },
    {
      HotelCode: "H004",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 0,
      },
      PriceModifier: null,
      AvailabilityTypeData: {
        AvailabilityType: 3,
        Restrictions: 0,
        AvailabilityTypeText: "NoAvailabilityExistingRestrictions",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [
        {
          AccommodationDates: {
            From: "2024-05-01T00:00:00Z",
            To: "2024-05-05T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 140.0,
          },
          PriceModifier: null,
        },
        {
          AccommodationDates: {
            From: "2024-05-06T00:00:00Z",
            To: "2024-05-11T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 80.0,
          },
          PriceModifier: null,
        },
      ],
    },
    {
      HotelCode: "H005",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 0,
      },
      PriceModifier: null,
      AvailabilityTypeData: {
        AvailabilityType: 3,
        Restrictions: 1,
        AvailabilityTypeText: "NoAvailabilityExistingRestrictions",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [
        {
          AccommodationDates: {
            From: "2024-05-01T00:00:00Z",
            To: "2024-05-05T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 140.0,
          },
          PriceModifier: null,
        },
        {
          AccommodationDates: {
            From: "2024-05-06T00:00:00Z",
            To: "2024-05-11T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 80.0,
          },
          PriceModifier: null,
        },
      ],
    },
    {
      HotelCode: "H006",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 0,
      },
      PriceModifier: null,
      AvailabilityTypeData: {
        AvailabilityType: 3,
        Restrictions: 2,
        AvailabilityTypeText: "NoAvailabilityExistingRestrictions",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [
        {
          AccommodationDates: {
            From: "2024-05-01T00:00:00Z",
            To: "2024-05-05T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 140.0,
          },
          PriceModifier: null,
        },
        {
          AccommodationDates: {
            From: "2024-05-06T00:00:00Z",
            To: "2024-05-11T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 80.0,
          },
          PriceModifier: null,
        },
      ],
    },
    {
      HotelCode: "H007",
      MinPrice: {
        CurrencyIsoCode: "USD",
        Pvp: 0,
      },
      PriceModifier: null,
      AvailabilityTypeData: {
        AvailabilityType: 3,
        Restrictions: 3,
        AvailabilityTypeText: "NoAvailabilityExistingRestrictions",
        RestrictionText: "Unknown",
      },
      AlternativeDays: [
        {
          AccommodationDates: {
            From: "2024-05-01T00:00:00Z",
            To: "2024-05-05T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 140.0,
          },
          PriceModifier: null,
        },
        {
          AccommodationDates: {
            From: "2024-05-06T00:00:00Z",
            To: "2024-05-11T00:00:00Z",
            Format: "YYYY-MM-DDTHH:mm:ssZ",
          },
          MinimalPrice: {
            CurrencyIsoCode: "USD",
            Pvp: 80.0,
          },
          PriceModifier: null,
        },
      ],
    },
  ],
};
