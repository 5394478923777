import { Checkbox, Col, Form, Input, Radio, Select } from "antd";
import CustomField, { KeyValuePair, TranslatedLabel } from "../models/CustomField";
import { GetLanguageInUrl } from "./urls";

 
export const GetCustomFields = (fields: CustomField[], t: any) : JSX.Element[] => {
    let result : JSX.Element[] =  [];
    let newField : JSX.Element = <></>;

    const actualLanguage = GetLanguageInUrl().replace("/", "");

    //filtrados por idioma
    fields = fields.filter((field: CustomField) => field.availableLanguages.length === 0 || field.availableLanguages.indexOf(actualLanguage) !== -1)
                   .sort((f1: CustomField, f2: CustomField) => f1.order >= f2.order ? 0 : -1);

    fields.forEach((field: CustomField) => {
        switch(field.type){
            case 'input':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.labels.find(l => l.languageIso.split("-")[1].toLowerCase() === actualLanguage.toLowerCase())?.label}
                                    name={field.name}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input className="app-input"/>
                                </Form.Item>
                            </Col>
                result.push(newField);                    
                break;
            case 'checkbox':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.labels.find(l => l.languageIso.split("-")[1].toLowerCase() === actualLanguage.toLowerCase())?.label}
                                    name={field.name}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
            case 'radio':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.labels.find(l => l.languageIso.split("-")[1].toLowerCase() === actualLanguage.toLowerCase())?.label}
                                    name={field.name}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Radio />
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
            case 'select':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.labels.find(l => l.languageIso.split("-")[1].toLowerCase() === actualLanguage.toLowerCase())?.label}
                                    name={field.name}
                                    initialValue={field.defaultValue}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Select>
                                        {
                                            field.options.map((option: KeyValuePair) => <Select.Option key={`opt-${option.key}`} value={option.key}>
                                                                                            {option.value.find((v: TranslatedLabel)=> v.languageIso.split("-")[1].toLowerCase() === actualLanguage.toLowerCase())?.label}
                                                                                        </Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
            case 'text-area':
                newField = <Col xs={24} md={field.alone ? 24 : 12}>
                                <Form.Item
                                    label={field.labels.find(l => l.languageIso.split("-")[1].toLowerCase() === actualLanguage.toLowerCase())?.label}
                                    name={field.name}
                                    rules={[{ required: field.required, message: t("forms.validations.required-field")! }]}
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                result.push(newField);
                break;
        }
    })


    return result;
}