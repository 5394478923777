import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AgencyAndCompanyRequestSignUpFormProps{
    onSignUpRequest : (email: string, name: string) => void,
    isRequesting: boolean,
    resetForm: boolean,
    name: string
}

const AgencyAndCompanyRequestSignUpFormComponent = (props: AgencyAndCompanyRequestSignUpFormProps) : JSX.Element => {
    const { t } = useTranslation();

    const {onSignUpRequest, isRequesting, name} = props;

    const [resetForm, setResetForm] = useState<boolean>(props.resetForm);

    const [form] = useForm();

    const onFinish = () : void => {
        onSignUpRequest(form.getFieldValue("email"), form.getFieldValue("name"));
    }

    useEffect(()=>{
        form.resetFields();
    },[name])

    return(
        <div className="agency-and-company-request-sign-up">
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label={t("components.agency-and-company-request-sign-up.your-name")}
                    name="name"
                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                >
                    <Input className="app-input" />
                </Form.Item>
                <Form.Item
                    label={t("components.agency-and-company-request-sign-up.your-email")}
                    name="email"
                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                >
                    <Input type="email" className="app-input" />
                </Form.Item>
                <div className="app-actions">
                    <Button htmlType="submit" className="app-button">
                        {
                            isRequesting ?  <LoadingOutlined /> : <></>
                        }
                        {t("components.agency-and-company-request-sign-up.next")}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default AgencyAndCompanyRequestSignUpFormComponent;