import { useTranslation } from "react-i18next";

import './LandingPage.scss';
import { useEffect, useState } from "react";
import { TemplateClientInfo } from "../../../models/TemplateClientInfo";
import { fetchTemplateInfo, useDoLogin, useRecoverPassword } from "../../../api/repositoryEP";
import Template2Page from "./DefaultsLandingPages/Template2/Template2";
import Template1Page from "./DefaultsLandingPages/Template1/Template1";
import HTMLReactParser from "html-react-parser";
import { createRoot } from "react-dom/client";
import { generateComponent } from "../../../utils/DynamicComponentFactory";
import ApiResponse from "../../../models/api/ApiResponse";
import UserSessionData from "../../../models/UserSessionData";
import { GetFormattedUrl } from "../../../utils/urls";
import { Modal, notification } from "antd";
import { ForgetPassword, LoginForm } from "../../../components";
import ShortCodeMethod from "../../../models/ShortCodeMethod";

const LandingPage = () : JSX.Element => {
    const { t } = useTranslation();

    const [template, setTemplate] = useState<any>();

    const doLogin  = useDoLogin;
    const recoverPassword = useRecoverPassword;
    
    /**Métodos para usar con los ShotCodes */
    const [haveError, setHaveError] = useState<boolean>(false);
    const onLogin = (userName: string, password: string) : void => {
        doLogin(userName, password).then((r: ApiResponse | null)=>{
            if (r && r.typeText === "success"){
                var userSessionData = r.data as UserSessionData;
                if (userSessionData && userSessionData.isLogged){
                    window.sessionStorage.setItem("user-data", JSON.stringify(userSessionData));
                    
                    const url = GetFormattedUrl('private/dashboard');
                    
                    window.location.href = url;
                }
            }else{
                setHaveError(true);
            }
        })
    }

    const [showForgetPassword,setShowForgetPassword] = useState<boolean>(false);
    const onForgetPassword = () : void => {
        setShowForgetPassword(true);
    }

    const [showModalLogin, setShowModalLogin] = useState<boolean>(false);
    const openModalLogin = () : void => {
        setShowModalLogin(true);
    }
    const closeModalLogin = () : void => {
        setShowModalLogin(false);
    }

    const closeForgetPassword = () : void => {
        setShowForgetPassword(false);
    }

    // A este array añadiremos todos los métodos de los componentes que pueden 
    // cargarse por un short_code
    const arrayShortCodesMethods : ShortCodeMethod[] = [
                            {name: 'onDoLogin', method: onLogin}, 
                            {name: 'onForgetPassword', method: onForgetPassword},
                            {name: 'openModalLogin', method: openModalLogin},
                        ]


    const [loadingTemplate, setLoadingTemplate] = useState<boolean>(true);
    const [templateInfo, setTemplateInfo] = useState<TemplateClientInfo>();
    const getTemplate = () : void => {
        setLoadingTemplate(true);
        fetchTemplateInfo().then((r)=>{
            if (r?.data){
                setTemplateInfo(r.data);
            }else{
                setTemplateInfo({
                    isDefaultTemplate: true,
                    defaultTemplateName: 'template1',
                    template:[]
                });
            }
        })
        .finally(()=>{
            setLoadingTemplate(false);
        })
    }

    useEffect(()=>{
        getTemplate();
    },[])


    const processHTMLComponents = (block: string, order: number) => {
        const replacedHTML = block; 
        const regex = /id=['"]([^'"]+)['"]/;
        const match = regex.exec(block);
        let containerId = '';

        if (match) {
            const idValue = match[1];
            containerId = idValue;
        }
        
        setTimeout(() => {
            var container = document.getElementById(containerId);

            if (container){
                const root = createRoot(container);
                root.render(generateComponent(block, order, arrayShortCodesMethods))
            }

        }, 200);
        
        return <div key={order} dangerouslySetInnerHTML={{ __html: replacedHTML }} />;
      };

      const [sendingForgetPasswordEmail, sendSendingForgetPasswordEmail] = useState<boolean>(false);
      const submitForgetPasswordEmail = (email: string) : void => {
          sendSendingForgetPasswordEmail(true);
          recoverPassword(email).then((r: ApiResponse | null) => {
              if (r && r.typeText === "success"){
                  notification.success({
                        message: t("pages.login-page.forget-password-email-sent-title"),
                        description: HTMLReactParser(t("pages.login-page.forget-password-email-sent-description", [email]))
                  });
                  closeForgetPassword();
              }
          })
          .finally(()=>{
              sendSendingForgetPasswordEmail(false);
          })
      }

    useEffect(()=>{
        if (templateInfo){
            if (templateInfo.isDefaultTemplate || templateInfo.template.length == 0){
                switch(templateInfo.defaultTemplateName){
                    case 'template2':
                        setTemplate(<Template2Page />)
                        break;
                    case 'template1':
                    default:
                        setTemplate(<Template1Page shortCodeMethods={arrayShortCodesMethods}/>)
                        break;
                }
            }else{
                const componentsArray : JSX.Element[] = [];

                templateInfo.template.sort((block1, block2) => block1.order >= block2.order ? 0 : -1).forEach( block =>{

                    if (block.html.indexOf("[COMPONENT_") !== -1){
                        componentsArray.push(processHTMLComponents(block.html, block.order))
                    }else{
                        componentsArray.push(<div key={block.order}>{HTMLReactParser(block.html)}</div>)
                    }
                    
                })

                setTemplate(<div className="custom-template">{componentsArray}</div>)
            }
        }
    },[templateInfo])

    useEffect(() => {
        if (template && templateInfo && !templateInfo.isDefaultTemplate){
            if (templateInfo.css) {
                const styleElement = document.createElement('style');
                styleElement.innerHTML = templateInfo.css;
                document.head.appendChild(styleElement);
              }
          
              if (templateInfo.javascript) {
                const scriptElement = document.createElement('script');
                scriptElement.innerHTML = templateInfo.javascript;
                document.body.appendChild(scriptElement);
              }
        }
        
      }, [template]);
      
    return(
        <div className="landing-page">
            <Modal className="app-modal" title={t("pages.login-page.forget-password-modal-title")} open={showForgetPassword} footer={null} onCancel={closeForgetPassword}>
                <ForgetPassword onCancel={closeForgetPassword} onSendForm={submitForgetPasswordEmail} loading={sendingForgetPasswordEmail} />
            </Modal>

            <Modal className="app-modal" title={t("pages.login-page.modal-title")} open={showModalLogin} footer={null} onCancel={closeModalLogin}>
                <LoginForm haveError={haveError} onLogin={onLogin} doingLogin={false} onForgetPassword={onForgetPassword} />
            </Modal>

            {
                loadingTemplate ? <div className="loader-container"><div className="dots-bars-2"></div></div> : template
            }
        </div>
    )
}

export default LandingPage;