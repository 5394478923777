import { Button } from 'antd';
import { GetHomeIcon, GetRainCloudIcon, GetReloadIcon } from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import { GetDefaultLanguage, GetLanguageInUrl, IsLaguagePresentInUrl } from '../../../utils/urls';

import './BookingPaymentKOPage.scss';
import { useNavigate } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';


interface BookingPaymentKOPageProps{}

const BookingPaymentKOPage = (props : BookingPaymentKOPageProps) : JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const goHome = () : void => {
        const url : string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();
        window.location.href = url;
    }
    const goBack = () : void => {
        navigate(-1);
    }

    return <div className="error-ko-payment-page generic-message">
                    <div className="icon">
                        {
                            GetRainCloudIcon()
                        }
                    </div>
                    <div className="message">
                        <p className="title">
                            {t('pages.booking-payment-ko.title')}
                        </p>
                        <p className="text">
                            {t('pages.booking-payment-ko.description')}
                        </p>
                    </div>
                    <div className="buttons">
                        <Button className="app-button" onClick={goHome}>
                            <div className="content">
                                {GetHomeIcon()}
                                <span>
                                    {t('pages.booking-payment-ko.buttons.go-home')}
                                </span>
                            </div>
                        </Button>
                        <Button className="app-button" onClick={goBack}>
                            <div className="content">
                                <ReloadOutlined />
                                <span>
                                    {t('pages.booking-payment-ko.buttons.try-again')}
                                </span>
                            </div>
                        </Button>
                    </div>
            </div>
}

export default BookingPaymentKOPage;