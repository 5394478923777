import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { HotelLinkInfo } from "../../models/AppConfig";

import './GroupContactFormComponent.scss'
import RegimeInfo from "../../models/RegimeInfo";
import HTMLReactParser from "html-react-parser";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import { GroupFormData } from "../../models/ContactFormInfo";
import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect } from "react";

interface IGroupContactFormComponentProps {
    onSubmit: (formData: GroupFormData) => void,
    hotels: HotelLinkInfo[],
    bugetTypes: string[],
    services: string[],
    minOccupancyNumber: number,
    maxOccupancyNumber: number,
    minRoomsNumber: number,
    maxRoomsNumber: number,
    release: number,
    regimes: RegimeInfo[],
    sending: boolean,
    onResetFields: number
}

const GroupContactFormComponent: React.FC<IGroupContactFormComponentProps> = (props) => {
    const { t } = useTranslation();

    const { hotels, bugetTypes, release, services, minOccupancyNumber, maxOccupancyNumber, minRoomsNumber, maxRoomsNumber, regimes } = props;
    
    const [form] = useForm();

    const onFinish = () => {
        var finalData : GroupFormData = {
            budgetType: form.getFieldValue('budgetType'),
            hotelName: form.getFieldValue('hotelName'),
            occupancyNumber: form.getFieldValue('occupancyNumber'),
            roomsNumber: form.getFieldValue('roomsNumber'),
            regime: form.getFieldValue('regime'),
            checkIn: form.getFieldValue('dates')[0].format('YYYY-MM-DD'),
            checkOut: form.getFieldValue('dates')[1].format('YYYY-MM-DD'),
            services: form.getFieldValue('services'),
            otherServices: form.getFieldValue('otherServices'),
            observations: form.getFieldValue('observations')
        }
        props.onSubmit(finalData);
    }

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < moment().add(release, 'days').endOf('day');
      };

    const clearData = () => {
        form.resetFields();
    }

    useEffect(()=>{
        clearData();
    },[props.onResetFields])
    
    return (
        <div className="group-contact-component">
            <Form form={form} layout='vertical' onFinish={onFinish} onFinishFailed={(errorInfo) => {
                            // Enfocar en el primer campo que contiene un error
                            const firstErrorField = Object.keys(errorInfo.errorFields[0])[0];
                            const errorFieldRef = form.getFieldInstance(firstErrorField);

                            if (errorFieldRef){
                                errorFieldRef.focus();
                            }
                        }}>
                <Row gutter={16}>
                    <Divider className='app-divider' orientation="left">
                        {t("components.group-contact-form.title")}
                    </Divider>
                    <Col xs={24} >
                        
                        <Form.Item
                            label={t("components.group-contact-form.budget-type")}
                            name="budgetType"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select allowClear  className="app-input">
                                {
                                    bugetTypes.map((budgetType, index) => {
                                        return (<Select.Option key={index} value={budgetType}>{budgetType}</Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Divider className='app-divider' orientation="left">
                        {t("components.group-contact-form.details")}
                    </Divider>
                    <Col xs={24} lg={8}>
                        
                        <Form.Item
                            label={t("components.group-contact-form.hotel")}
                            name="hotelName"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select allowClear className="app-input" showSearch>
                                {
                                    // Sort hotels by name
                                    hotels.sort((a, b) => a.name.localeCompare(b.name) ).map((hotel, index) => {
                                        return (<Select.Option key={index} value={hotel.name}>
                                                    {hotel.name}
                                                    <small className="hotel-city-name">
                                                        {hotel.city}
                                                    </small>
                                                </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={16}>
                        <Form.Item
                            label={HTMLReactParser(t("components.group-contact-form.dates", [release > 0 ? t("components.group-contact-form.release-days", [release]): '']))}
                            name="dates"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <DatePicker.RangePicker className="app-input" disabledDate={disabledDate}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("components.group-contact-form.regime")}
                            name="regime"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Select allowClear className="app-input" showSearch>
                                {
                                    // Sort regimes by name
                                    regimes.sort((a, b) => a.name.localeCompare(b.name)).map((regime, index) => {
                                        return (<Select.Option key={index} value={regime.name}>
                                                    {regime.name}
                                                </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={HTMLReactParser(t("components.group-contact-form.occupancy-number", [minOccupancyNumber, maxOccupancyNumber]))}
                            name="occupancyNumber"
                            initialValue={minOccupancyNumber}
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" type="number" min={minOccupancyNumber} max={maxOccupancyNumber}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            initialValue={minRoomsNumber}
                            label={HTMLReactParser(t("components.group-contact-form.rooms-number", [minRoomsNumber, maxRoomsNumber]))}
                            name="roomsNumber"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className="app-input" type="number" min={minRoomsNumber} max={maxRoomsNumber}/>
                        </Form.Item>
                    </Col>
                    
                    <Divider className='app-divider' orientation="left">
                        {t("components.group-contact-form.services")}
                    </Divider>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.group-contact-form.services-to-include")}
                            name="services"
                        >
                            <Select mode="multiple" allowClear className="app-input" showSearch>
                                {
                                    // Sort services by name
                                    services.sort((a, b) => a.localeCompare(b)).map((service, index) => {
                                        return (<Select.Option key={index} value={service}>
                                                    {service}
                                                </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.group-contact-form.other-services")}
                            name="otherServices"
                        >
                            <Input.TextArea className="app-input" rows={6} />
                        </Form.Item>
                    </Col>
                    <Divider className='app-divider' orientation="left">
                        {t("components.group-contact-form.others")}
                    </Divider>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.group-contact-form.observations")}
                            name="observations"
                        >
                            <Input.TextArea className="app-input" rows={6} placeholder={t("components.group-contact-form.observations-notice")!}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="actions">
                    <Button className="app-button"htmlType="submit" disabled={props.sending}>
                        {
                            props.sending ? <LoadingOutlined /> : <MailOutlined />
                        }
                        {
                            t("components.group-contact-form.submit-button")
                        }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default GroupContactFormComponent