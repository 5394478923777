
import { Button, Col, Form, Input, Row } from 'antd';
import UsersInfo from '../../../models/UsersInfo';
import './UsersAddComponent.scss'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface UsersAddComponentProps {
    onAddUser: (userData: UsersInfo) => void
    counter: number;
}

const UsersAddComponent = (props: UsersAddComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const { onAddUser } = props;

    const clearForm = () : void => {
        form.resetFields()
    };

    const onFinish = () : void => {
        onAddUser(form.getFieldsValue());
    }

    useEffect(()=>{
        clearForm();
    },[props.counter])


    return(
        <div className="users-add">
            <Form form={form} layout='vertical' onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-add.name")}
                            name="name"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input' allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-add.surname")}
                            name="surname"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input' allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-add.second-surname")}
                            name="secondSurname"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input className='app-input' allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("components.users-add.email")}
                            name="email"
                            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                        >
                            <Input  className='app-input' allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                        label={t("components.users-add.phone")}
                        name="phone"
                        >
                            <Input  className='app-input' allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} className="form-actions">
                        <Button htmlType='submit' className='app-button'>
                            {t("components.users-add.save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default UsersAddComponent;