import './ContactPhoneComponent.scss';

interface ContactPhoneComponentProps{
    countryName: string,
    isoCountry: string,
    phone: string
}

const ContactPhoneComponent = (props: ContactPhoneComponentProps) : JSX.Element => {

    const {isoCountry, countryName, phone} = props;

    const getCountryFlag = () : any => <img className="flag-icon" style={{fontSize: "12px"}} src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${isoCountry.toUpperCase()}.svg`} />;

    return (
        <div className="contact-phone-component">
            <div className="flag">
                {getCountryFlag()}
                <small>{countryName}</small>
            </div>
            <div className="phone">{phone}</div>
        </div>
    )
}

export default ContactPhoneComponent;