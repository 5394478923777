import { Divider, Select, Skeleton } from 'antd'
import { useEffect, useState } from 'react';
import { Language } from '../../../models/Language';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { DEFAULT_LANGUAGE } from '../../../i18n/i18n';
import { GetAppLanguage, UpdateAppLanguage } from '../../../RoutesApp';

import './CurrencySelectorComponent.scss';
import { GetArrowSelectIcon } from '../../../utils/icons';
import { Currency } from '../../../models/Currency';

const CurrencySelectorComponent = () : JSX.Element =>{

    const appConfig = useAppConfig();

    const [availableClientCurrencies, setAvailableClientCurrencies] = useState<Currency[]>([]);

    const getAvailableClientCurrencies = () : void => {
        if (appConfig && appConfig.config.availableCurrencies){
            setAvailableClientCurrencies(appConfig.config.availableCurrencies);
        }
    }

    const changeCurrency = (shortCode: string) : void => {
        console.log("Shortcode", shortCode)
    }

    useEffect(()=>{
        getAvailableClientCurrencies();
    },[appConfig])

    return (
        <>
            {
                availableClientCurrencies && availableClientCurrencies.length > 0
                ?
                    <Select className='currency-selector' defaultValue={availableClientCurrencies[0]?.iso} onChange={changeCurrency} suffixIcon={GetArrowSelectIcon()}>
                        {
                            availableClientCurrencies.map((c: Currency) => {
                                return <Select.Option disabled={!c.available} key={c.iso}>{c.description} ({c.iso})</Select.Option>
                            })
                        }
                    </Select>
                :
                    <Skeleton.Input active size={'small'} />
            }
        </>
    )
}

export default CurrencySelectorComponent;