import { useTranslation } from 'react-i18next';
import './ProfilePage.scss'
import { EditProfile } from '../../../components';
import { useState } from 'react';
import UpdateProfileData from '../../../models/UpdateProfileData';
import ChangePasswordData from '../../../models/ChangePasswordData';
import UpdatePersonalData from '../../../models/UpdatePersonalData';
import { updateProfileAccessData, updateProfileContacData, updateProfilePersonalData } from '../../../api/repositoryEP';



const ProfilePage = () : JSX.Element => {
    const { t } = useTranslation();

    const onChange = (key: string) => {
        console.log(key);
      };

    const [savingIndex, setSavingIndex] = useState<number>(0);

    const onSaveProfile  = (data: UpdateProfileData) : void => {
      setSavingIndex(1);
      updateProfileContacData(data).finally(() => {
        setSavingIndex(0);        
      });

    }

    const onSavePersonalData = (data: UpdatePersonalData) : void => {
      setSavingIndex(2);
      updateProfilePersonalData(data).finally(() => {
        setSavingIndex(0);        
      });
    }

    const onChangePassword = (data: ChangePasswordData) : void => {
      setSavingIndex(3);
      updateProfileAccessData(data).finally(() => {
        setSavingIndex(0);        
      });
    }



    return (
        <div className="profile-page">
            <h2 className="title">{t('pages.profile-page.main-title')}</h2>
            <h3 className="sub-title">{t('pages.profile-page.subtitle')}</h3>

            <EditProfile 
                onSaveProfile={onSaveProfile} 
                onSaveContactData={onSavePersonalData}
                onSavePassword={onChangePassword}
                savingIndex={savingIndex}/>
        </div>
    )
}

export default ProfilePage;