import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import './UsersFilterComponent.scss'
import { useTranslation } from 'react-i18next';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import CustomField from '../../../models/CustomField';
import { GetLanguageInUrl } from '../../../utils/urls';
import { useEffect, useState } from 'react';
import { UsersFilteData } from '../../../models/UsersInfo';

interface UsersFilterComponentProps {
    onFilter: (filter: UsersFilteData) => void;
    customFields: CustomField[];
    onShowAddUser: () => void;
}

const UsersFilterComponent = (props: UsersFilterComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    const { customFields } = props;

    const actualLanguage = GetLanguageInUrl().replace("/", "");

    const [form] = Form.useForm();

    const onFilter = () : void => {
        props.onFilter(form.getFieldsValue());
    }

    const onShowAddUser = () : void => {
        props.onShowAddUser();
    }

    const [customFieldsByLanguage, setCustomFieldsByLanguage] = useState<CustomField[]>([]);
    useEffect(()=>{
        if (customFields){
            setCustomFieldsByLanguage(customFields.filter((field: CustomField) => field.availableLanguages.length == 0 || field.availableLanguages.indexOf(actualLanguage) !== -1));
        }
    },[customFields])

    return(
        <div className="users-filter-component">
            <Form form={form} layout='vertical'>
                <Row gutter={16}>
                    <Col xs={24} lg={7}>
                        <Form.Item
                        label={t("components.users-filter.email")}
                        name="email"
                        >
                            <Input onChange={onFilter} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon"/>}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={7}>
                        <Form.Item
                        label={t("components.users-filter.name")}
                        name="name"
                        >
                            <Input onChange={onFilter} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon"/>}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={7}>
                        <Form.Item
                        label={t("components.users-filter.dni")}
                        name="dni"
                        >
                            <Input onChange={onFilter} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon"/>}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={3}>
                        <Button type="primary" className="app-button search-button" onClick={onShowAddUser}>
                            <PlusOutlined />
                            {t("components.users-filter.add-user")}
                        </Button>
                    </Col>
                    <Col xs={24}>
                            <Collapse className="app-collapse advanced-search-container" items={[
                                {
                                    key: '1',
                                    label: t('components.users-filter.filter.advanced-search'),
                                    headerClass: "advanced-search-header right-align",
                                    children: <Row gutter={16}>
                                                    <Col xs={24} lg={12}>
                                                        <Form.Item
                                                            label={t("components.company-selector.filter.phone")}
                                                            name="phone"
                                                        >
                                                            <Input onChange={onFilter} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon" />}/>
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        customFieldsByLanguage?.map((field: CustomField, index: number) => {
                                                            return(
                                                                <Col xs={24} lg={12} key={index}>
                                                                    <Form.Item
                                                                        label={field.labels.find(l => l.languageIso.split("-")[1].toLowerCase() == actualLanguage.toLowerCase())?.label}
                                                                        name={`custom.${field.name}`}
                                                                    >
                                                                        <Input onChange={onFilter} allowClear className="app-input" prefix={<SearchOutlined className="prefix-search-icon" />}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                }
                            ]}/>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default UsersFilterComponent;